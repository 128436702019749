/**
 *  NO UTILIZAR ESTE COMPONENTE - OBSOLETO!!!!!!!
 */

/**
 * Angular
 */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
/**
 * Interfaces
 */
import { LocalNavData } from './local-nav.interface';
import { ChangedetectorService } from '../../core/changedetector/changedetector.service';

@Component({
  selector: 'app-local-nav',
  templateUrl: './local-nav.component.html',
  styleUrls: ['./local-nav.component.scss']
})
export class LocalNavComponent implements OnInit, OnChanges {

  @Input() data: LocalNavData;
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();

  constructor(private cdService: ChangedetectorService) {
  }

  ngOnInit(): void {

  }

  select(itemId: string): void {
    this.data.selected = itemId;
    this.selected.emit(itemId);
    this.cdService.runApplicationChangeDetection();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.data && changes.data.currentValue) {
      this.data = changes.data.currentValue;
    }
  }
}
