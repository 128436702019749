import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Injector,
  isDevMode,
  NgZone,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { MessageToastService } from './core/message-toast/share/message-toast.service';
import { SpinnerService } from './shared/spinner/spinner.service';
import { Router } from '@angular/router';
import { WindowRef } from './shared/utils/browser-globals';
import { AppContainerService } from './app-container.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {

  @HostListener('document:click', ['$event', '$event.target'])
  onDocumentClicked(event: MouseEvent, targetElement: HTMLElement): void {
    this.appContainerService.documentClicked.next();
  }


  /**
   * Get an instance of AppComponent
   *
   * @param {ViewContainerRef} vcr
   * @param {MessageToastService} messageToastervice
   */
  constructor(
    public vcr: ViewContainerRef,
    private messageToastervice: MessageToastService,
    private spinnerService: SpinnerService,
    private cdRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private injector: Injector,
    private router: Router,
    private windowRef: WindowRef,
    private appContainerService: AppContainerService) {
    // Esto es un "hack" para los tests e2e, ver link: https://github.com/angular/protractor/issues/3911
    const window: any = windowRef.getNativeWindow();
    window.protractorNavigateByUrl = (route): Promise<any> => {
      return ngZone.run(() => {
        return router.navigateByUrl(route, {skipLocationChange: false, replaceUrl: true});
      });
    };
    // Para que los tests sepan si la aplicación está o no en dev mode
    window.AngularIsDevMode = isDevMode();
  }

  /**
   * updates page title on component initialitation
   */
  ngOnInit(): void {
    this.spinnerService.setRootViewContainer(this.vcr, this.cdRef);
  }

  ngAfterViewInit(): void {
  }
}
