import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { MenuItemCompiledFrontend } from '../../../../core/navigation/models/MenuItemCompiledFrontend.class';
import { NavigationService } from '../../../../core/navigation/navigation.service';
import { isNullOrUndefined, makeSafeForCSS } from '../../../utils/typescript.utils';
import { filter, takeUntil } from 'rxjs/operators';
import { DestroyableObjectTrait } from '../../../utils/destroyableobject.trait';
import { MenuType } from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-menu-child',
  templateUrl: './menu-child.component.html'
})
export class MenuChildComponent extends DestroyableObjectTrait implements OnChanges {
  // inputs
  @Input() open = false;
  @Input() tree: MenuItemCompiledFrontend[];
  @Input() depth: number;

  // public properties
  openedChildren: number;

  /**
   * MenuChildComponent class constructor.
   * @param {ChangeDetectorRef} cdRef
   * @param {NavigationService} navService
   */
  constructor(
    private cdRef: ChangeDetectorRef,
    private navService: NavigationService
  ) {
    super();
  }

  makeSafeForCssWithPrefix(name: string): string {
    return makeSafeForCSS('nav_' + name);
  }

  /**
   * A lifecycle hook that is called when any data-bound property of a directive changes.
   */
  ngOnChanges(): void {
    if (!(this.tree === null || this.tree === undefined)) {
      this.tree = this.tree.filter(x => x.Hidden === false && x.menuType !== MenuType.LocalAction);
    }
  }

  /**
   * change the opened children iterator
   * @param {number} i
   */
  changeOpenedChildren(i: number, item: MenuItemCompiledFrontend): void {
    if (item.getFrontendPath() && item.ForceOnClickNavigation) {
      this.navService.navigateToMenuItem(item)
        .pipe(
          takeUntil(this.componentDestroyed$),
          filter((j) => j === true)
        )
        .subscribe(() => {
          this.openedChildren = (this.openedChildren === i) ? null : i;
          this.cdRef.detectChanges();
        });
      return;
    }

    this.openedChildren = (this.openedChildren === i) ? null : i;
    this.cdRef.detectChanges();
  }

  /**
   * returns if an array of children is valid. If has no children or has no Normal type children, it's an
   * invalid array.
   * @param {FrontendMenuItem[]} children: children array to check
   * @returns {boolean} if children array contains valid children, returns true
   */
  hasValidChildren(item: MenuItemCompiledFrontend): boolean {
    const children: MenuItemCompiledFrontend[] = item.children;
    if (isNullOrUndefined(children)) {
      return false;
    }
    return children.filter((x) => {
      return x.menuType === MenuType.Normal && x.Hidden === false;
    }).length !== 0;
  }
}
