<mwl-calendar-month-view
  [viewDate]="viewDate"
  [events]="events"
  [refresh]="refresh"
  [activeDayIsOpen]="activeDayIsOpen"
  [weekendDays]="weekendDays"
  [excludeDays]="weekendDays"
  [weekStartsOn]="weekStartOn"
  (dayClicked)="onDayClicked($event)"
  (eventClicked)="onEventClicked($event)"
  (eventTimesChanged)="onEventTimesChanged($event)"
  (beforeViewRender)="onBeforeMonthViewRender($event)">
</mwl-calendar-month-view>
