import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DestroyableObjectTrait } from '../../../utils/destroyableobject.trait';
import { ViewsuserconfigchangedEventdata } from '../../../list_v2/viewsuserconfigchanged.eventdata';
import { ViewsPluginRequest, ViewsVboSelectedItem } from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';

import { Observable } from 'rxjs';
import { ViewFormGeneratorDirective } from './view-form-generator.directive';
import { ListComponent2Service } from '../../../list_v2/list.service';
import { ViewsinitializedEventdata } from '../../../list_v2/events/viewsinitialized.eventdata';


/**
 * Versión del comonente de listados que se puede embedir en los formularios
 */

@Component({
  selector: 'app-views-form',
  templateUrl: './views-form.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ViewsFormComponent extends DestroyableObjectTrait implements OnInit {

  @ViewChild(ViewFormGeneratorDirective, {static: true}) component: ViewFormGeneratorDirective;

  @Input() explicitLoad = false;

  @Output() viewIntialized: EventEmitter<ViewsinitializedEventdata> = new EventEmitter<ViewsinitializedEventdata>();

  @Output() vboSelectedItems: EventEmitter<{ [id: string]: ViewsVboSelectedItem }> = new EventEmitter<{ [p: string]: ViewsVboSelectedItem }>();

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  loadListFromPluginRequest(pluginRequest: ViewsPluginRequest, init: boolean, options: ViewsuserconfigchangedEventdata = null): void {
    this.component.view.viewIntialized.takeUntil(this.componentDestroyed$).subscribe(x => this.viewIntialized.emit(x));
    this.component.view.vboSelectedItems.takeUntil(this.componentDestroyed$).subscribe(x => this.vboSelectedItems.emit(x));
    this.component.view.loadListFromPluginRequest(pluginRequest, init, options);
  }

  reloadList(): void {
    this.component.view.reloadList();
  }

  hideTitle(): void {
    this.component.view.hideTitle();
  }

  get listComponentService(): ListComponent2Service {
    return this.component.view.listComponentService;
  }

  materializeVboSelectionInMemory(): Observable<ViewsVboSelectedItem[]> {
    return this.component.view.materializeVboSelectionInMemory();
  }
}
