import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { asIterableObject, getInSafe } from '../../../../../../../shared/utils/typescript.utils';
import { ChangedetectorReference } from '../../../../../../../core/changedetector/changedetectoreference';
import { DestroyableObjectTrait } from '../../../../../../../shared/utils/destroyableobject.trait';
import { takeUntil } from 'rxjs/operators';
import { FuseChartsService } from '../../fuse-charts.service';
import {
  ChartExecutionResponse,
  ChartUserConfiguration,
  IChart
} from '../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-chart-mode-selector',
  templateUrl: './chart-mode-selector.component.html',
  styleUrls: ['./chart-mode-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ChangedetectorReference]
})
export class ChartModeSelectorComponent extends DestroyableObjectTrait implements OnInit {

  public currentChartResponse: ChartExecutionResponse;
  public availableCharts: IChart[];
  public showComponent: boolean = false;
  public currentChart: string = null;

  constructor(private cdReference: ChangedetectorReference,
              private chartService: FuseChartsService) {
    super();
    this.chartService.currentChartUserConfiguration
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((x: ChartUserConfiguration) => {
        this.currentChart = x.SelectedChart;
      });

    this.chartService.lastChartExecutionResponseChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((value: ChartExecutionResponse) => {
        this.currentChartResponse = value;
        this.availableCharts = getInSafe(
          this.currentChartResponse.CompiledChart,
          x => asIterableObject(x.AvailableCharts),
          []);
        this.showComponent = this.availableCharts.length > 1;
        this.currentChart = getInSafe(
          this.currentChartResponse.UserConfiguration,
          x => x.SelectedChart,
          this.currentChartResponse.CompiledChart.CurrentChart)
        this.cdReference.changeDetector.detectChanges();
      });
  }

  public onOptionChanged(option: IChart): void {
    this.chartService.changeChart(option.Id);
  }

  ngOnInit(): void {
  }
}
