import Dexie from 'dexie';
import { Translation } from '../models/translation';
import { Observable } from 'rxjs';
import { RestTranslatorInterface } from './rest-translator.interface';

export class TranslatorDatabase extends Dexie implements RestTranslatorInterface {

  /**
   * Tabla de traducciones
   */
  public translations: Dexie.Table<Translation, number>;

  constructor() {
    super('Locale');

    const db: this = this;

    db.version(2)
      .stores({
        translations: '&key,targetLanguage,literalTranslated'
      })
      .upgrade(transaction => {
        return transaction
          .table('translations')
          .toCollection()
          .modify(translation => {
            delete translation.template;
          })
      });

    db.translations.mapToClass(Translation);
  }

  /**
   * Obtener todas las traducciones almacenads de un idioma determinado
   *
   * @param language
   */
  getTranslationsForLanguage(language: string): Observable<Translation[]> {
    return Observable.fromPromise(
      this.translations
        .where('targetLanguage')
        .equals(language)
        .toArray()
    );
  }

  /**
   * Borra todas las traducciones para un idioma determinado, se usa
   * para resincronizar las traducciones de ese idioma.
   *
   * @param language
   */
  clearTranslationsForLanguage(language: string): Observable<number> {
    return Observable.fromPromise(
      this.translations
        .where('targetLanguage')
        .equals(language)
        .delete());
  }

  putTranslations(translations: Translation[]): Observable<number> {
    return Observable.fromPromise(
      this.transaction('rw', this.translations, () => {
        return this.translations.bulkPut(translations);
      }));
  }

  /**
   * Clear all the translations and remove all the localstorage locale keys
   */
  clear(): Observable<void> {
    return Observable.fromPromise(this.translations.clear());
  }
}
