import { Injectable, Optional, SkipSelf } from '@angular/core';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { IResultCollector } from '../../core/commands/resultcollector.interface';
import { backendTypeMatch } from '../utils/typescript.utils';
import { DestroyableObjectTrait } from '../utils/destroyableobject.trait';
import { CommandService } from '../../core/commands/command.service';
import {
  CoreBatchSeeProgressCommand,
  CoreCreateReportCommand, CoreViewsCommandRefreshView, ICommand
} from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import { ReportService } from '../../core/services/ETG_SABENTISpro_Application_Core_report.service';
import { Observable } from 'rxjs';

/**
 * Servicios globales de la API de Reports (i.e. listener para los comandos)
 */
@Injectable({
  providedIn: 'root',
})
export class ReportsGlobalServicesClass extends DestroyableObjectTrait {

  /**
   * FormGlobalServicesClass class constructor.
   */
  constructor(
    protected reportService: ReportService,
    protected commandService: CommandService,
    @Optional() @SkipSelf() parentModule?: ReportsGlobalServicesClass
  ) {

    super();

    if (parentModule) {
      throw new Error(
        'ReportsGlobalServicesClass is already loaded. Import it in the AppModule only');
    }

    this.commandService.CommandObservable
      .pipe(
        filter(obj => backendTypeMatch(CoreCreateReportCommand.$type, obj.Argument)),
        map((obj) => obj as IResultCollector<CoreCreateReportCommand, (() => Promise<boolean>) | Observable<boolean>>),
      ).subscribe((next) => {
      next.AddResult(
        this.reportService.postCreatereport(next.Argument.Target, next.Argument.Arguments)
          .pipe(
            takeUntil(this.componentDestroyed$),
            switchMap((result) => {
              if (next.Argument.SeeProgress) {
                const progressCommand: CoreBatchSeeProgressCommand = new CoreBatchSeeProgressCommand();
                progressCommand.Job = result.result;
                progressCommand.OnCloseCommands = {};
                progressCommand.OnCloseCommands['coreViewsCommandRefreshView'] = new CoreViewsCommandRefreshView();
                return this.commandService.executeCommandChain([progressCommand]);
              } else {
                return Observable.of(true);
              }
            })));
    });
  }
}
