import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';
import { EventData } from './interfaces/event.class';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  event = new Subject<EventData>();

  /**
     * Returns an observable with the events filtered by an action.
     */
  getEventsFilteredByAction(action: string): Observable<EventData> {
    return this.event.asObservable()
      .filter(e => e.action === action);
  }

  /**
   * Submits an event.
   *
   * Added to avoid the direct use (and misuse) of the event property.
   */
  emitEvent(event: EventData): void {
    this.event.next(event);
  }

}
