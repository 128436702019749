<app-views-form
  #listField
  (vboSelectedItems)="vboChanged()"
  (viewIntialized)="initialize($event)"
  [explicitLoad]="true"
></app-views-form>

<div class="text-center" *ngIf="this.configuration.isMultiselect === true">
  <button class="o-btn__secondary t-select-vbomodal-items" (click)="selectItems()">Seleccionar</button>
</div>
