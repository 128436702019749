<div class="l-multiselector-inline">
  <div class="c-multiselector__container" [id] = "'multiselector-container-' + getId()" *ngFor="let item of renderedItems; let i = index;" [ngClass]="'row-' + i">
    <div class="c-multiselector__items" [id] = "'multiselector-items-' + getId()">
      <div class="c-multiselector__icon--remove" [id] = "'multiselector-icon-remove-' + getId()" *ngIf="!isDisabled">
        <i class="fas fa-minus t-remove" (click)="removeElement(item.Key)"></i>
      </div>
      <div class="u-base-regular-text">{{ item.Name }}</div>
      <div class="u-base-bold-text">{{ item.Description }}</div>
      <div *ngFor="let extra of item.ExtraInformation">{{ extra }}</div>
      <div [innerHTML]="item.Html"></div>
    </div>
  </div>
</div>

<div class="c-multiselector__icon--add t-add"
     [id] = "'multiselector-icon-add-' + getId()"
     (click)="openModal()"
     *ngIf="showAddIcon">
  <i class="fas fa-plus"></i>
</div>
