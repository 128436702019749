import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItemCompiled, MenuType } from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { Subject } from 'rxjs';
import { getInSafe, UtilsTypescript } from '../../utils/typescript.utils';
import { MenuItemCompiledFrontend } from '../../../core/navigation/models/MenuItemCompiledFrontend.class';
import { NavigationRequest } from '../../../core/navigation/models/NavigationRequest.class';

@Component({
  selector: 'app-wizardnavigation',
  templateUrl: './wizardnavigation.component.html'
})
export class WizardnavigationComponent implements OnInit, AfterViewInit, OnDestroy {

  /**
   * Destroy subject.
   */
  componentDestroyed$: Subject<null> = new Subject<null>();

  wizardSteps: MenuItemCompiledFrontend[] = [];

  containerCssClasses: string[] = [];

  nextPath: string = null;

  previousPath: string = null;

  constructor(private navigationService: NavigationService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.navigationService
      .getWizardSteps()
      .takeUntil(this.componentDestroyed$)
      .subscribe(
        (next) => {
          const tabs: MenuItemCompiledFrontend[] = getInSafe(next.navigationRequest.responseMenuPath, (i) => i.find(p => p.menuType === MenuType.WizardContainer).children, []);
          this.setTabs(tabs, next.navigationRequest);
        }
      )
  }

  /**
   * Set the current tabs
   */
  private setTabs(tabs: MenuItemCompiledFrontend[], navigationRequest: NavigationRequest): void {

    const steps: MenuItemCompiledFrontend[] = UtilsTypescript.jsonClone(tabs);
    const currentController: string = navigationRequest.requestController;

    steps.filter((tab) => !tab.Hidden);

    // If there's only one tab, we don't show the tab container
    if (steps.length === 0 || steps.length === 1) {
      this.wizardSteps = [];
      return;
    }

    // Active trail
    /*for (const item of steps) {
      item.CssClasses.push('active');
      if (item.controller === currentController) {
        break;
      }
    }*/

    // Visited trail
    for (const item of steps) {
      if (item.controller === currentController) {
        item.CssClasses.push('active');
        break;
      }
      item.CssClasses.push('visited');
    }

    this.containerCssClasses = navigationRequest.responseMenuPath[1].CssClasses;
    this.wizardSteps = steps;

    const currentStep: any = this.wizardSteps.findIndex(m => m.path === navigationRequest.responseMenuPath[0].path);

    this.nextPath = this.wizardSteps[Math.min(currentStep + 1, this.wizardSteps.length - 1)].path;
    this.previousPath = this.wizardSteps[Math.max(currentStep - 1, 0)].path;

    this.cdRef.detectChanges();
  }

  /**
   * Get classes for a single tab
   *
   * @param tab
   */
  getTabCssClasses(tab: MenuItemCompiled): string[] {
    const classes: string[] = [...tab.CssClasses];
    if (tab.Disabled === true) {
      classes.push('--disabled');
    }
    return classes;
  }

  /**
   * Get class for the wizard wrapper
   */
  getClasses(): string[] {
    return this.containerCssClasses;
  }

  ngAfterViewInit(): void {
    this.cdRef.detach();
  }

  /**
   * Destroy lifecycle event.
   */
  ngOnDestroy(): void {
    this.componentDestroyed$.next(null);
    this.componentDestroyed$.complete();
  }

  next(): string {
    return this.nextPath;
  }

  previous(): string {
    return this.previousPath;
  }
}
