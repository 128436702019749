import { Injectable, isDevMode, Optional, SkipSelf } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { isNullOrUndefined, removeFromArray } from 'app/shared/utils/typescript.utils';
import { BootstrapService } from './app-bootstrap.service';
import { DateTimeService } from './core/date-time/date-time.service';
import { GeocodingConfiguration } from './core/models/ETG_SABENTISpro_Application_Core_models';
import { DomReferenceProviderService } from './shared/utils/providers/dom-reference-provider.service';
import { PrimeUtils } from './shared/utils/prime.utils';
import { take } from 'rxjs/operators';
import { AppBootstrapSpinnerService } from './app-bootstrap-spinner.service';
import { GoogleAnalyticsClientSettings } from './core/models/ETG_SABENTISpro_Application_Modules_models';

@Injectable()
export class AppConfigurationService {

  /**
   * Store the key-value
   */
  config: Object = null;

  /**
   * Subject that alerts when the configuration has been bootstrapped.
   */
  public bootstrapDone$ = new ReplaySubject<boolean>(1);

  /**
   * Subject that alerts when the configuration params has been loaded.
   */
  private configDone$ = new ReplaySubject<boolean>(1);

  private generalConfig: any;
  private analyticsConfig: GoogleAnalyticsClientSettings;
  private geocodingConfiguration: GeocodingConfiguration;
  public systemLanguageCode: string;
  public systemLanguageIso: string;
  private activeModulesConfig: { nombre: string }[] = [];
  public errorPageIsDisabled: boolean = false;

  /**
   * Bootstrap Lock keys.
   * Claves que llegan en el bootdata que no permiten arrancar si no las tenemos tratadas.
   * @private
   */
  private bootstrapLockKeys: string[] = [];

  /**
   * Bootstrap configuration.
   */
  private bootstrapData: any;

  /**
   * domain value
   *
   * @private
   */
  private domain: string;

  /**
   * Creates a new instance of AppConfigurationService
   * @param {ThemeCustomizerService} themeCustomizarService
   * @param {ClientThemeService} clientThemeService
   * @param {DateTimeService} dateTimeService
   * @param {bootstrapService} bootstrapService
   * @param {DomReference} domReference
   */
  constructor(
    private bootstrapService: BootstrapService,
    private domReference: DomReferenceProviderService,
    private bootstrapSpinner: AppBootstrapSpinnerService,
    @Optional() @SkipSelf() parentModule?: AppConfigurationService
  ) {
    // Protección para garantizar que esto está inyecto como SINGLETON
    if (parentModule) {
      throw new Error(
        'AppConfigurationService is already loaded. Import it in the AppModule only');
    }

    this.addBootstrapKey('ga-settings');
    this.addBootstrapKey('active-modules');
    this.addBootstrapKey('geocoding-settings');
    this.addBootstrapKey('error-page-disabled');
    this.addBootstrapKey('system-language');
    this.addBootstrapKey('system-languageIso');

    if (isDevMode()) {
      console.debug('App configuration service started.');
    }
    this.loadConfiguration();
    this.loadBootstrapData();
  }

  /**
   * Retrieve the value for the input key
   */
  get(key: any): any {
    if (key === 'domain') {
      // Lo guardamos para que no inunde el log....
      if (!this.domain) {
        this.domain = PrimeUtils.GenerateApiConnection(this.config);
      }
      return this.domain;
    }
    return this.config[key];
  }

  /**
   * Retrieve the value of general config
   * @returns {any}
   */
  getGeneralConfig(): any {
    return this.generalConfig;
  }

  /**
   * Retrieve Google Analytics Client Settings
   * @returns {GoogleAnalyticsClientSettings}
   */
  getAnalyticsConfig(): GoogleAnalyticsClientSettings {
    return this.analyticsConfig;
  }

  /**
   * Retrieve Google Analytics Client Settings
   * @returns {GoogleAnalyticsClientSettings}
   */
  getGeocodingConfiguration(): GeocodingConfiguration {
    return this.geocodingConfiguration;
  }

  /**
   * Retrieve active modules configuration
   * @returns {{nombre: string}[]}
   */
  getActiveModulesConfig(): { nombre: string }[] {
    return this.activeModulesConfig;
  }

  /**
   * Loads bootstrap data
   */
  loadBootstrapData(): void {
    this.bootstrapService
      .bootDataReady$()
      .pipe(take(1))
      .subscribe((responseData: any) => {

        if (isNullOrUndefined(responseData)) {
          this.triggerBootstrapDone(false);
          return false;
        }

        this.bootstrapData = responseData.result;

        if (isDevMode()) {
          console.log('✔ Boot data ready.');
          console.log('⌛ Bootstrap started.');
        }

        this.analyticsConfig = responseData.result['ga-settings'];
        this.removeBootstrapKey('ga-settings');
        this.activeModulesConfig = responseData.result['active-modules'];
        this.removeBootstrapKey('active-modules');
        this.geocodingConfiguration = responseData.result['geocoding-settings'];
        this.removeBootstrapKey('geocoding-settings');
        this.errorPageIsDisabled = responseData.result['error-page-disabled'];
        this.removeBootstrapKey('error-page-disabled');
        this.systemLanguageCode = responseData.result['system-language'];
        this.removeBootstrapKey('system-language');
        this.systemLanguageIso = responseData.result['system-languageIso'];
        this.removeBootstrapKey('system-languageIso');

        this.triggerBootstrapDone(true);
      });
  }

  hasBootstrapData(): boolean {
    return !!this.bootstrapData;
  }

  /**
   * Returns a data set from the boostrap data object.
   */
  getBootstrapData(key: string): any {
    if (!this.bootstrapData.hasOwnProperty(key)) {
      return undefined;
    }

    return this.bootstrapData[key];
  }

  addBootstrapKey(key: string): void {
    if (!this.bootstrapLockKeys) {
      this.bootstrapLockKeys = [];
    }
    this.bootstrapLockKeys.push(key);
  }

  removeBootstrapKey(key: string): void {
    removeFromArray(this.bootstrapLockKeys, key);
  }


  /**
   * Triggers the `bootstrapDone$` emitter, removing the body > .spinner-container
   * and informs the communication services (and others) that are allowed to
   * make requests.
   */
  triggerBootstrapDone(success: boolean): void {
    if (this.bootstrapLockKeys.length === 0) {
      if (isDevMode()) {
        console.log('👌🏼 Bootstrap completed.');
      }
      this.bootstrapSpinner.remove();
      this.bootstrapDone$.next(success);
    }
  }

  /**
   * Load "configuration.[environment].json" to get all variables (e.g.: 'assets/configuration/configuration.production.json')
   * Use the environment class to get the configuration file name.
   */
  loadConfiguration(): void {
    this.bootstrapService
      .configurationDataReady$()
      .subscribe((responseData) => {
        this.config = responseData;
        this.generalConfig = responseData;
        this.configDone$.next(true);

        if (isDevMode()) {
          console.log('✔ Configuration data ready.');
        }

        return (this.config);
      });
  }

  /**
   * Return a true boolean indicating if the configuration has been loaded.
   */
  isAppConfigLoaded$(): Observable<boolean> {
    return this.configDone$
      .asObservable()
      .filter(r => r)
      .take(1);
  }

  /**
   * Returns a true boolean indicating if apllication has been bootstrapped.
   */
  isAppBootstrapped$(): Observable<boolean> {
    return this.bootstrapDone$
      .pipe(
        take(1)
      );
  }
}
