import { Component, ElementRef, OnInit } from '@angular/core';
import { ChangedetectorReference } from '../../../../../../../../../core/changedetector/changedetectoreference';
import { NGXSimpleSeriesChartComponent } from '../../ngx-simple-series-chart.class';
import {
  BarChartDisplayOptions,
  SimpleVerticalBarChart
} from '../../../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-simple-vertical-bar-chart',
  templateUrl: './simple-vertical-bar-chart.component.html',
  styleUrls: ['./simple-vertical-bar-chart.component.scss'],
  providers: [ChangedetectorReference]
})
export class SimpleVerticalBarChartComponent extends NGXSimpleSeriesChartComponent<SimpleVerticalBarChart, BarChartDisplayOptions> implements OnInit {

  constructor(protected cdReference: ChangedetectorReference,
              protected elementRef: ElementRef) {
    super(cdReference);
  }

  GetViewValueOnInitializeChart(): [number, number] {
    return this.GetDefaultViewValueOnInitializeChart(this.elementRef);
  }

  GetViewValueOnResize(): [number, number] {
    return this.GetDefaultViewValueOnResize(this.elementRef);
  }
}
