import { Injectable, Optional, SkipSelf } from '@angular/core';
import { filter, map, takeUntil } from 'rxjs/operators';
import { CommandService } from '../../core/commands/command.service';
import { IResultCollector } from '../../core/commands/resultcollector.interface';
import { CoreMappingOpenInModalCommand } from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import { DecoupledModalBridgeService } from '../decoupled-modal/decoupled-modal-bridge.service';
import { ModalReference } from '../decoupled-modal/models/decoupled-modal-bridge.interface';
import { DestroyableObjectTrait } from '../utils/destroyableobject.trait';
import { backendTypeMatch } from '../utils/typescript.utils';
import { MappingFlowModalComponent } from './mapping-flow-modal/mapping-flow-modal.component';

/**
 * Servicios globales de la API de formularios (i.e. listener para los comandos)
 */
@Injectable({
  providedIn: 'root',
})
export class MappingGlobalServicesClass extends DestroyableObjectTrait {

  /**
   * MappingGlobalServicesClass class constructor.
   */
  constructor(
    protected dmbs: DecoupledModalBridgeService,
    protected commandService: CommandService,
    @Optional() @SkipSelf() parentModule?: MappingGlobalServicesClass
  ) {
    super();

    if (parentModule) {
      throw new Error(
        'MappingGlobalServicesClass is already loaded. Import it in the AppModule only');
    }

    this.commandService.CommandObservable
      .pipe(
        filter(obj => backendTypeMatch(CoreMappingOpenInModalCommand.$type, obj.Argument)),
        map((obj) => obj as IResultCollector<CoreMappingOpenInModalCommand, () => Promise<boolean>>),
      ).subscribe((next) => {
      next.AddResult(() => new Promise(() => this.OpenModal(next.Argument)));
    });
  }

  /**
   * Abre la modal con el flujo de ejecución de mapping
   */
  public OpenModal(command: CoreMappingOpenInModalCommand): void {

    const ref: ModalReference<unknown> = this.dmbs.showComponent(
      MappingFlowModalComponent,
      {CssClasses: ['mapping-flow-modal']},
      {
        availableMappings: command.AvailableMappings
      }
    );

    ref.close
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(() => {
        if (!command.OnCloseCommands) {
          return Promise.resolve();
        }
        return this.commandService.executeCommandChain(Object.values(command.OnCloseCommands));
      });
  }
}
