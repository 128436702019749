import { ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit, SkipSelf } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { filter, mergeMap, takeUntil, throttleTime } from 'rxjs/operators';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';

import { GeocodeResult, ReverseGeocodeResult } from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { FrontendGeocodingService } from '../../../geocoding/frontend-geocoding.service';
import { getNewtonSoftRealValue } from '../../../utils/typescript.utils';
import { FormManagerService } from '../../form-manager/form-manager.service';
import { FrontendFormElementInput } from '../formelementinput.class';
import { FrontendFormElementWrapper } from '../formelementwrapper.class';

/**
 * Google address data model.
 *
 * Returned from the `ReverseGeocode` method of the `FrontendGeocodingService`.
 */
interface AddressData {
  AddressMetadata: {
    Country: string,
    CountryShortName: string,
    Region: string,
    RoadName: string,
    StreetNumber: string,
  },
  FormattedAddres: string,
  Id: string,
  RawResult: string
}

/**
 * Address raw data model interface.
 *
 * This model corressponds to the RawResult property
 * of the `AdressData` interface.
 */
interface AddressRawData {
  address_components: AddressComponent[];
  formatted_address: string,
  geometry: any,
  partial_match: boolean,
  place_id: string,
  types: string[]
}

/**
 * The address component  correspondes to the property adress_components
 * of the `AddressRawData` interface.
 *
 * The AddressRawData is an array containing the separate components
 * applicable to this address.
 */
interface AddressComponent {
  long_name: string,
  short_name: string,
  types: string[]
}

/**
 * Map component used on form API.
 */
@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent
  extends FrontendFormElementWrapper implements OnInit, OnDestroy {

  /**
   * The Google Maps API is loaded
   */
  googleMapsApiLoaded = false;

  /**
   * Position
   */
  position: google.maps.LatLngLiteral;

  /**
   * Initial zoom for map
   */
  zoom: number;

  /**
   * Loading spinnner boolean.
   */
  loading = false;

  /**
   * When true, all value change events are processed.
   */
  private takeValues: boolean = true;

  /***
   * Constructor for MapsComponent. Taking into account that this component
   * extends from InputComponent, we have to specify the super method for the
   * formManagerService injection.
   *
   * @param {FrontendGeocodingService} geocodingService. service for geocoding
   * @param {FormManagerService} formManagerService. service for form management
   * @param {ChangeDetectorRef} cdRef
   * @param {cdRefParent} ChangeDetectorRef
   */
  constructor(
    protected geocodingService: FrontendGeocodingService,
    protected formManagerService: FormManagerService,
    protected cdRef: ChangeDetectorRef,
    @SkipSelf() protected cdRefParent: ChangeDetectorRef
  ) {
    super(formManagerService, cdRef, cdRefParent);
    this.geocodingService
      .apiLoaded
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(x => {
          this.googleMapsApiLoaded = x;
        }
      )
  }

  /**
   * Component class host binding.
   */
  @HostBinding('class')
  get hostWrapperClasses(): string {
    return this.getComponentClassesRendered();
  }

  /**
   * @inheritdoc
   */
  formElementInstance(): FrontendFormElementInput {
    // This method doesn't wraps an innner component. So this method is not
    // supported.
    throw new Error('Not supported.');
  }

  /***
   * On Init hook we get the values of lat & lng and subscribe to its changes.
   * We also subscribe to any address change considering the mapping
   * consideration for the address and that we shouldn't send a request to the
   * geocoding service on every key stroke. A timeout is set to avoid this
   * and send a request when we have a second of idle time on the address setting.
   */
  ngOnInit(): void {
    this.group.controls[this.config.latitudeElementKey]
      .valueChanges
      .takeUntil(this.componentDestroyed$)
      .subscribe(
        (newLat) => {
          this.position.lat = newLat ? Number(newLat) : this.geocodingService.defaultLatitude;
          this.forceDetectChanges();
        });

    this.group.controls[this.config.longitudeElementKey]
      .valueChanges
      .takeUntil(this.componentDestroyed$)
      .subscribe(
        (newLng) => {
          this.position.lng = newLng ? Number(newLng) : this.geocodingService.defaultLongitude;
          this.forceDetectChanges();
        });

    if (this.config.addessMappingObject) {
      Object.keys(this.config.addessMappingObject)
        .map((item) => {
          const selector: string = this.config.addessMappingObject[item];
          this.manageAddressSubscriptions(selector, this.group);
        });
    }
    const auxLat: number = this.formManagerService.getFormComponentValue(this.config.latitudeElementKey);
    const auxLng: number = this.formManagerService.getFormComponentValue(this.config.longitudeElementKey);

    this.position = {
      lat: auxLat ? auxLat : this.geocodingService.defaultLatitude,
      lng: auxLng ? auxLng : this.geocodingService.defaultLongitude
    } as google.maps.LatLngLiteral;

    this.zoom = 15;
  }

  /***
   * change marker method fired when right click is pressed on the map.
   * @param event
   */
  changeMarker(event: any): void {
    this.loading = true;
    if (this.config.editableMap) {
      const latlng: any = event.coords as any;

      this.formManagerService
        .setFormComponentValue(this.config.longitudeElementKey, latlng.lng, true);
      this.formManagerService
        .setFormComponentValue(this.config.latitudeElementKey, latlng.lat, true);

      this.geocodingService
        .ReverseGeocode(latlng)
        .takeUntil(this.componentDestroyed$)
        .take(1)
        .subscribe((result: ReverseGeocodeResult[]) => {
          this.takeValues = false;
          const values: AddressData[] = getNewtonSoftRealValue(result);

          if (isNullOrUndefined(values) || values.length === 0) {
            this.takeValues = true;
            return;
          }

          const value: AddressData = values.shift()
          const addressComponents: AddressRawData = JSON.parse(value.RawResult);
          const mapping: object = this.config.addessMappingObject;

          addressComponents.address_components
            .map((component: AddressComponent) => {
              if (!mapping.hasOwnProperty(component.types[0])) {
                return;
              }

              const mappingItem: string = (mapping || {})[component.types[0]];

              if (isNullOrUndefined(mapping)) {
                return;
              }

              const control: AbstractControl
                = this.group.controls[mappingItem];

              if (isNullOrUndefined(control)
                || isNullOrUndefined(control.setValue)) {
                return;
              }

              control.patchValue(component.long_name);
              this.formManagerService.detectChangesFormElement(mappingItem);
            });

          setTimeout(() => {
            this.takeValues = true;
            this.loading = false;
            this.cdRef.detectChanges();
          }, 1000);
        });
    }
  }

  /***
   * address parsing from input fields.
   * @returns {string} address on string format
   */
  getAddressFromFields(): string {
    let result: string = this.getValueFromAddressSelector('route');

    if (!result) {
      return null;
    }

    const selectors: string[] = ['street_number', 'postal_code', 'locality', 'country'];
    selectors.forEach((x: string) => {
      const value: string = this.getValueFromAddressSelector(x);
      if (value) {
        result += ` ${value}`
      }
    });
    return result;
  }

  getValueFromAddressSelector(selector: string): string {
    if (!this.config.addessMappingObject.hasOwnProperty(selector)) {
      return null;
    }
    const formElementSelector: string = this.config.addessMappingObject[selector];
    const value: any = this.formManagerService.getFormComponentValue(formElementSelector);
    const valueName: string = value && JSON.parse(JSON.stringify(value)) && JSON.parse(JSON.stringify(value)).Name ? JSON.parse(JSON.stringify(value)).Name : null;
    return value && valueName ? valueName : value;
  }

  /**
   * Manage address subscriptions with the geocoding service
   * @param {string} selector
   * @param {FormGroup} group
   */
  manageAddressSubscriptions(selector: string, group: FormGroup): void {
    if (selector.split('.').length !== 1) {
      const nestedSelector: string = selector
        .split('.').slice(1).join('.');
      const nestedGroup: FormGroup = group
        .controls[selector.split('.')[0]] as FormGroup;

      this.manageAddressSubscriptions(nestedSelector, nestedGroup);
      return;
    }

    group.controls[selector]
      .valueChanges
      .pipe(
        mergeMap(() => {
          if (this.takeValues) {
            return of(true);
          }

          return of(false);
        }),
        filter((proceed) => proceed),
        throttleTime(1000),
        mergeMap(() => {
          this.loading = true;
          this.cdRef.detectChanges();

          return this.geocodingService.Geocode(this.getAddressFromFields());
        }),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((result: GeocodeResult[]) => {
        const values: GeocodeResult[] = getNewtonSoftRealValue(result);

        if (isNullOrUndefined(values) || values.length === 0) {
          return;
        }

        const value: GeocodeResult = values.shift();

        this.position = {
          lat: value.Latitude,
          lng: value.Longitude
        };

        this.loading = false
        this.formManagerService.setFormComponentValue(this.config.latitudeElementKey, value.Latitude, true);
        this.formManagerService.setFormComponentValue(this.config.longitudeElementKey, value.Longitude, true);
        this.cdRef.detectChanges();
      });
  }
}
