import { AfterViewInit, ChangeDetectorRef, Directive, Injectable } from '@angular/core';
import { AbstractControl, ControlValueAccessor, ValidationErrors, Validator } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

import {
  FormElementInput,
  FormElementInputNumber,
  FormElementInputRegexValidator,
  FormHandlerType,
  FormSubmitData,
  FormValidationTypeEnum,
  IFormElementInputValidator
} from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { FormManagerService } from '../form-manager/form-manager.service';
import { FrontendFormElementAware } from './formelement.class';
import { debounceTime, delay, take, takeUntil } from 'rxjs/operators';
import { backendTypeMatch, isNullOrUndefined, isNullOrWhitespace, isString } from '../../utils/typescript.utils';
import { isNumber } from 'app/shared/utils/typescript.utils';
import { stateApiTriggerSourceData } from '../state-api/stateApiTriggerSourceData';
import { TranslatorService } from '../../../core/translator/services/rest-translator.service';

/**
 * Clase base para todos los elementos de formularios en el nivel de
 * ControlValueAccessor (no los wrappers).
 */
@Directive()
@Injectable()
export abstract class FrontendFormElementInput
  extends FrontendFormElementAware
  implements ControlValueAccessor, Validator, AfterViewInit {

  /**
   * La propagación de cambios la hacemos con un debounce, para minimizar
   * el impacto en la propagación rápida de inputs que cambian con velocidad
   * (i.e. cajas de texto o similar).
   */
  private propagateChangeDebouncer: Subject<any> = new Subject<any>();

  /**
   * Permite de manera externa añadir funciones que permitan decidir si el campo
   * puede o no cambiar su valor
   */
  public canChangeValue: (data: stateApiTriggerSourceData) => Observable<boolean> = () => Observable.of(true);

  /**
   * This function is called when the control status changes to or from "DISABLED".
   * Depending on the value, it will enable or disable the appropriate DOM element.
   *
   * @param isDisabled
   */
  setDisabledState?(isDisabled: boolean): void {
    this.cdRef.detectChanges();
  };

  /**
   * Callback function that should be called when the control's value
   * changes in the UI.
   * @see https://angular.io/api/forms/ControlValueAccessor
   */
  private doPropagateChange: (value: any) => void = () => {
  };

  /**
   * Callback function that should be called when the control receives
   * a blur event.
   * @see https://angular.io/api/forms/ControlValueAccessor
   */
  private doPropagateTouch: any = () => {
  };

  /**
   * Callback function to call when the validator inputs change.
   * @see https://angular.io/api/forms/Validator
   */
  private doPropagateValidatorChange: () => void = () => {
  };

  /**
   * `FrontendFormElementInput` class constructor.
   *
   * @param {FormManagerService} formManagerService
   * @param {ChangeDetectorRef} cdRef
   */
  constructor(
    protected formManagerService: FormManagerService,
    protected cdRef: ChangeDetectorRef,
    protected localeService: TranslatorService
  ) {
    super(formManagerService, cdRef);

    this.propagateChangeDebouncer
      .pipe(
        takeUntil(this.componentDestroyed$),
        debounceTime(150)
      )
      .subscribe(((value: any): void => this.doPropagateChange(value)).bind(this));
  }

  /**
   * Writes a new value to the element.
   *
   * This method is called by the forms API to write to the view when
   * programmatic changes from model to view are requested.
   *
   * @see https://angular.io/api/forms/ControlValueAccessor#writeValue
   *
   * @param {any} obj
   */
  abstract writeValue(obj: any): void;

  /**
   * Registers a callback function that should be called when the control's value
   * changes in the UI.
   *
   * This is called by the forms API on initialization so it can update the form
   * model when values propagate from the view (view -> model).
   *
   * If you are implementing `registerOnChange` in your own value accessor, you
   * will typically want to save the given function so your class can call it
   * at the appropriate time.
   *
   * @see https://angular.io/api/forms/ControlValueAccessor
   */
  registerOnChange(fn: any): void {
    this.doPropagateChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives
   * a blur event.
   *
   * This is called by the forms API on initialization so it can update the form model
   * on blur.
   *
   * If you are implementing `registerOnTouched` in your own value accessor, you
   * will typically want to save the given function so your class can call it
   * when the control should be considered blurred (a.k.a. "touched").
   *
   * @see https://angular.io/api/forms/ControlValueAccessor
   */
  registerOnTouched(fn: any): void {
    this.doPropagateTouch = fn;
  }

  /**
   * Registers a callback function to call when the validator inputs change.
   *
   * @see https://angular.io/api/forms/Validator
   */
  registerOnValidatorChange?(fn: () => void): void {
    this.doPropagateValidatorChange = fn;
  }

  /**
   * Indica si el componente está "ocupado". Cuando un componente está ocupado, no se permiten
   * cambios de valores en los input del formulario, ni ejecuciones de submit/rebuild/rebuildvalues, etc.
   */
  componentIsBussy(): boolean {
    return false;
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {

    // Si tenemos un async value callback nos ponemos a la cola de carga
    if (
      this.config
      && this.config.FormElement
      && this.config.FormElement.Handlers
      && this.config.FormElement.Handlers.find((i) => i.Type === FormHandlerType.InputElementAsyncValue)) {
      const formSubmitData: FormSubmitData = new FormSubmitData();
      formSubmitData.formInput = this.formManagerService.getFormComponentValue('');
      formSubmitData.submitElement = this.config.FormElement.ClientPath;
      this.formManagerService.getElementInputAsyncValueCallback(this.config.FormElement, formSubmitData)
        .pipe(
          takeUntil(this.componentDestroyed$)
        ).subscribe((i) => {
        this.writeValue(i.Value);
      });
    }

    super.ngAfterViewInit();
  }

  /**
   * Propagar un cambio en el valor del control
   *
   * @param value
   * Valor a propagar
   *
   * @param debounce
   * Usar true para hacer un debounce automático en la propagación
   *
   * @param debounce
   * Algunos componentes necesitan hacer un detectChanges instantáneo
   */
  protected propagateChange(value: any, debounce: boolean = false, detectChanges: boolean = true, skipCanChangeValue: boolean = false): void {

    // No propagamos el cambio de valor hasta que la API de acciones nos diga que podemos hacerlo
    if (this.canChangeValue && !skipCanChangeValue) {
      const triggerElementData: stateApiTriggerSourceData = new stateApiTriggerSourceData();
      triggerElementData.path = this.config.ClientPath;
      // TODO: Para poder dejar de usar getFormComponentValueActive aquí, deberíamos refactorizar el propagatechanges por comppleto
      // cosa que ahora no es posible...
      triggerElementData.prevValue = this.formManagerService.getFormComponentValueActive(triggerElementData.path);
      triggerElementData.newValue = value;
      this.canChangeValue(triggerElementData)
        .pipe(
          takeUntil(this.componentDestroyed$),
          take(1)
        )
        .subscribe((canChange: boolean) => {
          if (canChange === true) {
            this.propagateChange(value, debounce, detectChanges, true);
          } else {
            this.writeValue(triggerElementData.prevValue);
          }
        });
      return;
    }

    if (detectChanges === true) {
      this.cdRef.detectChanges();
    }
    if (debounce === true) {
      this.propagateChangeDebouncer.next(value);
    } else {
      this.doPropagateChange(value);
    }
  }

  /**
   * Focus input in this form element. Return true if the element supports focus, false if it does not support it
   * or is not implemented. Default implementation is false.
   */
  public focusInput(): boolean {
    return false;
  }

  /**
   * Sobercargamos para tener en cuenta el has-error
   */
  getComponentClasses(): string[] {
    const classes: string[] = super.getComponentClasses();
    const component: AbstractControl = this.formManagerService
      .getFormComponent(this.config.ClientPath);

    if (component.enabled && component.valid === false && component.touched) {
      classes.push('has-error');
    }

    return classes;
  }

  /**
   * For elements that support options based inputs, if the input has options available to the user.
   */
  hasOptions(): boolean {
    return true;
  }

  /**
   * Controls can override how the required validation is executed.
   */
  emptyValue(value: any): boolean {

    if (isNullOrUndefined(value)) {
      return true;
    }

    // En los checkbox tipo simple un false indica que no hay valor
    if (value === false) {
      return true;
    }

    if (value === '') {
      return true;
    }

    if (Array.isArray(value) && value.length === 0) {
      return true;
    }

    if (JSON.stringify(value) === JSON.stringify({})) {
      return true;
    }

    return false;
  }

  /**
   * Method that performs synchronous validation against the provided control.
   *
   * @param control The control to validate against.
   *
   * @returns {ValidationErrors} A map of validation errors if validation fails,
   * otherwise null.
   *
   * @see https://angular.io/api/forms/Validator#validate
   */
  validate(c: AbstractControl): ValidationErrors {

    // Si no tengo valor y no soy requerido, no hace falta validar.
    if (this.emptyValue(c.value) && !this.config.required) {
      return [];
    }

    const isVisible: boolean = this.config.visible;
    const isEnabled: boolean = !!!this.getIsDisabled(c);

    // Si no tengo configuración, o no soy visible, o no estoy habilitado
    if (isNullOrUndefined(this.config) || !isVisible || !isEnabled) {
      return [];
    }

    return this.doValidate(c);
  }

  /**
   * Handler for the Validator interface.
   *
   * @param control The control to validate against.
   *
   * @returns {ValidationErrors} A map of validation errors if validation fails,
   * otherwise null.
   *
   * @see https://angular.io/api/forms/Validator#validate
   */
  doValidate(c: AbstractControl): ValidationErrors {

    const errors: object = {};

    const elementInput: FormElementInput =
      Object.assign(new FormElementInput(), this.config.FormElement);

    const elementInputNumber: FormElementInputNumber =
      Object.assign(new FormElementInputNumber(), this.config.FormElement);

    const currentValue: any = c.value;

    // Hay algunos casos raros con campos que no tienen label y en esos
    // casos no queremos que aparezca undefined como nombre del campo
    const itemLabelForMessage: string = (this.config.label ? this.config.label : null);

    if (this.config.required === true) {
      if (this.emptyValue(currentValue)) {
        if (itemLabelForMessage === null) {
          errors[FormValidationTypeEnum.Required] = this.localeService.get(`El campo es obligatorio.`);
        } else {
          errors[FormValidationTypeEnum.Required] = this.localeService.get(`El campo @field es obligatorio.`,
            {
              '@field': itemLabelForMessage
            });
        }
      }
    }

    if ((elementInput.MaxLength > 0 && !isNullOrUndefined(currentValue)) || currentValue === Infinity) {
      if (currentValue.toString().length > elementInput.MaxLength) {
        if (itemLabelForMessage === null) {
          errors[FormValidationTypeEnum.MaxLength] = this.localeService.get(`El campo no puede tener más de @max caracteres.`,
            {
              '@max': elementInput.MaxLength?.toString()
            });
        } else {
          errors[FormValidationTypeEnum.MaxLength] = this.localeService.get(`El campo @campo no puede tener más de @max caracteres.`,
            {
              '@campo': itemLabelForMessage,
              '@max': elementInput.MaxLength?.toString()
            });
        }
      }
    }

    if (elementInput.MinLength > 0 && !isNullOrUndefined(currentValue)) {
      if (currentValue.toString().length < elementInput.MinLength) {
        if (itemLabelForMessage === null) {
          errors[FormValidationTypeEnum.MinLength] = this.localeService.get(`El campo no puede tener menos de @min caracteres.`,
            {
              '@min': elementInput.MinLength?.toString()
            });
        } else {
          errors[FormValidationTypeEnum.MinLength] = this.localeService.get(`El campo @campo no puede tener menos de @min caracteres.`,
            {
              '@campo': itemLabelForMessage,
              '@min': elementInput.MinLength?.toString()
            });
        }
      }
    }

    if (isNumber(elementInputNumber.MaxValue) && !isNullOrUndefined(currentValue)) {
      if (Number(currentValue) > elementInputNumber.MaxValue) {
        if (itemLabelForMessage === null) {
          errors[FormValidationTypeEnum.MaxValue] = this.localeService.get(`El campo no puede ser superior a @max.`,
            {
              '@max': elementInputNumber.MaxValue?.toString()
            });
        } else {
          errors[FormValidationTypeEnum.MaxValue] = this.localeService.get(`El campo @campo no puede ser superior a @max.`,
            {
              '@campo': itemLabelForMessage,
              '@max': elementInputNumber.MaxValue?.toString()
            });
        }
      }
    }

    if (isNumber(elementInputNumber.MinValue) && !isNullOrUndefined(currentValue)) {
      if (Number(currentValue) < elementInputNumber.MinValue) {
        if (itemLabelForMessage === null) {
          errors[FormValidationTypeEnum.MinValue] = this.localeService.get(`El campo no puede ser inferior a @min.`,
            {
              '@min': elementInputNumber.MinValue?.toString()
            });
        } else {
          errors[FormValidationTypeEnum.MinValue] = this.localeService.get(`El campo @campo no puede ser inferior a @min.`,
            {
              '@campo': itemLabelForMessage,
              '@min': elementInputNumber.MinValue?.toString()
            });
        }
      }
    }

    if (elementInput.Validators) {
      for (const validatorKey of Object.keys(elementInput.Validators)) {
        const validator: IFormElementInputValidator = elementInput.Validators[validatorKey];

        if (backendTypeMatch(FormElementInputRegexValidator.$type, validator)) {
          this.doValidateRegex(validator as FormElementInputRegexValidator, errors, currentValue);
        } else {
          console.warn('Unknown frontend validator: ' + validator.$type);
        }
      }
    }

    return errors;
  }

  /**
   * Ejecutar el validador de expresión regular
   *
   * @param validator
   * @param errors
   * @param inputValue
   */
  doValidateRegex(validator: FormElementInputRegexValidator, errors: {}, inputValue: any): void {

    if (isNullOrWhitespace(validator.RegexValidation) || isNullOrWhitespace(inputValue)) {
      return;
    }

    const expression: RegExp = new RegExp(validator.RegexValidation);

    const stringValue: string = inputValue.toString();

    if (stringValue !== '' && !isNullOrUndefined(stringValue)) {
      if (expression.test(stringValue) === false) {
        errors[validator.Id] = validator.RegexValidationMessage;
      }
    }
  }

  /**
   * Permite comparar dos valores de datos de este campo
   * para determinar si son o no iguales.
   *
   * @param valueA
   * @param valueB
   */
  equalValues(valueA: any, valueB: any): boolean {

    if (isNullOrUndefined(valueA) && isNullOrUndefined(valueB)) {
      return true;
    }

    if (valueA === valueB) {
      return true;
    }

    if (this.ensureString(valueA) === this.ensureString(valueB)) {
      return true;
    }

    return false;
  }

  ensureString(value: any): string {
    return isString(value) ? value : JSON.stringify(value);
  }

  /**
   * Comparar dos valores, devuelve:
   *   1 si A > B
   *   0 si A = B
   *   2 si B > A
   * @param valueA
   * @param valueB
   */
  compareValues(valueA: any, valueB: any): number {
    if (valueA === valueB) {
      return 0;
    }
    if (valueA > valueB) {
      return 1;
    }
    return -1;
  }

  /**
   * Propagate that this element has been touched
   */
  propagateTouch(): void {

    const component: AbstractControl = this.formManagerService.getFormComponent(this.config.ClientPath);

    // No volver a propagar si ya estaba touched
    if (component.touched === true) {
      return;
    }

    // Se ha puesto un delay de 150 milisegundos para lidiar con el siguiente caso:
    // * Usuario está en un formulario con el focus en un input obligatorio que no ha escrito nada
    // * Usuario pulsa en guardar, pero como el blur ocurre antes del click, aparece el mensaje de validación
    // * Como el mensaje de validación ha desplazado el botón, ya no toma el click, por lo que parece que el usuario nunca presionó el botón
    Observable.of(true)
      .pipe(
        delay(150)
      )
      .subscribe(() => {
        // Hay que hacer estas operaciones para que se actualice el estado
        // del componente (validaciones, etc.)
        this.doPropagateTouch();
        component.updateValueAndValidity({emitEvent: true, onlySelf: false});
      });
  }
}
