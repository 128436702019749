import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { of, timer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { mergeMap, take } from 'rxjs/operators';

import { fuseAnimations } from '../../../../../../../../../../@fuse/animations';
import { getInSafe, UtilsTypescript } from '../../../../../../../../../shared/utils/typescript.utils';
import { FuseChartsService } from '../../../../fuse-charts.service';
import { BaseFuseChartTypeInterface } from '../../base-fuse-chart-type.class';
import { ChangedetectorReference } from '../../../../../../../../../core/changedetector/changedetectoreference';
import {
  ISerie,
  SingleNumberChart, SingleNumberChartDisplayOptions
} from '../../../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-single-number-chart',
  templateUrl: './single-number-chart.component.html',
  styleUrls: ['./single-number-chart.component.scss'],
  animations: fuseAnimations,
  providers: [ChangedetectorReference]
})
export class SingleNumberChartComponent extends BaseFuseChartTypeInterface<SingleNumberChart, SingleNumberChartDisplayOptions> implements OnInit {

  /**
   * Public variable that holds the number to display.
   */
  displayNumber: string = null;

  /**
   * SingleNumberChartComponent class constructor.
   *
   * @param {FuseChartsService} fuseChartsService
   * @param {ChangeDetectorRef} cdRef
   */
  constructor(
    public fuseChartsService: FuseChartsService,
    protected cdRef: ChangeDetectorRef,
    protected cdReference: ChangedetectorReference) {
    super(cdReference);
  }

  /**
   * Component init lifecycle method.
   */
  ngOnInit(): void {
    this.rollNumbers()
      .takeUntil(this.componentDestroyed$)
      .subscribe((value: number) => {
        this.displayNumber = this.formatNumber(value);
        this.detectChanges();
      });
  }

  /**
   * Number formatter.
   * @param {string} num
   */
  formatNumber(num: number): string {
    return (num || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  /**
   * Returns the number to display.
   */
  get singleNumber(): Number {
    const serie: ISerie = this.getDataSeries().Series[this.currentChart.ValueSeries];
    return UtilsTypescript.ObjectValues(serie.Values)[0].Value;
  }

  /**
   * Return the description for a chart.
   */
  get description(): string {
    return getInSafe(this.currentChart, c => c.Description, null);
  }

  /**
   * EL número para pintar..
   */
  private rollNumbers(): Observable<Number> {
    const singleNumber: number = this.singleNumber as number;
    const delay: number = 50;
    const loops: number = 25;

    return timer(0, delay)
      .takeUntil(this.componentDestroyed$)
      .pipe(
        take(loops),
        mergeMap((i: number) => {
          const value: number = (singleNumber / loops) * i;
          return of(i !== (loops - 1) ? Math.ceil(value) : singleNumber);
        }),
      );
  }

  /**
   * Triggers a change detection cycle.
   */
  private detectChanges(): void {
    this.cdRef.detectChanges();
  }

  initializeChart(): void {
    this.rollNumbers()
      .takeUntil(this.componentDestroyed$)
      .subscribe((value: number) => {
        this.displayNumber = this.formatNumber(value);
        this.detectChanges();
      });
  }

  getTooltip(): string {
    if (!this.displayOptions || this.displayOptions.TooltipDisabled || !this.displayOptions.TooltipText) {
      return '';
    }

    return this.displayOptions.TooltipText;
  }
}
