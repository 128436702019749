import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccessComponent } from './access/access.component';
import { PersonalDataComponent } from './personalData/personalData.component';
import { PersonManagementComponent } from './personManagement/person-management.component';

const routes: Routes = [
  { path: 'profile', redirectTo: 'details', pathMatch: 'full' },
  { path: 'access', component: AccessComponent, data: { breadcrumb: 'Acceso', mapping: 'editProfile.access' } },
  { path: 'personalData', component: PersonalDataComponent, data: { breadcrumb: 'Personas', mapping: 'editProfile.personal' } },
  { path: 'person-data', component: PersonManagementComponent, data: { breadcrumb: 'Personas', mapping: 'editProfile.persondata' } },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})

export class EditProfileRoutingModule {
}
