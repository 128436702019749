import {HttpErrorResponse, HttpRequest, HttpResponse} from "@angular/common/http";

export class UnhandledError implements  Error {
  readonly name = "UnhandledError";
  readonly message: string;
  readonly error: any | null;

  constructor(
    request: HttpRequest<any>,
    response: HttpErrorResponse,
  )
  {
    this.message = "Unhandled error in backend response.\n\nRequest:\n" + JSON.stringify(request) + "\n\nResponse:\n" + JSON.stringify(response);
    this.error = response.error;
  };
}
