import { NavigationService } from './navigation.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppContextService } from '../../shared/context/context.service';
import { filter, flatMap, switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../authentication/auth.service';
import { BootstrapService } from '../../app-bootstrap.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationContextRefreshService {

  /**
   * Get an instance of NavigationContextRefreshService
   */
  constructor(
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private appContextService: AppContextService,
    private bootstrapService: BootstrapService) {

    /**
     * Si se cambia el contexto... la navegación deja de ser válida.
     */
    this.appContextService.ContextAfterChanged
      .pipe(
        switchMap(() => this.bootstrapService.bootDataReady$()),
        switchMap(() => {
          // En realidad no hay que hacer un refresh navigation... solo hay que borrar cachés y enviar a la home
          this.navigationService.clearNavigationCaches(true);
          return from(this.navigationService.goToHome());
        }),
        switchMap(() => this.navigationService.executeNavigation(this.activatedRoute.snapshot))
      )
      .subscribe(o => {
      });

    /**
     * TODO: ¿Esto no se puede hacer en el propio servicio de navegación? Asuí se usa exclusivamente
     * el servicio de navegación por lo que no debería haber acomplamiento de servicios y/o componentes.
     */
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        flatMap((event: NavigationEnd) => this.navigationService.executeNavigation(this.activatedRoute.snapshot))
      )
      .subscribe();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        flatMap((event: NavigationStart) => this.navigationService.executeNullNavigation())
      )
      .subscribe();
  }
}
