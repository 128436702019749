<!--<div class="chart-container" style="position: relative; height:280px; width:100%">
  <canvas #canvasItem height="280"></canvas>
</div>-->
<div class="chart-container"
     (appOnWindowResize)="onNgxChartResizedWithDirective()"
     (window:resize)="onNgxChartResized($event)"
     resizeWindowDebounceTime="500">
  <ngx-charts-line-chart
      [results]="data"
      [gradient]="displayOptions?.Gradient"
      [xAxis]="!displayOptions?.HideXAxis"
      [yAxis]="!displayOptions?.HideYAxis"
      [legend]="getLegendDisplayOptions()?.ShowLegend"
      [legendTitle]="getLegendDisplayOptions()?.LegendTitle"
      [legendPosition]="getLegendDisplayOptions()?.LegendPosition"
      [showXAxisLabel]="!displayOptions?.HideXAxisLabel"
      [showYAxisLabel]="!displayOptions?.HideYAxisLabel"
      [xAxisLabel]="displayOptions?.XAxisLabel"
      [yAxisLabel]="displayOptions?.YAxisLabel"
      [yScaleMax]="displayOptions?.YScaleMax"
      [yScaleMin]="displayOptions?.YScaleMin"
      [tooltipDisabled]="displayOptions?.TooltipDisabled"
      [scheme]="colorScheme"
      [maxXAxisTickLength]="displayOptions?.MaxXAxisTickLength"
      [trimYAxisTicks]="displayOptions?.TrimYAxisTicks"
      [timeline]="displayOptions?.TimeLine"
      [view]="view"
      #chart>
    <ng-template #tooltipTemplate let-model="model"
                 *ngIf="displayOptions && !displayOptions?.TooltipDisabled && displayOptions?.TooltipText">
      <div [innerHTML]="getTooltipText(model)"></div>
    </ng-template>
  </ngx-charts-line-chart>
</div>
