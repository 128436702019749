import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Observable } from 'rxjs';

import { fuseAnimations } from '../../../../../../../../@fuse/animations';
import { DestroyableObjectTrait } from '../../../../../../../shared/utils/destroyableobject.trait';
import {
  asIterableObject,
  backendTypeMatch,
  getInSafe,
  isNullOrUndefined
} from '../../../../../../../shared/utils/typescript.utils';
import { FuseChartsService } from '../../fuse-charts.service';
import { getChartName } from '../chart-types/fuse-chart-types';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';
import { CommunicationService } from '../../../../../../../core/communication/communication.service';
import { IResultCollector } from '../../../../../../../core/commands/resultcollector.interface';
import { CommandService } from '../../../../../../../core/commands/command.service';
import { FuseChartUtils } from '../../fuse-chart-utils.class';
import { FuseWidgetComponent } from '../../../../../../../../@fuse/components/widget/widget.component';
import { ChangedetectorReference } from '../../../../../../../core/changedetector/changedetectoreference';
import { DecoupledModalBridgeService } from '../../../../../../../shared/decoupled-modal/decoupled-modal-bridge.service';
import { ModalReference } from '../../../../../../../shared/decoupled-modal/models/decoupled-modal-bridge.interface';
import { ModalBatchItemComponent } from '../../../../../../../shared/batch/modal-batch-item/modal-batch-item.component';
import {
  BatchTaskInfo,
  ChartDataSeries,
  ChartExecutionResponse,
  ChartExportingResultBatchTask,
  ChartExportingResultFileRef, ChartExportingResultHtmlTable,
  ChartPluginRequest, ChartUserConfiguration,
  CoreChartsCommandRefreshChart,
  DtoFrontendModalType,
  IChart,
  IChartAction,
  IChartExportingResult,
  ISerie,
  PieChart
} from 'app/core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-fuse-chart',
  templateUrl: './fuse-chart.component.html',
  styleUrls: ['./fuse-chart.component.scss'],
  providers: [ChangedetectorReference, FuseChartsService],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class FuseChartComponent extends DestroyableObjectTrait implements OnInit, OnChanges {

  /**
   * ViewChild widget
   */
  @ViewChild('widget') widget: FuseWidgetComponent;

  /**
   * Plugin identifier for the chart.
   */
  @Input() chartId: string;

  /**
   * Arguments to pass on the list query.
   */
  @Input() args: any[];

  /**
   * On init the Chart, skips the caches. Used with FormElementChartEmbeed Options
   */
  @Input() skipCaches?: boolean;

  /**
   * On init the Chart, skips the caches. Used with FormElementChartEmbeed Options
   */
  @Input() forceSyncExecution?: boolean;

  /**
   * The current plugin rquest
   */
  currentPluginRequest: ChartPluginRequest;

  /**
   * Boolean that indicates if the widget must be drawn.
   */
  hidden: boolean = false;

  /**
   * Initialized Flag
   */
  initialized: boolean = false;

  /**
   * Boolean that indicates if there are not results to show.
   */
  isEmpty: boolean;

  /**
   * Checks if the component is intersected by the Observer Intersection API
   */
  isIntersected: boolean;

  /**
   * Chart actions
   */
  actions: { [key: string]: IChartAction };

  /**
   * Show Filters
   */
  showFilters: boolean = false;

  /**
   * Show Html Table
   */
  showHtmlTable: boolean = false;

  /**
   * Html table
   */
  htmlTable: string = '<p></p>';

  /**
   * Boolean that indicates if ha lanzado un error el back
   */
  hasError: boolean = false;

  /**
   * Chart Info
   */
  chartInfo: {
    title: string,
    description: string;
    hasFilters: boolean,
    chartIsExportableAsImage: boolean,
    exportingAsImageChartDeferred: boolean
    chartIsExportableAsExcel: boolean,
    exportingAsExcelChartDeferred: boolean,
    chartIsExportableAsHtmlTable: boolean,
    exportingAsHtmlTableChartDeferred: boolean
  }

  /**
   * Ctor
   * @param fuseChartsService
   * @param cdRef
   * @param fileService
   * @param commandService
   */
  constructor(
    public fuseChartsService: FuseChartsService,
    private cdRef: ChangeDetectorRef,
    private fileService: CommunicationService,
    private commandService: CommandService,
    private dmbs: DecoupledModalBridgeService) {
    super();
    this.cdRef.detach();
  }

  public ngOnInit(): void {
    this.detectChanges();

    this.commandService.CommandObservable
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(obj => backendTypeMatch(CoreChartsCommandRefreshChart.$type, obj.Argument)),
        map((obj) => obj as IResultCollector<CoreChartsCommandRefreshChart, (() => Promise<boolean>) | Observable<boolean>>),
        filter(obj => this.currentPluginRequest.Id.match(obj.Argument.ChartId).length > 0)
      ).subscribe((next) => {
      next.AddResult(() => {
        this.reload();
        return Promise.resolve(true);
      });
    });

    this.fuseChartsService.lastChartExecutionResponseChanges
      .pipe(
        takeUntil(this.componentDestroyed$)
      ).subscribe(x => {
      this.showFilters = false;
      this.showHtmlTable = false;
      this.postExecuteLoad(x);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.isIntersected) {
      this.initCurrentPluginRequestAndLoad();
    }
  }

  private initCurrentPluginRequestAndLoad(): void {
    this.currentPluginRequest = new ChartPluginRequest();
    this.currentPluginRequest.Arguments = this.args;
    this.currentPluginRequest.Id = this.chartId;
    if (this.forceSyncExecution) {
      this.currentPluginRequest.DeferredExecution = false;
    }
    this.load();
  }

  private load(): void {
    if (!isNullOrUndefined(this.skipCaches)) {
      this.internalLoad(this.skipCaches);
      return;
    }
    this.internalLoad(false);
  }

  private internalLoad(forceSkipCaches: boolean): void {
    this.fuseChartsService
      .load(this.currentPluginRequest, null, forceSkipCaches)
      .pipe(
        takeUntil(this.componentDestroyed$),
        catchError((err) => {
          this.hasError = true;
          this.detectChanges();
          return Observable.empty();
        }),
        map((response: ChartExecutionResponse) => {
          this.currentPluginRequest = response.PluginRequest;
          return response;
        })
      )
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((x: ChartExecutionResponse) => {
      });
  }

  private postExecuteLoad(chartExecutionResponse: ChartExecutionResponse): void {
    this.chartInfo = {
      title: getInSafe(chartExecutionResponse, e => e.CompiledChart.Title, ''),
      description: getInSafe(chartExecutionResponse, e => e.CompiledChart.Description, ''),
      hasFilters: getInSafe(chartExecutionResponse,
        e => e.UserConfiguration.SignedFormState !== null && e.UserConfiguration.SignedFormState !== undefined,
        false),
      chartIsExportableAsImage: getInSafe(chartExecutionResponse, e => e.CompiledChart.AvailableCharts[e.UserConfiguration.SelectedChart].ExportableAsImageSettings.Allowed, false),
      exportingAsImageChartDeferred: getInSafe(chartExecutionResponse, e => e.CompiledChart.AvailableCharts[e.UserConfiguration.SelectedChart].ExportableAsImageSettings.DeferredExecution, false),
      chartIsExportableAsExcel: getInSafe(chartExecutionResponse, e => e.CompiledChart.AvailableCharts[e.UserConfiguration.SelectedChart].ExportableAsExcelSettings.Allowed, false),
      exportingAsExcelChartDeferred: getInSafe(chartExecutionResponse, e => e.CompiledChart.AvailableCharts[e.UserConfiguration.SelectedChart].ExportableAsExcelSettings.DeferredExecution, false),
      chartIsExportableAsHtmlTable: getInSafe(chartExecutionResponse, e => e.CompiledChart.AvailableCharts[e.UserConfiguration.SelectedChart].ExportableAsHtmlTableSettings.Allowed, false),
      exportingAsHtmlTableChartDeferred: getInSafe(chartExecutionResponse, e => e.CompiledChart.AvailableCharts[e.UserConfiguration.SelectedChart].ExportableAsHtmlTableSettings.DeferredExecution, false),
    };
    this.hidden = chartExecutionResponse.CompiledChart.Access && getInSafe(chartExecutionResponse, e => FuseChartUtils.GetCurrentChartFromChartExecutionResponse(e).ChartDisplayOptions.Hidden, false);
    this.actions = getInSafe(chartExecutionResponse, e => e.CompiledChart.Actions, {});
    if (!this.hasSeries(chartExecutionResponse)) {
      this.isEmpty = true;
    }
    this.initialized = true;
    this.detectChanges();
  }

  private hasSeries(chartExecutionResponse: ChartExecutionResponse): boolean {
    const series: { [key: string]: ISerie } =
      getInSafe(chartExecutionResponse.CompiledChart, c => (c.Data as ChartDataSeries).Series, {});

    const specificEmptyChart: boolean[] = this.getAvailableCharts(chartExecutionResponse)
      .map(c => {
        switch (c['$type'] || '') {
          case getChartName(new PieChart()):
            const chart: PieChart = c as PieChart;
            const serie: ISerie = series[chart.ValueSeriesId];

            return (Object.keys(serie.Values) || []).length === 0;

          // TODO: Añadir casos custom para tipos de chart con logica
          // de serie de valores especial.
        }

        return false;
      });

    if (specificEmptyChart.every((c: boolean) => c)) {
      return false;
    }

    const items: { key: string, value: ISerie }[] = Object.keys(series)
      .map((key) => ({key, value: series[key]}));

    return items.length > 0;
  }

  /**
   * Return all the available charts in the current execution.
   */
  private getAvailableCharts(chartExecutionResponse: ChartExecutionResponse): IChart[] {
    const charts: IChart[] = [];
    charts.push(getInSafe(chartExecutionResponse, e => FuseChartUtils.GetCurrentChartFromChartExecutionResponse(e), {}));
    return charts;
  }

  public reload(): void {
    this.initialized = false;
    this.internalLoad(true);
  }

  public downloadImage(): void {
    this.fuseChartsService
      .exportAsImage(this.currentPluginRequest, this.chartInfo.exportingAsImageChartDeferred)
      .takeUntil(this.componentDestroyed$)
      .subscribe((result: IChartExportingResult) => {
        this.OpenExportResult(result);
      });
  }

  public downloadExcel(): void {
    this.fuseChartsService
      .exportAsExcel(this.currentPluginRequest, this.chartInfo.exportingAsExcelChartDeferred)
      .takeUntil(this.componentDestroyed$)
      .subscribe((result: IChartExportingResult) => {
        this.OpenExportResult(result);
      });
  }

  /**
   * Export chart as html table
   */
  public exportHtmlTable(): void {
    this.showHtmlTable = !this.showHtmlTable;
    this.fuseChartsService
      .exportAsHtmlTable(this.currentPluginRequest)
      .takeUntil(this.componentDestroyed$)
      .subscribe((result: IChartExportingResult) => {
        this.htmlTable = (result as ChartExportingResultHtmlTable).Html;
        this.detectChanges();
      });
  }

  public OpenExportResult(result: IChartExportingResult): void {
    if (!isNullOrUndefined(result)) {
      if (backendTypeMatch(ChartExportingResultBatchTask.$type, result)) {
        this.OpenModal((result as ChartExportingResultBatchTask).BatchTaskInfo);
      } else if (backendTypeMatch(ChartExportingResultFileRef.$type, result)) {
        window.open(this.fileService.generateFileUrl((result as ChartExportingResultFileRef).FileRef), '_blank');
      }
    }
  }


  public OpenModal(task: BatchTaskInfo): void {
    const ref: ModalReference<unknown> = this.dmbs.showComponent(
      ModalBatchItemComponent,
      {CssClasses: ['batch-item-modal'], ModalType: DtoFrontendModalType.Modal},
      {
        params: {
          Job: task,
        }
      }
    );
    ref.close
      .takeUntil(this.componentDestroyed$)
      .subscribe(() => {
        return Promise.resolve();
      });
  }

  public checkExistsActions(position: string): boolean {
    if (!this.actions) {
      return false;
    }
    return this.getActions(position).length > 0;
  }

  public getActions(position: string): IChartAction[] {
    const actions: IChartAction[] = asIterableObject(this.actions);
    return actions.filter(x => x.Position === position)
  }

  public executeAction(item: IChartAction): void {
    if (item.OnClickCommands && Object.keys(item.OnClickCommands)) {
      this.commandService.executeCommandChain(asIterableObject(item.OnClickCommands));
    }
  }

  public detectChanges(): void {
    this.fuseChartsService.detectChanges();
    this.cdRef.detectChanges();
  }

  public toggle(): void {
    this.widget.toggle();
    this.detectChanges();
  }

  public manageFiltersForm(): void {
    this.showFilters = !this.showFilters;
    this.detectChanges();
  }

  public isIntersecting(status: boolean): void {
    console.debug('Chart ' + this.chartId + ' is intersecting = ' + status);
    if (this.isIntersected || status !== true) {
      return;
    }
    this.isIntersected = true;
    if (!this.initialized) {
      this.initCurrentPluginRequestAndLoad();
    }
  }
}
