import { Component, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

import { FrontendFormElementAware } from '../formelement.class';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { merge } from 'rxjs';
import { FormState, FormValidationError } from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { isNullOrUndefined, UtilsTypescript } from '../../../utils/typescript.utils';

/**
 * This component shows a list validation errors about a form component.
 *
 * @see InputWrapperComponent
 */
@Component({
  selector: 'app-formelementinput-validationmessages',
  templateUrl: './formelementinputvalidationmessages.component.html'
})
export class FormelementinputvalidationmessagesComponent extends FrontendFormElementAware implements OnInit {

  protected component: AbstractControl;

  /**
   * Lifecycle hook that is called after a component's view has been fully
   * initialized.
   */
  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit(): void {
    super.ngOnInit();

    this.component = this.formManagerService.getFormComponent(this.config.ClientPath);

    merge(
      this.component.statusChanges,
      this.component.valueChanges,
      this.component.statusChanges,
      this.formManagerService.submitAttemptWithClientValidationErrors,
      this.formManagerService.formStateChanged)
      .pipe(
        debounceTime(50),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(((): void => {
        this.forceDetectChanges();
      }).bind(this));
  }

  /**
   * Obtenemos los mensajes a mostrar específicos de este campo.
   *
   * Estos serían, los mensajes de validación de frontend + los de backend si los hay.
   *
   * En caso que exista un mismo tipo de error en frontend y en backend, prevalece el error de backend,
   * que permancerá fijo hasta que se vuelva a enviar el formulario.
   *
   * @returns {string[]}
   */
  getMessages(): string[] {

    const messages: string[] = [];

    // Recogemos los errores de frontend
    const errors: ValidationErrors = this.component.errors || {};

    // A los errores de frontend, le sumamos los de backend
    const formState: FormState = this.formManagerService.getFormState();
    if (formState && formState.ValidationErrors) {
      const backendErrors: FormValidationError[] = formState.ValidationErrors.filter((i) => i.Element === this.config.ClientPath);
      // En los errores de backend no siempre vienen claves, intentamos quitar duplicados cuando sea posible
      // basándonos en las claves del error de validación.
      let x: number = 0;
      for (const beError of backendErrors) {
        const errorKey: string = !UtilsTypescript.isNullOrWhitespace(beError.ValidationKey) ? beError.ValidationKey : 'be_' + x;
        errors[errorKey] = beError.Message;
        x++;
      }
    }

    if (isNullOrUndefined(errors)) {
      return [];
    }

    /**
     * $pristine/$dirty tells you whether the user actually changed anything, while
     * $touched/$untouched tells you whether the user has merely been there/visited.
     *
     * This is really useful for validation. The reason for $dirty was always
     * to avoid showing validation responses until the user has actually
     * visited a certain control. But, by using only the $dirty property, the
     * user wouldn't get validation feedback unless they actually altered
     * the value. So, an $invalid field still wouldn't show the user a prompt
     * if the user didn't change/interact with the value.
     * If the user entirely ignored a required field, everything looked OK.
     *
     * @see https://angular.io/api/forms/AbstractControl#pristine
     * @see https://stackoverflow.com/questions/25025102/angularjs-difference-between-pristine-dirty-and-touched-untouched
     */

      // En algunos controles el touched no termina de funcionar bien...
      // así que en esos casos si NO estamos pristine, es como si hubieramos
      // hecho el touch.
    const validationExecuted: boolean = (this.formManagerService.submitAttemptWithClientValidationErrors &&
          this.formManagerService.submitAttemptWithClientValidationErrors.value &&
          this.formManagerService.submitAttemptWithClientValidationErrors.value.length > 0)
        || (this.formManagerService.getFormState() &&
          this.formManagerService.getFormState().ValidationErrors &&
          this.formManagerService.getFormState().ValidationErrors.length > 0);

    if (!validationExecuted) {
      if (this.component.touched === false && this.component.pristine) {
        return [];
      }
    }

    for (const error of Object.keys(errors)) {
      messages.push(errors[error]);
    }

    return messages;
  }
}
