import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs/Rx';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';

import {
  ViewConfiguration,
  ViewQuickSearchFieldSimple,
  ViewsPagerUserConfigurationSimple,
  ViewUserConfiguration
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { ListComponent2Service } from '../../../list.service';
import { ViewsuserconfigchangedEventdata } from '../../../viewsuserconfigchanged.eventdata';

@Component({
    selector: 'app-view-searcher',
    templateUrl: './view-searcher.component.html',
    styleUrls: ['./view-searcher.component.scss']
})
export class ViewSearcherComponent implements OnInit, OnDestroy {

    fields: ViewQuickSearchFieldSimple[];

    configuration: ViewConfiguration;

    findLabel: string = 'Buscar';

    searchString: string;

    showFilters = false;

    userConfiguration: ViewUserConfiguration;

    protected destroySubject$ = new Subject<void>();

    /**
     * Creates a new instance of ListSearcherComponent
     * @param {ListComponent2Service} listComponentConfiguration
     * @param {ChangeDetectorRef} changeDetector
     */
    constructor(
        protected listComponentConfiguration: ListComponent2Service,
        protected changeDetector: ChangeDetectorRef) {
    }

    /**
     * On component initialization gets configuration and prepare columns.
     */
    ngOnInit(): void {
        // this.findLabel = SEACH_BASE_TEXT;
        this.userConfiguration = this.listComponentConfiguration.getUserConfiguration();
        this.configuration = this.listComponentConfiguration.getConfiguration();
        if (this.userConfiguration.Search?.SearchString !== undefined) {
          this.searchString = this.userConfiguration.Search.SearchString;
        }

        this.setFields();

        this.listComponentConfiguration
            .userConfigurationChanged
            .takeUntil(this.destroySubject$).subscribe(
            (next: ViewsuserconfigchangedEventdata) => {
                this.userConfiguration = next.userConfiguration;
                this.setFields();
            }
        )
    }

    /**
     * On Component Destruction remove observables
     */
    ngOnDestroy(): void {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }

    /**
     * Event when user click outside the drop-down
     * to close it. Uses clickOutside directive
     * @param event
     */
    clickOutsideHandler(event: any): void {
        this.showFilters = false;
        this.changeDetector.detectChanges();
    }

    /**
     * En every check or uncheck of a field on the drop-down, search fields array
     * changes
     * @param event
     */
    inputChanged(event: any): void {
        if (event.target.checked) {
            this.userConfiguration.Search.Fields.push(event.target.id);
        } else {
            this.userConfiguration.Search.Fields.splice(this.userConfiguration.Search.Fields.indexOf(event.target.id), 1)
        }
    }

    /**
     * Method to check if field is or not selected
     * @param field
     * @returns {boolean}
     */
    isChecked(field: any): boolean {
        return this.userConfiguration.Search.Fields.indexOf(field) !== -1;
    }

    /**
     * On input submit, the user configuration is applied with the search string
     * and the given Array of fields to search in.
     */
    searchChange(event: UIEvent): void {
        /**
         * If the event is a `KeyboardEvent` and doesn't corresponds to a
         * enter key then return.
         */
        if (this.keyboardEventIsNotEnterKey(event)) {
            return;
        }

        // Prevent default behaviour on enter-like keys and button submit.
        this.preventEventPropagation(event);

        this.exectuteSearch();
    }

    exectuteSearch(): void {
        if (this.userConfiguration && !isNullOrUndefined(this.userConfiguration.Search)) {
            this.userConfiguration.Search.SearchString = this.searchString;
        }

        if (this.userConfiguration && !isNullOrUndefined(this.userConfiguration.Pagination)) {
            (this.userConfiguration.Pagination as ViewsPagerUserConfigurationSimple).CurrentPage = 0;
        }

        this.listComponentConfiguration.setUserConfiguration(this.userConfiguration);
    }

    /**
     * This method is called on `search input keypress`. And prevents default
     * behaviour on input elements.
     *
     * This was added as views can be a component for forms, and keypress can
     * propagate auto-submit events.
     *
     * @param {Event}event
     */
    preventEventPropagation(event: Event): void {
        if (this.keyboardEventIsNotEnterKey(event)) {
            return;
        }
        event.preventDefault();
    }

    /**
     * Set Fields by which you can search
     */
    setFields(): void {
        if (this.userConfiguration) {
            this.fields = !isNullOrUndefined(this.configuration.QuickSearch) ? this.configuration.QuickSearch.Fields : [];
        }
    }

    /**
     * Toggle dropdown visibility
     */
    toggleDropdown(event: Event): void {
        this.showFilters = !this.showFilters;
        this.changeDetector.detectChanges();
        this.preventEventPropagation(event);
    }

    /**
     * Retuns a boolean indicating true when an even is instance of `KeyboardEvent`
     * and it's code represents an Enter key.
     *
     * @param {Event} event
     */
    protected keyboardEventIsNotEnterKey(event: Event): boolean {
        return event instanceof KeyboardEvent && !((event as KeyboardEvent).code.includes('Enter') || (event as KeyboardEvent).key.includes('Enter'));
    }
}

