import { FileWrapper } from '../file-uploader/models/file-wrapper';
import { FieldConfig } from '../form/interfaces/field-config.interface';
import { ValidationErrors } from '@angular/forms';

export class FileUtils {

  /**
   * Max file size allowed
   */
  static FileSizeLimit: number = 52428800;
  FieldConfig

  /**
   * Check if the size is valid
   *
   * @param {FileWrapper} file
   * @returns {boolean}
   */
  public static IsValidSizeFieldConfig(file: FileWrapper, config: FieldConfig): boolean {
    return this.IsValidSize(file, config.validSize);
  }

  /**
   * Check if the size is valid
   *
   * @param {FileWrapper} file
   * @returns {boolean}
   */
  public static IsValidSize(file: FileWrapper, validSize: number, fileSizeLimit: number = FileUtils.FileSizeLimit): boolean {
    return (
      (validSize === 0 || (validSize > 0 && file.size < validSize)) && file.size < fileSizeLimit);
  }

  /**
   * Check if this is a valid extension
   *
   * @param {FileWrapper} file
   * @returns {boolean}
   */
  public static IsValidExtensionFieldConfig(file: FileWrapper, config: FieldConfig): boolean {
    return this.IsValidExtension(file, config.validExtensions);
  }

  /**
   * Check if this is a valid extension
   *
   * @param {FileWrapper} file
   * @returns {boolean}
   */
  public static IsValidExtension(file: FileWrapper, validExtensions: string[]): boolean {

    validExtensions = validExtensions || [];

    // If an array of valid extensions wasn't specified.
    if (validExtensions.length === 0) {
      return true;
    }

    return validExtensions.map(extension => extension.toLowerCase()).some((i) => i === file.name.toLowerCase().split('.').pop())
  }

  public static ValidateFileFormComponent(files: FileWrapper[], config: FieldConfig): ValidationErrors {
    const errors: ValidationErrors = {};

    if (files.length && !(files.filter(f => FileUtils.IsValidExtensionFieldConfig(f, config)).length === files.length)) {
      errors['invalid-extension'] = 'Extensión de fichero inválida.';
    }

    if (files.length && !(files.filter(f => FileUtils.IsValidSizeFieldConfig(f, config)).length === files.length)) {
      errors['invalid-file-legnth'] = 'Tamaño de fichero inválido.';
    }

    if (files.length && files.filter(f => f.UploadedFile === null).length > 0) {
      errors['invalid-file-pending'] = 'Ficheros pendientes de carga.';
    }

    return errors;
  }
}

