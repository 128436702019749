<div style="display: flex; flex-direction: column">
  <label>
    {{'Desde este buscador podrá encontrar accesos directos a las principales funcionalidades de Sabentis.' | t}}
    <br>
    {{'Tenga en cuenta que no todas las funcionalidades están cubiertas por este buscado debido al diseño de las mismas.' | t}}</label>
  <br>
  <mat-form-field appearance="legacy" fxFlex="100"
                  fxFill>
    <mat-label>{{'Buscar funcionalidad' | t}}</mat-label>
    <mat-icon matSuffix class="secondary-text" fxHide>search</mat-icon>
    <div fxLayout="row">
      <input id="search" matInput type="search"
             class="t-quicksearch t-quicksearch-input"
             [(ngModel)]="searchString"
             (keyup)="onKeyPressed($event)" #searchInput>
      <mat-icon matPrefix class="search">search</mat-icon>
    </div>
  </mat-form-field>

  <div class="navigation-items-container">
    <ul>
      <ng-container *ngFor="let item of navigation">
        <li *ngIf="item?.url else linourl"
            (mouseover)="onItemOver($event, item)" class="nav-link"
            [ngClass]="selectedItem?.id === item.id ? 'active' : ''">
          <a [routerLink]="item?.url" (click)="closeModal()">{{item.title}}</a>
        </li>
        <ng-template #linourl>
          <li class="nav-section">
            <span>{{item.title}}</span>
        </ng-template>
      </ng-container>
    </ul>
  </div>
</div>
