import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ListComponent2Service } from '../../list.service';
import { DestroyableObjectTrait } from '../../../utils/destroyableobject.trait';
import { debounceTime, delay, filter, takeUntil } from 'rxjs/operators';
import { ChangedetectorReference } from '../../../../core/changedetector/changedetectoreference';
import { ChangedetectorService } from '../../../../core/changedetector/changedetector.service';
import { cleanNewtonsoftTypeProperty, isNullOrUndefined } from '../../../utils/typescript.utils';
import { ViewsuserconfigchangedAction, ViewsuserconfigchangedEventdata } from '../../viewsuserconfigchanged.eventdata';
import { LoadingLengthNumber, NoLengthLengthNumber } from '../material-pager/mat-paginator-intl';
import {
  ViewConfiguration,
  ViewExecutableCountResult,
  ViewResult,
  ViewsPagerCompleteLazy,
  ViewsPagerLoadCountModeEnum,
  ViewsPagerUserConfigurationSimple,
  ViewUserConfiguration
} from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-lacy-pager',
  templateUrl: './lazy-pager.component.html',
  styleUrls: ['./lazy-pager.component.scss'],
  providers: [
    ChangedetectorReference
  ]
})
export class LazyPagerComponent extends DestroyableObjectTrait implements OnInit, OnChanges {

  currentPage = 0;

  pageSize = 25;

  maxSize = 200;

  pageSizeChanged: EventEmitter<void> = new EventEmitter();

  /**
   * The total result count
   */
  totalResultCount: ViewExecutableCountResult = null;

  /**
   * Length of the complete data set
   */
  length: number = NoLengthLengthNumber;

  /**
   * If the component was touched (solo cargamos count si tocamos el paginador)
   */
  touched: boolean = false;

  /**
   * Load mode of count
   */
  loadCountMode: ViewsPagerLoadCountModeEnum;

  /**
   * Is LoadCountButton visible
   */
  isLoadCountButtonVisible: boolean = false;

  /**
   * Modo de visualización del paginador, full o simple.
   */
  fullMode: boolean = false;

  queryCountResult: ViewExecutableCountResult = null;

  @Input() viewResult: ViewResult;

  /**
   * Usamos este evento para cancelar cualquier llamada "count"
   */
  countRequestTakeUntil: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Soporte para mostrar el paginador segun indica la propiedad de configuración
   * HidePagerIfNotNeeded.
   */
  hidePager: boolean = false;

  LoadingLengthNumber = LoadingLengthNumber;

  NoLengthLengthNumber = NoLengthLengthNumber;

  /**
   * Constructor
   *
   * @param listComponentService
   */
  constructor(public listComponentService: ListComponent2Service,
              protected cdRef: ChangedetectorReference,
              protected cdService: ChangedetectorService) {
    super();
  }

  /**
   * Initialize the component
   */
  ngOnInit(): void {
    const configuration: ViewConfiguration = this.listComponentService.getConfiguration();
    const userConfiguration: ViewUserConfiguration = this.listComponentService.getUserConfiguration();
    this.pageSize = (userConfiguration.Pagination as ViewsPagerUserConfigurationSimple).PageSize;
    this.currentPage = (userConfiguration.Pagination as ViewsPagerUserConfigurationSimple).CurrentPage;
    this.maxSize = (userConfiguration.Pagination as ViewsPagerUserConfigurationSimple).MaxPageSize;
    this.loadCountMode = (configuration.Pager as ViewsPagerCompleteLazy).LoadCountMode;
    this.listComponentService.userConfigurationChanged
      .takeUntil(this.componentDestroyed$)
      .subscribe(() => {
        this.configurationChanged();
      });

    this.hidePager = this.listComponentService.data.LastPage && this.currentPage === 0 && (configuration.Pager as ViewsPagerCompleteLazy).HidePagerIfNotNeeded;

    // Cada vez que obtengo resultados de backend, si hay paginación intento cogerla.
    this.listComponentService
      .viewDataLoadedWithReplay
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe(() => {
        this.updatePagerFromViewResult();
      });

    // Si cambia la configuración de usuario, tengo que resetear el count interno.
    this.listComponentService
      .userConfigurationChanged
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter((i) => !i.tags.includes(ListComponent2Service.userConfigurationChangeNotAffectingCountTag))
      )
      .subscribe((event: ViewsuserconfigchangedEventdata) => {
        // Stop any currently active count request...
        this.countRequestTakeUntil.emit();
        this.totalResultCount = null;
        this.length = NoLengthLengthNumber;
        this.touched = false;
      });

    this.pageSizeChanged
      .pipe(
        takeUntil(this.componentDestroyed$),
        debounceTime(800)
      )
      .subscribe(() => this.searchForResults());

    this.refresh();
  }

  /**
   * Actualiza el paginador a partir de los datos de un view result,
   * si éste tiene información sobre la paginación.
   */
  protected updatePagerFromViewResult(): void {
    const data: ViewResult = this.listComponentService.data;
    if (data && data.ResultCount) {
      this.totalResultCount = data.ResultCount;
      this.countRequestTakeUntil.emit();
      this.refresh();
    }
  }

  /**
   *
   * @param changes
   */
  refresh(): void {
    const configuration: ViewConfiguration = this.listComponentService.getConfiguration();
    const userConfiguration: ViewUserConfiguration = this.listComponentService.getUserConfiguration();

    this.pageSize = (userConfiguration.Pagination as ViewsPagerUserConfigurationSimple).PageSize;
    this.currentPage = (userConfiguration.Pagination as ViewsPagerUserConfigurationSimple).CurrentPage;

    if (this.totalResultCount && !isNullOrUndefined(this.totalResultCount.Count)) {
      this.length = this.totalResultCount.Count;
    } else if (this.loadCountMode === ViewsPagerLoadCountModeEnum.Always || this.touched === true) {
      this.loadCount();
    }

    if (this.loadCountMode === ViewsPagerLoadCountModeEnum.OnDemand) {
      this.isLoadCountButtonVisible = true;
    }

    this.cdRef.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.configurationChanged();
  }

  configurationChanged(): void {
    // If the view has changed, refresh the pager.
    if (this.fullMode === true) {
      this.toogleFullMode(false);
      this.queryCountResult = null;
    }
    // Si estoy en la última página, siempre tengo el count "gratis"
    if (this.fullMode === false && this.listComponentService.data.LastPage === true) {
      this.toogleFullMode(true);
      this.queryCountResult = this.listComponentService.data.ResultCount;
    }
    const userConfiguration: ViewUserConfiguration = this.listComponentService.getUserConfiguration();
    this.currentPage = (userConfiguration.Pagination as ViewsPagerUserConfigurationSimple).CurrentPage;
    this.cdRef.changeDetector.detectChanges();
  }

  /**
   * set User configuration to get data
   */
  changeCurrentPage(): void {
    const currentPage: number = this.currentPage;
    const pageSize: number = Math.floor(this.pageSize);

    let configUpdated: boolean = false;
    const userConfiguration: ViewUserConfiguration = this.listComponentService.getUserConfiguration();
    const currentConfiguration: ViewsPagerUserConfigurationSimple = userConfiguration.Pagination as ViewsPagerUserConfigurationSimple;

    if (currentConfiguration.CurrentPage !== currentPage) {
      currentConfiguration.CurrentPage = currentPage;
      configUpdated = true;
    }

    if (currentConfiguration.PageSize !== pageSize) {
      currentConfiguration.PageSize = pageSize;
      configUpdated = true;
    }

    if (configUpdated) {
      // Al cambiar de página, obtenemos el count para ayudar en la UX
      if (this.touched === false) {
        this.touched = true;
        this.refresh();
      }
      this.listComponentService.setUserConfiguration(userConfiguration, true, ViewsuserconfigchangedAction.Refresh, [ListComponent2Service.userConfigurationChangeNotAffectingCountTag]);
    }
  }

  /**
   * When number of available pages change go to first and get data
   */
  updatePageSize(): void {
    let newPageSize: number = Number(this.pageSize);
    if (this.maxSize < newPageSize) {
      newPageSize = this.maxSize;
    }
    this.pageSize = newPageSize;
    this.goToFirst();
  }

  /**
   * Checks if is first page
   * @returns {boolean}
   */
  isFirstPage(): boolean {
    return this.currentPage === 0;
  }

  toogleFullMode(fullMode: boolean): void {
    if (this.fullMode === fullMode) {
      return;
    }

    if (fullMode === true) {
      if (!isNullOrUndefined(this.queryCountResult)) {
        this.fullMode = true;
        this.cdRef.changeDetector.detectChanges();
      } else {
        this.listComponentService.getTotalItemCount()
          .pipe(
            takeUntil(this.componentDestroyed$)
          )
          .subscribe((count: ViewExecutableCountResult) => {
            this.queryCountResult = count;
            this.fullMode = true;
            this.cdRef.changeDetector.detectChanges();
          });
      }
    } else {
      this.fullMode = false;
      this.cdRef.changeDetector.detectChanges();
    }
  }

  /**
   * checks if is last page
   * @returns {boolean}
   */
  isLastPage(): boolean {
    return this.viewResult.LastPage === true;
  }

  /**
   * Goes to first page
   */
  goToFirst(): void {
    this.currentPage = 0;
    this.changeCurrentPage();
  }

  /**
   * Goes to last page
   */
  goToLast(): void {
    this.currentPage = Math.ceil(this.queryCountResult.Count / this.pageSize);
    this.changeCurrentPage();
  }

  /**
   * Go to next page if is not last
   */
  goToNext(): void {
    if (!this.isLastPage() && this.listComponentService.requestInProgress$.getValue() === false) {
      this.currentPage++;
      this.changeCurrentPage();
    }
  }

  /**
   * Go to previous page if is not first
   */
  goToPrevious(): void {
    if (this.currentPage > 0 && this.listComponentService.requestInProgress$.getValue() === false) {
      this.currentPage--;
      this.changeCurrentPage();
    }
  }

  keydownHandler(e: KeyboardEvent): void {
    e.preventDefault();
  }

  clearSearch(): void {
    this.pageSize = this.pageSize;
  }

  searchForResults(): void {
    this.updatePageSize()
  }

  mouseOverHandler(): void {
    this.cdRef.changeDetector.detectChanges();
  }

  loadCount(): void {
    this.length = LoadingLengthNumber;
    this.listComponentService.getTotalItemCount()
      .pipe(
        delay(1500),
        takeUntil(this.componentDestroyed$),
        takeUntil(this.countRequestTakeUntil),
      )
      .subscribe((count: ViewExecutableCountResult) => {
        this.totalResultCount = count;
        this.refresh();
      });
  }

  get rangeStart(): number {
    return this.viewResult.Results.length === 0 ? 0 : this.currentPage * this.pageSize + 1;
  }

  get rangeEnd(): number {
    let totalResultCount: number = this.viewResult.Results.length;
    const groups: object = cleanNewtonsoftTypeProperty(this.viewResult.Groups);
    if (groups && Object.keys(groups).length > 0) {
      totalResultCount = Object.keys(groups).length;
    }
    return this.currentPage * this.pageSize + totalResultCount;
  }
}
