<div class="acomplete__ac-value c-form-field__input t-input" [class.--disabled]="isDisabled" *ngIf="acValue">
  <div class="o-input__field--unset">
    {{ acValue.Name }}
    <span class="ac-unset-value" (click)="unsetOption()" style="cursor: pointer">
      <i class="glyphicon glyphicon-remove"></i>
    </span>
  </div>
</div>

<div *ngIf="!acValue">
  <div class="o-input" style="width: 100%;" #inputGroup>
    <input [id]="uniqueComponentId('input-')"
           type="text"
           class="o-input__field--search t-input"
           [placeholder]="config.placeholder || 'Buscar...'"
           (keyup.enter)="searchForResults()"
           (keyup.escape)="clearSearch()"
           (keydown.enter)="keydownHandler($event)"
           (focusout)="inputTouched()"
           [disabled]="isDisabled"
           autocomplete="off"
           [(ngModel)]="searchValue"
           [ngModelOptions]="{standalone: true}">
    <button class="c-form-field__button"
            type="button"
            (click)="searchForResults()"
            [disabled]="isDisabled">
      <i class="m-fi  m-fi--search af af-magnifier"></i>
    </button>
  </div>
  <div 
    *ngIf="optionList?.length > 0"
    [ngStyle]="listGroupStyles" 
    style="position: absolute; z-index: 9999;" 
    class="c-list-group"
    (appFormScroll)="scrolledToEndHandler()"
    #listgroup>
    <button *ngFor="let o of optionList; let i = index;" type="button" class="c-list-group__item"
            (click)="setOption(i)"> {{ o.Name }}</button>
  </div>
  <ul [ngStyle]="{ width: optionsWidth+'px' }" style="position: absolute; z-index: 9999;" class="c-list-group"
      *ngIf="optionList?.length === 0 && searched">
    <li class="list-group-item">{{ 'No se han encontrado resultados' | t }}</li>
  </ul>
</div>
