/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';
import { QUALA_QUESTIONNAIRE } from '../models/ETG_SABENTISpro_Models_models';
import { QUALA_PROBLEMCATEGORY } from '../models/ETG_SABENTISpro_Models_models';
import { QualaDefinition } from '../models/ETG_SABENTISpro_Application_Modules_models';
import { QUALA_PROBLEMTYPE } from '../models/ETG_SABENTISpro_Models_models';

@Injectable({ providedIn: 'root' })
export class HiraService
{
  constructor(public generalService: CommunicationService) {}

  /**
  *
  * Add questionnaires to study
  *
  * @param {string} studyId
  * @param {string[]} lstIdQuestionnaires
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {any}
  */
  getAddquestionnaires(studyId: string, lstIdQuestionnaires: string[], clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<any>>
  {
    const params: any = {'studyId' : studyId, 'lstIdQuestionnaires' : JSON.stringify(lstIdQuestionnaires)};
    return this.generalService.get(`quala-hira/addquestionnaires`, params, clientOpts) as Observable<WebServiceResponseTyped<any>>;
  }

  /**
  *
  * Add questionnaires to study
  *
  * @param {string} studyId
  * @param {string} problemId
  * @param {string} questionnaireId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {any}
  */
  getChangestatus(studyId: string, problemId: string, questionnaireId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<any>>
  {
    const params: any = {'studyId' : studyId, 'problemId' : problemId, 'questionnaireId' : questionnaireId};
    return this.generalService.get(`quala-hira/changestatus`, params, clientOpts) as Observable<WebServiceResponseTyped<any>>;
  }

  /**
  *
  * Gets active questionnaires from study and category
  *
  * @param {string} studyId
  * @param {string} categoryId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {QUALA_QUESTIONNAIRE[]} Person item or null
  */
  getStudyactivequestionnairescategories(studyId: string, categoryId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<QUALA_QUESTIONNAIRE[]>>
  {
    const params: any = {'studyId' : studyId, 'categoryId' : categoryId};
    return this.generalService.get(`quala-hira/study-active-questionnaires-categories`, params, clientOpts) as Observable<WebServiceResponseTyped<QUALA_QUESTIONNAIRE[]>>;
  }

  /**
  *
  * Gets first company of database
  *
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {QUALA_PROBLEMCATEGORY[]} Person item or null
  */
  getStudycategories(clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<QUALA_PROBLEMCATEGORY[]>>
  {
    return this.generalService.get(`quala-hira/study-categories`, null, clientOpts) as Observable<WebServiceResponseTyped<QUALA_PROBLEMCATEGORY[]>>;
  }

  /**
  *
  * Gets first company of database
  *
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {QualaDefinition} Person item or null
  */
  getStudydefinitioncompanycontext(clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<QualaDefinition>>
  {
    return this.generalService.get(`quala-hira/study-definition-company-context`, null, clientOpts) as Observable<WebServiceResponseTyped<QualaDefinition>>;
  }

  /**
  *
  * Gets first company of database
  *
  * @param {string} studyId
  * @param {string} categoryId
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {QUALA_PROBLEMTYPE[]} Person item or null
  */
  getStudyfamiliescategories(studyId: string, categoryId: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<QUALA_PROBLEMTYPE[]>>
  {
    const params: any = {'studyId' : studyId, 'categoryId' : categoryId};
    return this.generalService.get(`quala-hira/study-families-categories`, params, clientOpts) as Observable<WebServiceResponseTyped<QUALA_PROBLEMTYPE[]>>;
  }
}
