import 'hammerjs';
import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { blacklistZone } from './blacklist';
import { hmrBootstrap } from './hmr';

blacklistZone.run(() => {
  if (environment.production) {
    enableProdMode();
  }

  const bootstrap: () => Promise<NgModuleRef<any>> = () => platformBrowserDynamic().bootstrapModule(AppModule);
  if (environment.hmr) {
    if (module['hot']) {
      hmrBootstrap(module, bootstrap);
    } else {
      console.error('HMR is not enabled for webpack-dev-server!');
      console.log('Are you using the --hmr flag for ng serve?');
    }
  } else {
    bootstrap().catch(err => console.log(err));
  }
});


if (environment.production) {
  enableProdMode()
}
