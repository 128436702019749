import { Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot
} from '@angular/router';
import { catchError, flatMap, map, take, takeUntil } from 'rxjs/operators';
import { Observable, of, throwError } from '../../../node_modules/rxjs';
import { NavigationRequest } from '../core/navigation/models/NavigationRequest.class';
import { ReplacedControllerInfo } from '../core/navigation/models/redirect-info.interface';
import { NavigationService } from '../core/navigation/navigation.service';
import { DecoupledModalBridgeService } from '../shared/decoupled-modal/decoupled-modal-bridge.service';
import { DestroyableObjectTrait } from '../shared/utils/destroyableobject.trait';
import { isNullOrUndefined, UtilsTypescript } from '../shared/utils/typescript.utils';
import { MenuItemCompiledFrontend } from '../core/navigation/models/MenuItemCompiledFrontend.class';
import { CommandService } from '../core/commands/command.service';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * Este guard tiene varias funciones:
 * ** Gestionar el acceso a las rutas de front cargando el menú de backend (404 y 403)
 * **
 */
@Injectable()
export class CheckAccessblockingmessageGuard extends DestroyableObjectTrait implements CanLoad, CanActivate, CanActivateChild, OnDestroy {

  private currentHomeController: string = 'home';

  /**
   * CheckAccessblockingmessageGuard class constructor.
   */
  constructor(
    private navigationService: NavigationService,
    private dmbs: DecoupledModalBridgeService,
    private commandService: CommandService
  ) {
    super();
    this.currentHomeController = 'home';
  }

  canLoad(route: Route): boolean {
    return true;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.CheckCurrentNode(route);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.CheckCurrentNode(childRoute);
  }

  private CheckCurrentNode(route: ActivatedRouteSnapshot): Observable<boolean> {

    return this.navigationService
      .navigationRequestFromActivatedRoute(route)
      .pipe(
        takeUntil(this.componentDestroyed$),
        take(1),
        flatMap((i) => {
          // Para evitar bloqueos innecesarios, no aplicamos esto si estoy pidiendo la raíz de la aplicación, asumimos
          // que siempre tendrá acceso
          if (UtilsTypescript.isNullOrWhitespace(i.requestController) || i.requestController === this.currentHomeController) {
            return Observable.of(true);
          }
          return this.navigationService
            .resolveTreeFromRoute(route)
            .pipe(
              take(1),
              takeUntil(this.componentDestroyed$),
              flatMap(
                (j: NavigationRequest | ReplacedControllerInfo) => {
                  if (j) {
                    if (j.hasOwnProperty('requestController') || j instanceof NavigationRequest) {
                      return of(this.CheckNode(j));
                    }
                    const redirectInfo: ReplacedControllerInfo = j;
                    return Observable.fromPromise(this.navigationService.navigateUrlByController(redirectInfo.redirectedNavigationRequest.requestController,
                      redirectInfo.redirectedNavigationRequest.requestArguments))
                      .pipe(
                        // Devolvemos un false porque en realidad aquí hay una redirección
                        map(() => false)
                      );
                  }

                  return of(false);
                }
              ),
              catchError((err, obs) => {
                if (err instanceof HttpErrorResponse) {
                  if ((err.status === 403 || err.status === 401)) {
                    // Eso es que hemos intentado navegar a una ruta a la que no tengo acceso o no existe,
                    // de momento modal y pa la home, aunque lo suyo sería enviar a una página epecífica
                    window.alert('No tiene permisos para acceder al recurso o éste no existe.');
                    return Observable.fromPromise(this.navigationService.goToHome())
                      .map(() => false);
                  }
                  if (err.status === 404) {
                    // Eso es que hemos intentado navegar a una ruta a la que no existe,
                    // de momento modal y enviamos a la pagina de error 404
                    return Observable.fromPromise(this.navigationService.goToNotFoundPage())
                      .map(() => false);
                  }
                }
                throwError(err);
              })
            );
        })
      );
  }

  private CheckNode(navigationRequest: NavigationRequest | any): boolean {

    if (!navigationRequest.requestController) {
      return true;
    }

    return this.checkValidNode(navigationRequest);
  }

  private checkValidNode(navigationRequest: NavigationRequest | any): boolean {

    const path: MenuItemCompiledFrontend[] = navigationRequest.responseMenuPath;

    if (isNullOrUndefined(path)) {
      return true;
    }

    const node: MenuItemCompiledFrontend = path.find((x: MenuItemCompiledFrontend) => x.accessBlocked());

    if (node && node.accessBlocked()) {
      this.commandService.executeCommandChain(Object.values(node.AccessBlockedCommands));
      return false;
    }

    return true;
  }
}
