import { AfterViewInit, ChangeDetectorRef, Component, forwardRef, NgZone, SkipSelf, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { FormElementAutocompleteCallbackResult, FormElementOption, FormSubmitData } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { ChangedetectorService } from '../../../../../core/changedetector/changedetector.service';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { TagInputComponent } from 'ngx-chips';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';

@Component({
  selector: 'app-inputchips',
  templateUrl: './inputchips.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputchipsComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => InputchipsComponent), multi: true},
    ChangedetectorService
  ]
})

export class InputchipsComponent extends FrontendFormElementInput implements AfterViewInit {

  @ViewChild('tagInputComponent', {static: true}) tagInputComponent: TagInputComponent

  public itemsValue: FormElementOption[] = [];

  public get items(): FormElementOption[] {
    return this.itemsValue;
  }

  public set items(items: FormElementOption[]) {
    this.itemsValue = items;
  }

  private itemsSubject$ = new Subject<FormElementOption[]>();

  /**
   *
   * @param {FormManagerService} formManagerService
   */
  constructor(protected formManagerService: FormManagerService,
              protected cdRef: ChangeDetectorRef,
              @SkipSelf()
              protected cdRefParent: ChangeDetectorRef,
              protected cdService: ChangedetectorService,
              protected  ngZone: NgZone,
              protected localeService: TranslatorService) {
    super(formManagerService, cdRef, localeService);
  }

  /**
   * Transform the input to FormElementOption
   * @param value
   */
  public transform(value: any): Observable<FormElementOption> {
    let item: FormElementOption = new FormElementOption();
    if (value.hasOwnProperty('Key') && value.hasOwnProperty('Name')) {
      item = value as FormElementOption;
    } else {
      item.Key = null;
      item.Name = value.toString();
    }
    return of(item);
  }

  /**
   * Searches the avaible tags
   * @param text
   */
  public requestAutocompleteItems = (search: string): Observable<FormElementOption[]> => {
    this.getItems(search);
    return this.itemsSubject$.asObservable();
  };

  public getItems(search: string): void {
    const formSubmitData: FormSubmitData = new FormSubmitData();
    formSubmitData.formInput = this.formManagerService.form.value;
    formSubmitData.submitElement = this.config.name;
    this.formManagerService.getFieldautocompletecallback(
      formSubmitData,
      this.config.name,
      search)
      .takeUntil(this.itemsSubject$)
      .subscribe(this.getSuccessHandler.bind(this));
  }

  getSuccessHandler(result: FormElementAutocompleteCallbackResult): void {
    if (!isNullOrUndefined(result) && !isNullOrUndefined(result.Options)) {
      this.itemsSubject$.next(result.Options);
    }
    this.cdRef.detectChanges();
  }

  public writeValue(value: FormElementOption[]): void {
    this.items = value;
  }

  public onAdd(): void {
    this.tagInputComponent.dropdown.hide();
    this.propagateChange(this.items);
  }

  public onRemove(): void {
    this.propagateChange(this.items);
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    // Por algún motivo que desonocemos, las animaciones internas
    // que pintan los elementos seleccionados no se están lanzando correctamente
    // y requieren un repintado asíncrono desde la zona de angular
    this.cdService.runInsideAngularAsync(() => {
      this.cdRefParent.detectChanges();
    }, this);
  }
}
