<!--Selección cuando el componente SÍ es de selección múltiple-->
<div class="c-tag-close" *ngIf="this.elementAutocomplete.IsMultiselect">
  <div class="c-tag-close__item" *ngFor="let selectedOption of renderedSelection; let i = index" data-index="4">
    {{ selectedOption.Name }}
    <!-- Editar -->
    <ng-container *ngIf="this.showEditItem">
      <div [id]="uniqueComponentId('edit-' + i +'-')" class="form-autocompletenew-edit"
           (click)="editItem(selectedOption)">
        <span class="form-autocompletenew-create--text">{{ this.elementAutocomplete.EditItemTitle }}</span>
      </div>
    </ng-container>
    <i [id]="uniqueComponentId('unset-' + i +'-')"  (click)="unsetOption(selectedOption.HashedKey)" class="c-tag-close__btn t-remove"></i>
  </div>
</div>

<!--Selección cuando el componente NO es de selección múltiple-->
<div
    class="acomplete__ac-value c-form-field__input t-input"
    [class.--disabled]="isDisabled"
    *ngIf="renderedSelectionSingleItem && !this.elementAutocomplete.IsMultiselect"
>
  <div class="o-input__field--unset">
    {{ renderedSelectionSingleItem.Name }}
    <!-- Editar -->
    <div class="ac-actions">
      <ng-container *ngIf="this.showEditItem">
        <div class="form-autocompletenew-edit" (click)="editItem(renderedSelectionSingleItem)">
          <span [id]="uniqueComponentId('edit-')"
                class="form-autocompletenew-create--text">{{ this.elementAutocomplete.EditItemTitle }}</span>
        </div>
      </ng-container>
      <span class="ac-unset-value" (click)="unsetOption(renderedSelectionSingleItem.HashedKey)" style="cursor: pointer">
          <i [id]="uniqueComponentId('unset-')" class="glyphicon glyphicon-remove t-remove"></i>
        </span>
    </div>
  </div>
</div>

<!--Contenedor del buscador-->
<div *ngIf="showSearchBox">
  <div class="o-input o-input-acomplete" #inputGroup>
    <div class="o-input-acomplete__control-container">
      <input
          [id]="uniqueComponentId('input-')"
          type="text"
          class="o-input__field--search"
          [placeholder]="elementAutocomplete.Placeholder || 'Buscar...'"
          (keydown.enter)="keydownHandler($event)"
          (keyup.enter)="searchForResults()"
          (keyup.escape)="clearSearch()"
          (focusout)="inputTouched()"
          [(ngModel)]="searchValue"
          [ngModelOptions]="{ standalone: true }"
          [autocomplete]="'off-' + this.uniqueElementId"
          [disabled]="isDisabled"
      />
      <button class="c-form-field__button" type="button" (click)="searchForResults()" [disabled]="isDisabled">
        <i class="m-fi m-fi--search af af-magnifier"></i>
      </button>
    </div>
    <div class="o-input-acomplete__options-container">
      <div
          [ngStyle]="{ width: optionsWidth + 'px' }"
          style="position: absolute; z-index: 9999"
          class="c-list-group"
          *ngIf="renderedSearchItems?.length > 0 && searched === true"
          (appFormScroll)="scrolledToEndHandler()"
      >
        <div *ngFor="let o of renderedSearchItems; let i = index" class="c-list-group__item" (click)="setOption(o)">
          {{ o.Name }}
        </div>
      </div>
      <ul
          [ngStyle]="{ width: optionsWidth + 'px' }"
          style="position: absolute; z-index: 9999"
          class="c-list-group"
          *ngIf="renderedSearchItems?.length === 0 && searched === true"
      >
        <li class="list-group-item">{{ getNoResultsMessage() }}</li>
      </ul>
    </div>
  </div>
</div>
<!-- O agregue uno nuevo -->
<ng-container *ngIf="this.showCreateNewItem">
  <div class="form-autocompletenew-create" (click)="createNewItem()">
    <i class="fas fa-plus-circle"></i>
    <span class="form-autocompletenew-create--text">{{ this.elementAutocomplete.CreateNewItemTitle }}</span>
  </div>
</ng-container>
