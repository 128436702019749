<div class="gridcell" [ngClass]="{fieldInclude: true}">
  <div *ngIf="!utilsTypescript.isNullOrUndefined(fieldValue)">
    <ng-container *ngIf="this.fieldValue.Type === ViewResultCellType.FileLink && renderAsLinkUri">
      <a [href]="renderAsLinkUriHref" [title]="fieldValue.RawValue">{{fieldValue.RawValue}}</a>
    </ng-container>
    <ng-container *ngIf="this.fieldValue.Type === ViewResultCellType.NavigationNodeLink">
      <a [routerLink]="renderAsLinkUriHref" [queryParams]="renderAsLinkUriQueryParams"
         [title]="fieldValue.RawValue">{{fieldValue.RawValue}}</a>
    </ng-container>
    <ng-container *ngIf="!renderAsLinkUri && !renderAsHtml">{{simpleRenderString}}</ng-container>
    <ng-container *ngIf="this.fieldValue.Type === ViewResultCellType.Html">
      <div [innerHTML]="fieldValue.RawValue"></div>
    </ng-container>
    <ng-container *ngIf="this.truncateContents">
      <div class="core-lists-ver-mas-menos">
        <a *ngIf="this.truncateContentsCollapsed" (click)="this.toogleTruncateCollapse()">{{ 'Ver más' | t }}</a>
        <a *ngIf="!this.truncateContentsCollapsed" (click)="this.toogleTruncateCollapse()">{{ 'Ver menos' | t }}</a>
      </div>
    </ng-container>
  </div>
</div>
