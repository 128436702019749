import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { GoogleanalyticsService } from './googleanalytics.service';
import { EventsService } from '../core/events/events.service';
import { map } from 'rxjs/operators';
import { EventData } from '../core/events/interfaces/event.class';
import { compactElementId } from './googleanalytics.utils';

export const FORM_EVENT_TYPE: string = 'form-event';
export const LIST_EVENT_TYPE: string = 'list-event-type';

@NgModule({
  imports: [
    CommonModule
  ],
})
export class GoogleAnalyticsModule {
  static forRoot(): ModuleWithProviders<GoogleAnalyticsModule> {
    return {
      ngModule: GoogleAnalyticsModule,
      providers: [
        GoogleanalyticsService,
        {provide: APP_INITIALIZER, useFactory: analyticsServiceFactory, deps: [Injector, EventsService], multi: true}
      ]
    };
  }
}

/**
 * Analytics initializer
 */
export function analyticsServiceFactory(injector: Injector, eservice: EventsService): Function {
  return (): void => {
    const gaService: GoogleanalyticsService = injector.get(GoogleanalyticsService);
    gaService.initialize();

    gaService.trackEvents(
      eservice.getEventsFilteredByAction(FORM_EVENT_TYPE)
        .pipe(
          map((i) => {
            const result: EventData = new EventData();
            result.action = compactTrackingAction('f', i.params['formid'] as string, i.params['event'] as string);
            return result;
          })
        ));

    gaService.trackEvents(
      eservice.getEventsFilteredByAction(LIST_EVENT_TYPE)
        .pipe(
          map((i) => {
            const result: EventData = new EventData();
            let event: string = i.params['event'] as string;
            if (result['operation']) {
              event += result['operation'] as string;
            }
            result.action = compactTrackingAction('v', i.params['idlist'] as string, event);
            return result;
          })
        ));
  };
}

export function compactTrackingAction(category: string, elementId: string, action: string): string {
  const maxLength: number = 40 - category.length - action.length - 2;
  elementId = compactElementId(elementId, maxLength);
  return category + ':' + elementId + ':' + action;
}
