import { Directive, Injector, ViewContainerRef } from '@angular/core';
import { List2Component } from '../../../list_v2/list.component';
import { CustomComponentFactoryService } from '../../../custom-component-factory/custom-component-factory.service';

@Directive({
  selector: '[appViewFormGenerator]'
})
export class ViewFormGeneratorDirective {
  /**
   * Component to render.
   */
  view: List2Component;

  constructor(protected container: ViewContainerRef,
              protected customComponentFactory: CustomComponentFactoryService,
              protected injector: Injector) {
    this.view = this.customComponentFactory.createComponent('View', container, this.injector, null).instance as List2Component;
  }
}
