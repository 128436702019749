import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { UtilsTypescript } from '../../../shared/utils/typescript.utils';
import {
  CoreRenderTemplateSimpleMessageMessage,
  CoreRenderTemplateSimpleMessageTemplate,
  CoreRenderTemplateSimpleMessageType
} from '../../models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-core-render-template-simple-message',
  templateUrl: './core-render-template-simple-message.component.html',
  styleUrls: ['./core-render-template-simple-message.component.scss']
})
export class CoreRenderTemplateSimpleMessageComponent implements OnInit {

  @Input() data: CoreRenderTemplateSimpleMessageTemplate;

  /**
   *
   */
  constructor() {
  }

  @HostBinding('class')
  get hostWrapperClasses(): string {
    if (this.data.CssClasses) {
      return UtilsTypescript.getNewtonSoftRealValue(this.data.CssClasses).join(' ');
    }
  }

  ngOnInit(): void {
  }

  get messages(): CoreRenderTemplateSimpleMessageMessage[] {
    if (this.data && this.data.Messages) {
      return UtilsTypescript.getNewtonSoftRealValue(this.data.Messages);
    }
    return [];
  }

  get messagesInfo(): CoreRenderTemplateSimpleMessageMessage[] {
    return this.messages.filter((j) => j.Type === CoreRenderTemplateSimpleMessageType.Info);
  }

  get messagesWarning(): CoreRenderTemplateSimpleMessageMessage[] {
    return this.messages.filter((j) => j.Type === CoreRenderTemplateSimpleMessageType.Warning);
  }

  get messagesError(): CoreRenderTemplateSimpleMessageMessage[] {
    return this.messages.filter((j) => j.Type === CoreRenderTemplateSimpleMessageType.Error);
  }

  get messagesSuccess(): CoreRenderTemplateSimpleMessageMessage[] {
    return this.messages.filter((j) => j.Type === CoreRenderTemplateSimpleMessageType.Success);
  }
}
