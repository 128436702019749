/** AUTOGENERATED FILE - DO NOT MODIFY!!!!! */
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';

import { CommunicationService } from '../communication/communication.service';
import { IClientOptions } from '../communication/interfaces/client-options.intefrace';
import { WebServiceResponseTyped } from '../models/ETG_SABENTISpro_Application_Core_models';
import { AuthenticateResult } from '../models/ETG_SABENTISpro_Application_Modules_models';

@Injectable({ providedIn: 'root' })
export class SsoService
{
  constructor(public generalService: CommunicationService) {}

  /**
  *
  * Truncate table logs
  *
  * @param {string} token
  * @param {IClientOptions} clientOpts is an Object that contains optional value to define parameters on the client side
  * @returns {AuthenticateResult}
  */
  postAuthenticate(token: string, clientOpts: IClientOptions = {showSpinner: true}): Observable<WebServiceResponseTyped<AuthenticateResult>>
  {
    const params: any = {'token' : token};
    return this.generalService.post(`sso/authenticate`, params, clientOpts) as Observable<WebServiceResponseTyped<AuthenticateResult>>;
  }
}
