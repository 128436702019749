import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, Routes } from '@angular/router';
import { GenericFormPageSimpleComponent } from '../../shared/generic-pages/generic-form-page-simple/generic-form-page-simple.component';
import { GenericFormPageSimpleArguments } from '../../shared/generic-pages/generic-form-page-simple/GenericFormPageSimpleArguments';
import { AppConfigurationService } from '../../app.configuration.service';
import { GenericListPageSimpleComponent } from '../../shared/generic-pages/generic-list-page-simple/generic-list-page-simple.component';
import { GenericListPageSimpleArguments } from '../../shared/generic-pages/generic-list-page-simple/GenericListPageSimpleArguments';
import { GenericFormListFormPageSimpleArguments } from '../../shared/generic-pages/generic-form-list-form-page-simple/GenericFormListFormPageSimpleArguments';
import { GenericFormListFormPageSimpleComponent } from '../../shared/generic-pages/generic-form-list-form-page-simple/generic-form-list-form-page-simple.component';
import {
  CoreGenericFormPageSimpleComponent,
  CoreGenericFormViewFormPageSimpleComponent,
  CoreGenericViewPageSimpleComponent,
  CoreMenuFrontendRoute,
  CoreMenuFrontendRouteLocation
} from '../models/ETG_SABENTISpro_Application_Core_models';


/**
 * Servicio de ayuda para generar dinámicamente las rutas del router
 * usando la info pasada en el bootstrap generada por el módulo de backend
 * core/PageNavigation
 */
@Injectable()
export class DynamicRoutesGenerator {

  /**
   *
   * Constructor
   *
   * @param appConfigurationService
   */
  constructor(private appConfigurationService: AppConfigurationService) {
  }

  /**
   * Interface that a class can implement to be a guard deciding if a route can
   * be activated.
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   */
  pouplateDynamicRoutes(routes: Routes, location: CoreMenuFrontendRouteLocation): Routes {

    const dynamicPages: CoreMenuFrontendRoute[] = this.appConfigurationService.getBootstrapData('dynamicroutes') as CoreMenuFrontendRoute[];

    if (!dynamicPages) {
      return routes;
    }

    for (const frontendRoute of dynamicPages) {

      if (frontendRoute.Location !== location) {
        continue;
      }

      // La ruta es por defecto desde el home, el prefijo del layout lo toma de la jerarquía
      const r: Route = {
        path: frontendRoute.Path.replace(/%/g, ':')
      };

      switch (frontendRoute.Component.Type) {
        case 'CoreGenericFormPageSimpleComponent':
          const coreGenericFormPageSimpleComponent: CoreGenericFormPageSimpleComponent = frontendRoute.Component as CoreGenericFormPageSimpleComponent;
          r.component = GenericFormPageSimpleComponent;
          r.data = {
            componentConfig:
              new GenericFormPageSimpleArguments(coreGenericFormPageSimpleComponent.FormId, coreGenericFormPageSimpleComponent.FormArgumentMapping, coreGenericFormPageSimpleComponent.FormArgumentMappingFromResolver)
          };
          break;
        case 'CoreGenericViewPageSimpleComponent':
          const coreGenericViewPageSimpleComponent: CoreGenericViewPageSimpleComponent = frontendRoute.Component as CoreGenericViewPageSimpleComponent;
          r.component = GenericListPageSimpleComponent;
          r.data = {
            componentConfig:
              new GenericListPageSimpleArguments(coreGenericViewPageSimpleComponent.ViewsId, coreGenericViewPageSimpleComponent.ArgumentMapping, coreGenericViewPageSimpleComponent.EnableBackButton)
          };
          break;
        case 'CoreGenericFormViewFormPageSimpleComponent':
          const coreGenericFormViewFormPageSimpleComponent: CoreGenericFormViewFormPageSimpleComponent = frontendRoute.Component as CoreGenericFormViewFormPageSimpleComponent;
          r.component = GenericFormListFormPageSimpleComponent;
          r.data = {
            componentConfig:
              new GenericFormListFormPageSimpleArguments(
                coreGenericFormViewFormPageSimpleComponent.Form1Id,
                coreGenericFormViewFormPageSimpleComponent.Form1ArgumentMapping,
                coreGenericFormViewFormPageSimpleComponent.List1Id,
                coreGenericFormViewFormPageSimpleComponent.List1IdArgumentMapping,
                coreGenericFormViewFormPageSimpleComponent.Form2Id,
                coreGenericFormViewFormPageSimpleComponent.Form2ArgumentMapping,
                coreGenericFormViewFormPageSimpleComponent.Form3Id,
                coreGenericFormViewFormPageSimpleComponent.Form3ArgumentMapping
              )
          };
          break;
        default:
      }

      routes = [r, ...routes];
    }

    return routes;
  }
}
