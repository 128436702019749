<app-comment [value]="'TO TRANSLATE THIS VIEW, USE THE FOLLOWING CONTEXT: be:view:' + listId"></app-comment>
<div *ngIf="loadingAnimationPlaceholder; else listComponent">
  <app-list-loading-placeholder></app-list-loading-placeholder>
</div>
<div *ngIf="!loadingAnimationPlaceholder && (loadErrorCode === 403 || loadErrorCode === 401) && !hideOn403">
  <span>{{'No tiene permisos para ver este recurso' | t }}</span>
</div>

<div *ngIf="showLoadError && !loadingAnimationPlaceholder && (loadErrorCode === 429 || loadErrorCode === 423)">
  <span>{{'No se ha podido obtener el recurso. Por favor reintentelo de nuevo más tarde.' | t }}</span>
</div>
<ng-template #listComponent>
  <ng-container appCoreRenderTemplate [data]="this.listComponentService.getConfiguration().OnEmptyResultSetRender"
                *ngIf="emptyResultSetMessage; else innerListComponent">
  </ng-container>
  <ng-template #innerListComponent>
    <div class="flex-container" [ngClass]="getClasses()" *ngIf="!authError">

      <div *ngIf="listComponentService.getConfiguration() && title !== null && title !== undefined">
        <h2>{{ title }}</h2>
      </div>

      <ng-container appViewHeader>
      </ng-container>

      <app-view-mode-selector></app-view-mode-selector>
      <ng-container
          appViewModeDirective
          [ViewResultData]="data">
      </ng-container>

      <ng-template
          *ngIf="listComponentService.getConfiguration() && listComponentService.getUserConfiguration()"
          [appPagerLoader]="data"></ng-template>
    </div>
  </ng-template>
</ng-template>


