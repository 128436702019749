<ng-container
    *ngIf="listComponentService.getConfiguration() && listComponentService.getUserConfiguration()">

  <div fxLayout="column"
       fxFlex="100"
       fxFill
       class="bar-content">
    <div
        class="exposed-filters-container"
        fxLayout="row"
        fxFlex="100"
        fxFill>
      <app-exposed-filters updateViewConfigurationOnChangeSelection="true"></app-exposed-filters>
    </div>
    <div
        class="main-container"
        fxLayout="row"
        fxLayout.xs="column"
        fxLayout.sm="column"
        fxFlex="100"
        fxFill>
      <!--<app-view-configuration></app-view-configuration>-->
      <div class="searcher-container"
           fxLayout="column"
           fxFlex="50"
           fxFlex.xs="100"
           fxFlex.sm="100">
        <app-view-searcher-material>
        </app-view-searcher-material>
        <div *ngIf="currentUserConfigurationIsCustomized"
             class="view-search-and-filter-message-container">
          <i class="m-fi " [ngClass]="'m-fi--exclamation-on-circle'"></i>
          <span class="view-search-and-filter-message">{{ 'El listado tiene filtros, búsquedas o consultas aplicadas.' | t }}</span>
        </div>
      </div>
      <div
          class="actions-container"
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutAlign="end end"
          fxLayoutAlign.xs="center center"
          fxLayoutAlign.sm="end center"
          fxFlex="50"
          fxFlex.xs="100">
        <app-view-local-actions-material></app-view-local-actions-material>
        <app-view-operations
            (onVboOperationCompleted)="dispatchEventOnTaskCompleted($event)"></app-view-operations>
      </div>
    </div>
    <div fxLayout="row"
         fxFlex="100"
         fxFill
         fxHide.xs
         fxLayoutAlign="start center">
      <app-view-filter-material></app-view-filter-material>
      <app-view-column-configuration-material></app-view-column-configuration-material>
      <div [title]="'Refrescar listado' | t">
        <button mat-button class="c-btn-link" (click)="refreshView()">
          <i class="fas fa-sync-alt"></i>
          {{'Actualizar' | t }}
        </button>
      </div>
    </div>
    <app-view-user-configuration-material></app-view-user-configuration-material>
    <app-view-filter-container></app-view-filter-container>
    <app-view-filter-tabs fxHide.xs></app-view-filter-tabs>
  </div>

</ng-container>
