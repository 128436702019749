import { ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import {
  CoreHashedKey,
  DtoFrontendModalType,
  FormElementFormatItemsRequestResult,
  FormElementOptionExtended
} from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { DecoupledModalBridgeService } from '../../../../decoupled-modal/decoupled-modal-bridge.service';
import { ModalReference } from '../../../../decoupled-modal/models/decoupled-modal-bridge.interface';
import { isNullOrUndefined, jsonEqual, UtilsTypescript } from '../../../../utils/typescript.utils';
import { FormManagerService } from '../../../form-manager/form-manager.service';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { ViewsselectormodalComponent } from '../viewsselectormodal/viewsselectormodal.component';
import { TranslatorService } from '../../../../../core/translator/services/rest-translator.service';

/**
 * Component to select multiple elements from a list as an input
 */
@Component({
  selector: 'app-viewsselector',
  templateUrl: './viewsselector.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ViewsselectorComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => ViewsselectorComponent), multi: true}
  ]
})
export class ViewsselectorComponent extends FrontendFormElementInput implements OnInit {

  /**
   * Selected values
   */
  selectedKeysValue: CoreHashedKey[];

  /**
   * Selected values rendered
   */
  renderedItems: FormElementOptionExtended[];

  /**
   * Creates a new instance of ListSelectorInputMultipleComponent
   */
  constructor(
    protected dmbs: DecoupledModalBridgeService,
    protected formManagerService: FormManagerService,
    protected cdRef: ChangeDetectorRef,
    protected localeService: TranslatorService
  ) {
    super(formManagerService, cdRef, localeService);
  }

  get selectedKeys(): CoreHashedKey[] {
    if (isNullOrUndefined(this.selectedKeysValue)) {
      return [];
    }
    return this.selectedKeysValue;
  }

  set selectedKeys(value: CoreHashedKey[]) {
    if (UtilsTypescript.asIterable(value).length === 0) {
      value = null;
    }
    this.selectedKeysValue = value;
    this.propagateChange(value);
  }

  /**
   * Initialize component label
   */
  ngOnInit(): void {
  }

  /**
   * Opens list modal
   * @param {TemplateRef<any>} template
   */
  openModal(modalType: DtoFrontendModalType = DtoFrontendModalType.Modal): void {
    this.propagateTouch();
    this.subscribeClose(this.createNewModal(modalType));
  }

  /**
   * Inner Open Modal
   */
  createNewModal(modalType: DtoFrontendModalType = DtoFrontendModalType.Modal): ModalReference<unknown> {
    const ref: ModalReference<unknown> = this.dmbs.showComponent(
      ViewsselectormodalComponent,
      {
        ModalType: modalType
      },
      {
        formManager: this.formManagerService,
        configuration: this.config,
        selectedKeys: [...this.selectedKeys]
      }
    );

    return ref;
  }

  protected subscribeClose(ref: ModalReference<unknown>): void {
    ref.instance$
      .takeUntil(this.componentDestroyed$)
      .subscribe((component: ViewsselectormodalComponent) => {
        component.onSelect
          .pipe(
            takeUntil(this.componentDestroyed$),
            take(1)
          )
          .subscribe(this.selectedItemsHandler.bind(this));
      });
  }

  /**
   * What to do when items are selected
   *
   * @param items
   */
  selectedItemsHandler(items: CoreHashedKey[]): void {
    this.selectedKeys = items;
    // No es necesario volver a propagar valores, esto
    // se realiza implicitamente en el setter de selectedKeys
    // this.propagateChange(items);
    this.renderSelectedKeys();
  }

  /**
   * The selected keys
   */
  renderSelectedKeys(): void {
    // Si las claves están vacías, no tengo que ir a buscar a servidor,
    // solo tengo que formatear un vacío.
    if (this.selectedKeys && this.selectedKeys.length === 0) {
      this.itemsFormattedHandler(new FormElementFormatItemsRequestResult());
      return;
    }

    this.formManagerService
      .callbackFormatItems(this.selectedKeys, this.config.ClientPath)
      .subscribe(this.itemsFormattedHandler.bind(this));
  }

  /**
   * Mostrar o no el icono de añadir
   */
  get showAddIcon(): boolean {
    // Si no soy editable
    if (this.isDisabled) {
      return false;
    }
    // Si solo permito seleccionar uno, y ya hay uno seleccionado
    if (!isNullOrUndefined(this.selectedKeysValue) && this.config.isMultiselect !== true) {
      return false;
    }
    return true;
  }

  /**
   * The selected element options.
   *
   * @param result
   */
  itemsFormattedHandler(result: FormElementFormatItemsRequestResult): void {
    this.renderedItems = result.Options;
    this.cdRef.detectChanges();
  }

  /**
   * Remove the given i element from values array
   * @param {number} i
   */
  removeElement(key: string): void {
    this.propagateTouch();
    this.renderedItems = this.renderedItems.filter((j) => j.Key !== key);
    this.selectedKeys = this.selectedKeys.filter((j) => j.Key !== key);
    this.cdRef.detectChanges();
  }

  /**
   * Method used by angular forms to write the default value to this component
   * @param obj
   */
  writeValue(obj: CoreHashedKey[]): void {
    // Si el valor no ha cambiado, no tengo que propagar el cambio.
    if (jsonEqual(this.selectedKeys, obj)) {
      return;
    }
    // Si ha cambiado, propago para renderizar
    this.selectedItemsHandler(obj);
  }

  getId(): string {
    return this.config.ClientId;
  }
}
