import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { ListComponent2Service } from '../../../../list.service';
import { DecoupledModalBridgeService } from '../../../../../decoupled-modal/decoupled-modal-bridge.service';
import { ModalReference } from '../../../../../decoupled-modal/models/decoupled-modal-bridge.interface';
import {
  DtoFrontendModalType,
  StoredViewUserConfiguration, StoredViewUserConfigurationInfo
} from '../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { TranslatorService } from '../../../../../../core/translator/services/rest-translator.service';
import { takeUntil } from 'rxjs/operators';
import { ActiveUserConfigurationClass } from '../../../../activeUserConfigurationClass';
import { DestroyableObjectTrait } from '../../../../../utils/destroyableobject.trait';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ViewsService } from '../../../../../../core/services/ETG_SABENTISpro_Application_Core_views.service';
import { MessageToastService } from '../../../../../../core/message-toast/share/message-toast.service';

@Component({
  selector: 'app-view-user-configuration-material',
  templateUrl: './view-user-configuration-material.component.html',
  styleUrls: ['./view-user-configuration-material.component.scss']
})
export class ViewUserConfigurationMaterialComponent extends DestroyableObjectTrait {

  @ViewChild('loadConfigurationModal', {static: true}) loadConfigurationModal: TemplateRef<any>;

  formGroupLoadConfiguration: FormGroup;

  @ViewChild('saveConfigurationModal', {static: true}) saveConfigurationModal: TemplateRef<any>;

  formGroupSaveConfiguration: FormGroup;

  /**
   * Configuration modal reference.
   */
  modalRef: ModalReference<unknown>;

  options: Array<StoredViewUserConfigurationInfo>;


  /**
   * The current configuration is stored into de Views Storage
   */
  currentUserConfigurationIsCustomized: boolean = false;

  /**
   * The view storage is enabled
   */
  viewStorageIsEnabled: boolean = false;

  constructor(private cdRef: ChangeDetectorRef,
              private listComponentService: ListComponent2Service,
              private dmbs: DecoupledModalBridgeService,
              private translatorService: TranslatorService,
              private _formBuilder: FormBuilder,
              private _viewsService: ViewsService,
              private _messageToastService: MessageToastService) {
    super();

    // Initializes component suscription to view `userConfigurationChanged`.

    this.listComponentService
      .activeUserConfiguration
      .pipe(
        takeUntil(this.componentDestroyed$),
      )
      .takeUntil(this.componentDestroyed$)
      .subscribe((next: ActiveUserConfigurationClass) => {
        this.viewStorageIsEnabled = next.viewStorageIsEnabled;
        this.currentUserConfigurationIsCustomized = next.currentUserConfigurationIsCutomized();
      });
  }

  /**
   * Refresh the view removing the stored view user configuration
   */
  dropStoredUserConfigurationAndRefresh(): void {
    this.listComponentService.dropStoredUserConfigurationAndRefresh();
  }

  saveUserViewConfiguration(): void {
    this.formGroupSaveConfiguration = this._formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', null]
    });
    this.modalRef = this.dmbs.showTemplate(this.saveConfigurationModal, {
      Title: this.translatorService.get('Guardar configuración actual'),
      ModalType: DtoFrontendModalType.Modal,
    });
  }

  public saveConfiguration(): void {
    const configuration: StoredViewUserConfiguration = new StoredViewUserConfiguration();
    configuration.ViewUserConfiguration = this.listComponentService.getUserConfiguration();
    configuration.ViewId = this.listComponentService.getConfiguration().ViewId;
    configuration.Name = this.formGroupSaveConfiguration.value.name;
    configuration.Description = this.formGroupSaveConfiguration.value.description;
    configuration.Id = null;

    this._viewsService.postSaveconfigurationastemplate(configuration, configuration.ViewId).pipe(takeUntil(this.componentDestroyed$))
      .subscribe(x => {
        this._messageToastService.showSuccess(this.translatorService.get('Consulta guardada correctamente'), '', false);
        this.modalRef.doClose();
      });
  }


  loadUserViewConfiguration(): void {

    this._viewsService
      .getGetconfigurationtemplatesinfo(this.listComponentService.getConfiguration().ViewId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(x => {
        this.options = x.result.StoredViewUserConfigurations;
        this.formGroupLoadConfiguration = this._formBuilder.group({
          query: ['', [Validators.required]]
        });
        this.modalRef = this.dmbs.showTemplate(this.loadConfigurationModal, {
          Title: this.translatorService.get('Cargar configuración almacenada'),
          ModalType: DtoFrontendModalType.Modal,
        });
      })
  }

  public loadConfigurationTemplate(): void {
    const selectedQuery: string = this.formGroupLoadConfiguration.value.query;
    const currentOption: StoredViewUserConfigurationInfo = this.options.find(x => x.Id === selectedQuery);
    this._viewsService
      .getGetconfigurationtemplate(selectedQuery)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(x => {
        this.options = null;
        this.modalRef.doClose();
        this._messageToastService.showInfo(this.translatorService.get('Cargando consulta almacenada ' + currentOption.Name), '', false);
        this.listComponentService.setUserConfiguration(x.result.ViewUserConfiguration);
      })

  }

  public deleteConfigurationTemplate(): void {
    const selectedQuery: string = this.formGroupLoadConfiguration.value.query;

    this._viewsService.deleteDeleteconfigurationtemplate(selectedQuery)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(x => {
        this.modalRef.doClose();
        this._messageToastService.showSuccess(this.translatorService.get('Consulta eliminada correctamente'), '', false);
      });
  }

  /**
   * closes modal
   */
  closeHandler(): void {
    this.modalRef.doClose();
    this.modalRef = null;
  }
}
