import { Component, OnInit } from '@angular/core';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { DateTimeService } from '../../../../../../core/date-time/date-time.service';
import {
  DtoFrontendModalType,
  IViewField,
  ViewFieldData,
  ViewFilterInstanceSimple,
  ViewFilterSimple,
  ViewFilterSimpleOperatorConfiguration,
  ViewsDataTypeConfiguration
} from '../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { DecoupledModalBridgeService } from '../../../../../decoupled-modal/decoupled-modal-bridge.service';
import { ModalReference } from '../../../../../decoupled-modal/models/decoupled-modal-bridge.interface';
import { DestroyableObjectTrait } from '../../../../../utils/destroyableobject.trait';
import { getInSafe, UtilsTypescript } from '../../../../../utils/typescript.utils';
import { ListComponent2Service } from '../../../../list.service';
import { ViewFilterModalComponent } from './view-filter-modal.component';
import { TranslatorService } from '../../../../../../core/translator/services/rest-translator.service';


@Component({
  selector: 'app-view-filter-trigger',
  templateUrl: './view-filter-trigger.component.html',
  styleUrls: ['./view-filter-trigger.component.scss']
})
export class ViewFilterTriggerComponent
  extends DestroyableObjectTrait implements OnInit {

  /**
   * available fields
   */
  fields: { [key: string]: ViewFilterSimple };

  /**
   * Available operators
   */
  operators: { [key: string]: ViewFilterSimpleOperatorConfiguration };

  /**
   * Available operators by type
   */
  operatorsForType: ViewsDataTypeConfiguration[];

  /**
   * Original fields
   */
  originalFields: { [key: string]: IViewField };

  /**
   * Creates a new instance of FilterTriggerComponent
   */
  constructor(
    private listService: ListComponent2Service,
    private dateTimeService: DateTimeService,
    private dmbs: DecoupledModalBridgeService,
    private translatorService: TranslatorService
  ) {
    super();
  }

  get simpleFilterAvailable(): boolean {
    return !isNullOrUndefined(this.listService.getFilterManagerSimple());
  }

  /**
   * Initializes component
   */
  ngOnInit(): void {
    this.fields = getInSafe(this.listService.getFilterManagerSimple(), x => x.Filters, {});
    this.operatorsForType = getInSafe(this.listService.getFilterManagerSimple(), x => x.OperatorsForType, []);
    this.originalFields = getInSafe(this.listService.getConfiguration(), x => x.Fields, {});
    this.operators = getInSafe(this.listService.getFilterManagerSimple(), x => x.OperatorConfiguration, {});
  }

  /**
   * gets data when modal is closed and calls service to update info
   * @param e
   */
  modalClosedHandler(e: any): void {

    // Hay operadores como "vacío" que no tienen un tercer argument fieldValue o fieldValue2
    const validArgument: boolean = e && e['field'] && e['operator'];

    console.debug('Invalid filter configuration, field and operator parameters are required.');

    if (!validArgument) {
      return;
    }

    const filter: ViewFilterInstanceSimple = new ViewFilterInstanceSimple();

    filter.Id = UtilsTypescript.guid();
    filter.FilterId = e['field'];
    filter.Operator = e['operator'];
    filter.Negate = e['negate'];

    // Estos dos son opcionales
    filter.Value = e['fieldValue'];
    filter.Value2 = e['fieldValue2'];

    // Que es esto?
    if (e['fieldValue'] instanceof Date) {
      (e['fieldValue'] as Date).setHours(12, 0);
      filter.Value = ((e['fieldValue'].getTime() / 1000)) + this.dateTimeService.toAdd2user();
    }

    // Caso raro en que los valores del rango de fechas vienen en un array?
    if (
      !isNullOrUndefined(e['fieldValue']) &&
      e['fieldValue'].constructor === Array &&
      e['fieldValue'].length === 2 &&
      e['fieldValue'][0] instanceof Date &&
      e['fieldValue'][1] instanceof Date) {
      (e['fieldValue'][0] as Date).setHours(12, 0);
      filter.Value = ((e['fieldValue'][0].getTime() / 1000)) + this.dateTimeService.toAdd2user();
      (e['fieldValue'][1] as Date).setHours(12, 0);
      filter.Value2 = ((e['fieldValue'][1].getTime() / 1000)) + this.dateTimeService.toAdd2user();
    }

    this.listService.addFilter(filter);
  }

  /**
   * opens modal
   */
  openModal(event: Event): void {
    const ref: ModalReference<unknown> = this.dmbs.showComponent(
      ViewFilterModalComponent,
      {
        Title: this.translatorService.get('Añadir filtros'),
        ModalType: DtoFrontendModalType.Modal,
      },
      {
        fields: this.fields,
        originalFields: this.originalFields as { [key: string]: ViewFieldData },
        operators: this.operators,
        operatorsForType: this.operatorsForType,
      }
    );

    ref.instance$
      .takeUntil(this.componentDestroyed$)
      .subscribe((component: ViewFilterModalComponent) => {
        component.onConfigurationSet
          .subscribe((e) => this.modalClosedHandler(e));
      });
  }
}

