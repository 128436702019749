import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnChanges,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';

import { ListComponent2Service } from '../list.service';
import { PagerInterface } from './interfaces/pager.interface';
import {
  ViewConfiguration,
  ViewsPagerBase,
  ViewsPagerTypeEnum
} from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { PAGERS } from './pager.types';


@Directive({
  selector: '[appPagerLoader]'
})
export class PagerLoaderDirective implements OnInit, OnChanges {

  @Input('appPagerLoader') data;

  componentRef: ComponentRef<PagerInterface>;

  constructor(private viewContainerRef: ViewContainerRef,
              private componentFactoryResolver: ComponentFactoryResolver,
              public listComponentService: ListComponent2Service) {
  }

  ngOnInit(): void {
    const config: ViewConfiguration = this.listComponentService.getConfiguration();
    const pager: ViewsPagerBase = Object.assign(new ViewsPagerBase(), config.Pager);
    if (pager.Type === ViewsPagerTypeEnum.None) {
      return;
    }
    this.viewContainerRef.clear();
    this.componentRef = this.viewContainerRef.createComponent(PAGERS[ViewsPagerTypeEnum[pager.Type]].component);
    this.componentRef.instance.viewResult = this.data;
  }

  ngOnChanges(): void {
    if (!isNullOrUndefined(this.componentRef)) {
      this.componentRef.instance.viewResult = this.data;
    }
  }
}
