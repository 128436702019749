<select [(ngModel)]="selectedItems"
        [disabled]="isDisabled"
        (blur)="propagateTouch()"
        [ngModelOptions]="{standalone: true}"
        [id]="this.uniqueComponentId('select-')">
  <option [id]="this.uniqueComponentId(option.key + '-')" *ngFor="let option of options"
          value="{{option.key}}">{{option.name}}</option>
</select>
<!-- O agregue uno nuevo -->
<ng-container *ngIf="this.showCreateNewItem">
  <div class="form-autocompletenew-create" (click)="createNewItem()">
    <i class="fas fa-plus-circle"></i>
    <span class="form-autocompletenew-create--text">{{ this.elementSelectDynamic.CreateNewItemTitle }}</span>
  </div>
</ng-container>
<i>{{currentItemDescription}}</i>
