<select [(ngModel)]="selectedItems"
        [disabled]="isDisabled"
        (blur)="propagateTouch()"
        [ngModelOptions]="{standalone: true}"
        [id]="this.uniqueComponentId('select-')">
  <option [id]="this.uniqueComponentId(option.key + '-')" *ngFor="let option of options"
          value="{{option.key}}">{{option.name}}</option>
</select>

<i>{{currentItemDescription}}</i>
