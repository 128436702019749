import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { backendTypeMatch } from '../../../shared/utils/typescript.utils';
import { ChangedetectorService } from '../../changedetector/changedetector.service';
import { DestroyableObjectTrait } from '../../../shared/utils/destroyableobject.trait';
import { delay, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { CommandService } from '../../commands/command.service';
import { IResultCollector } from '../../commands/resultcollector.interface';
import {
  CoreMessageCommand,
  MessageCommandData,
  MessageType
} from '../../models/ETG_SABENTISpro_Application_Core_models';
import { ActiveToast } from 'ngx-toastr/toastr/toastr.service';
import { AppContainerService } from '../../../app-container.service';
import { NavigationEnd, Router } from '@angular/router';
import { race } from 'rxjs';
import { RxNgZoneScheduler } from '../../../utils/rx-ng-zone-scheduler';

/**
 * Messaje Toast Service.
 */
@Injectable({
  providedIn: 'root'
})
export class MessageToastService extends DestroyableObjectTrait {

  /**
   * Constructor.
   * @param commandService CommandService
   */
  constructor(
    private commandService: CommandService,
    private toastr: ToastrService,
    private cdService: ChangedetectorService,
    private appContainerService: AppContainerService,
    private ngZone: NgZone,
    private navigationService: Router,
    private zoneScheduler: RxNgZoneScheduler
  ) {
    super();

    // Nos suscribimos el evento de nuevo comando
    this.commandService
      .CommandObservable
      .pipe(
        takeUntil(this.componentDestroyed$),
        filter(obj => backendTypeMatch(CoreMessageCommand.$type, obj.Argument)),
        map((obj) => obj as IResultCollector<CoreMessageCommand, (() => Promise<boolean>) | Observable<boolean>>)
      )
      .subscribe((next) => {
        next.AddResult(() => {
          const toast: ActiveToast<any> = this.doShow(next.Argument.Message);
          this.cdService.runApplicationChangeDetection();
          return Promise.resolve(true);
        });
      });
  }

  private setToastCloseEvents(toast: ActiveToast<any>): void {
    // Esto del leaveNgZone está explicado aqúi: rx-ng-zone-scheduler.ts
    // es un workaround para los tests de protractor
    Observable.timer(5000, this.zoneScheduler.leaveNgZone())
      .pipe(
        take(1),
        // Eventos externos que desbloquean la deaparición del toast:
        // Navegar
        // Hacer click en la página
        switchMap(() => race([
          this.appContainerService.documentClicked,
          this.navigationService.events.filter(e => e instanceof NavigationEnd)])),
        takeUntil(toast.onHidden),
        delay(2500, this.zoneScheduler.leaveNgZone()),
        map((i) => {
          this.toastr.remove(toast.toastId);
        })
      )
      .subscribe();
  }

  public doShow(messageData: MessageCommandData): ActiveToast<any> {
    switch (messageData.Type) {
      case MessageType.WARNING:
        return this.showWarning(messageData.Message, messageData.Title, messageData.EnableHtml);
        break;
      case MessageType.ERROR:
        return this.showError(messageData.Message, messageData.Title, messageData.EnableHtml);
        break;
      case MessageType.STATUS:
        return this.showSuccess(messageData.Message, messageData.Title, messageData.EnableHtml);
        break;
      default:
        return this.showInfo(messageData.Message, messageData.Title, messageData.EnableHtml);
        break;
    }
  }

  /**
   * Show toast of type success.
   * @param message string
   * @param messageTitle string
   */

  showSuccess(message: string, messageTitle: string = null, isHTML: boolean): ActiveToast<any> {
    const result: ActiveToast<any> = this.toastr.success(message, messageTitle, this.getData(isHTML));
    this.setToastCloseEvents(result);
    return result;
  }

  /**
   * Show toast of type error.
   * @param message string
   * @param messageTitle string
   */

  showError(message: string, messageTitle: string = null, isHTML: boolean): ActiveToast<any> {
    const result: ActiveToast<any> = this.toastr.error(message, messageTitle, this.getData(isHTML));
    this.setToastCloseEvents(result);
    return result;
  }

  /**
   * Show toast of type warning.
   * @param message string
   * @param messageTitle string
   */

  showWarning(message: string, messageTitle: string = null, isHTML: boolean): ActiveToast<any> {
    const result: ActiveToast<any> = this.toastr.warning(message, messageTitle, this.getData(isHTML));
    this.setToastCloseEvents(result);
    return result;
  }

  /**
   * Show toast of type info.
   * @param message string
   * @param messageTitle string
   */

  showInfo(message: string, messageTitle: string = null, isHTML: boolean): ActiveToast<any> {
    const result: ActiveToast<any> = this.toastr.info(message, messageTitle, this.getData(isHTML));
    this.setToastCloseEvents(result);
    return result;
  }

  /**
   * Return object data with the time of create of the toast.
   */
  private getData(isHTML: boolean): Partial<IndividualConfig> {
    return {
      enableHtml: isHTML,
      onActivateTick: Zone.current.name !== 'angular',
      disableTimeOut: true
    };
  }
}
