<div class="c-selectngx"
     appNgxSelectChangedetectionFixerDirective
     bsDropdown
     [id]="uniqueComponentId('checkmodal-')"
     [isDisabled]="isDisabled"
     [insideClick]="true"
     #dropdown="bs-dropdown">
  <button [id]="uniqueComponentId('dropdowntoogle-')"
          dropdownToggle
          type="button"
          class="dropdown-toggle"
          [attr.aria-controls]="uniqueComponentId('ul-')">
    {{selectedText}}
    <span style="padding-right: 7px" class="m-fi m-fi--arrow-point-down" [class.arrow-point-down-padding]="selectedValue.length == 0"></span>
  </button>
  <ul *bsDropdownMenu
      class="dropdown-menu dropdown-menu-right c-list-group"
      role="menu"
      [attr.aria-labelledby]="uniqueComponentId('dropdowntoogle-')"
      [id]="uniqueComponentId('ul-')">
    <li *ngIf="config.ShowSelectAll" class="margin-left-check-modal">
      <input type="checkbox" [id]="uniqueComponentId('selectall')" [checked]="selectAll" (click)="onSelectAllChange()" (blur)="propagateTouch()">
      <label [for]="uniqueComponentId('selectall')" class="o-checkradio__label"> {{ "Seleccionar todos" | t }} </label>
    </li>
    <li [id]="uniqueComponentId(option.Key + '-')"  class="margin-left-check-modal"
        *ngFor="let option of config.options">
      <input
        [id]="uniqueComponentId(option.Key + '-mcheck')"
        [checked]="isChecked(option)"
        [disabled]="selectAll || (getFormElement().MaxItems && selectedValue.length >= getFormElement().MaxItems && !isChecked(option))"
        type="checkbox"
        [value]="option.Key"
        (blur)="propagateTouch()"
        (click)="checkHandler($event)">
      <label
        [id]="uniqueComponentId(option.Key + '-label')"
        [for]="uniqueComponentId(option.Key + '-mcheck')"
        class="o-checkradio__label">
        {{ option.Name }}
      </label>
    </li>
  </ul>
</div>
