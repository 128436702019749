<div style="width: 100%;">
  <div class="c-popup-config" style="width: 100%;">
    <div class="c-popup-config__column" style="width: 85%; margin: 2px;">
      <p>{{ 'Columnas disponibles' | t }}</p>
      <ul class="c-popup-config__list  o-list-bare" *ngIf="availableItems?.length <= 0">
        <li class="c-popup-config__list-item  u-align-right">
                  <span class="u-color__brand-secondary  u-font-size__xxs">
                      {{ 'No hay columnas disponibles.' | t }}
                  </span>
        </li>
      </ul>
      <ul class="c-popup-config__list  o-list-bare" *ngIf="availableItems?.length > 0">
        <li class="c-popup-config__list-item  u-align-right">
          <span class="e-link-fake  u-color__brand-secondary  u-font-size__xxs"
                (click)="addAllItems()">{{ 'Añadir todos' | t }}</span>
        </li>
        <li class="c-popup-config__list-item" *ngFor="let item of availableItems; let i = index">
          <div class="c-popup-config__elem">
            <span>{{ item.Header }}</span>
            <button type="button" class="o-btn__default" (click)="addItem(item)"><i
                class="m-fi  m-fi--double-arrow-to-right"></i></button>
          </div>
        </li>
      </ul>
    </div>
    <div class="c-popup-config__column" style="width: 85%; margin: 2px;">
      <p>{{ 'Columnas visibles' | t }}</p>
      <ul class="c-popup-config__list  c-popup-config__list--clean  o-list-bare" *ngIf="selectedItems?.length > 0">
        <li class="c-popup-config__list-item" *ngFor="let item of selectedItems; let i = index">
          <div class="c-popup-config__elem">
            <span *ngIf="item !== null && item !== undefined">{{ item.Header }}</span>
            <span *ngIf="item === null || item === undefined"> --- </span>
            <button type="button" *ngIf="item" class="c-btn" (click)="removeDefaultItem(item)"><i
                class="m-fi  m-fi--close"></i></button>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="" style="display: flex; justify-content: center; margin-top: 20px;">
    <button type="button" class="o-btn__danger" (click)="closeHandler()">{{ 'Cancelar' | t }}</button>
    <button type="button" class="o-btn__primary" (click)="saveAndCloseHandler()">{{ 'Aceptar' | t }}</button>
  </div>
</div>


