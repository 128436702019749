import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ContextUtils } from '../../shared/context/context.utils';

/**
 * This interceptor transforms the headers of outgoing requests adding the
 * context.
 */
@Injectable()
export class ContextInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    /**
     * Commands previously were set here. But now they are set in the
     * `AppContextService` as an interceptor should not modify other
     * app state/storage conditions.
     *
     * That logic must be done in services.
     *
     * "Most interceptors will transform the outgoing request before passing
     * it to the next interceptor in the chain".
     */

    const requestCopy: HttpRequest<any> = ContextUtils.AddContextToHeaders(req);
    return next.handle(requestCopy)
      .catch(res => {
        /**
         * On errors, commands previously were set here. But now they are set
         * in the `AppContextService` as result of the handling done in the
         * `CommandsInterceptor`.
         */
        return throwError(res);
      });
  }
}

