import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DestroyableObjectTrait } from 'app/shared/utils/destroyableobject.trait';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';
import { ViewsSingleItemOperationImplementationTypes } from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { BackNavigateOptions } from '../../../core/navigation/object-navigation';
import { LocalActionEventData2 } from '../../list_v2/events/localaction.eventdata';
import { List2Component } from '../../list_v2/list.component';


/**
 * MappingExecutionHistoryComponent: component that controls the mapping execution history
 *
 * @export
 * @class MappingExecutionHistoryComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-mapping-execution-history',
  templateUrl: './mapping-execution-history.component.html',
})

/**
 * This view controls the visualization of information for users and asociated groups
 */
export class MappingExecutionHistoryComponent extends DestroyableObjectTrait implements OnInit {

  @ViewChild('list', { read: List2Component, static: true }) list;
  params = {};
  listSettings = 'custommappingexecution'

  /**
   * Interval handler observable.
   */
  protected timerObservable = Observable.interval(10000);

  /**
   * Creates an instance of MappingExecutionComponent
   * @param {Router} router
   * @param {ActivatedRoute} activatedRoute
   * @param {NavigationService} navigationService
   */
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService) {
    super();

    this.timerObservable
      .takeUntil(this.componentDestroyed$)
      .subscribe(
        () => {
          this.refresh();
        }
      );
  }

  /**
   * Initialization to get the parameters from the navigation
   */
  ngOnInit(): void {
    const routeParams: object[] = this.navigationService.findParamsFromRoute(this.activatedRoute.snapshot);
    const params: object = routeParams.find(x => x.hasOwnProperty('mapping'));

    if (!isNullOrUndefined(params)) {
      this.params['mappingdefinitionId'] = params['mapping'];
    }
  }

  /**
   * Handler when cancel button is pressed
   * @param e
   */
  cancel(e: any): void {
    // redirect to list
    this.navigationService.back({ skipMapping: true } as BackNavigateOptions);
  }

  /**
   * Handler on local action
   * @param {LocalActionEventData2} event
   */
  onLocalAction(event: LocalActionEventData2): void {
  }

  /**
   * Handler on SingleItemOperation
   * @param {SingleItemOperationEventData} event
   */
  onSingleItemOperation(data: any): void {
    const id: string = data.row.Metadata['field_meta_id'].RawValue;
    if (!isNullOrUndefined(data.row)) {
      switch (data.action) {
        case ViewsSingleItemOperationImplementationTypes.Details:
          this.navigationService.navigateUrlByController('utilities.mappings.details.execute.history.details', { '%mapping': this.params['mappingdefinitionId'], '%mappingexecution': id });
          break;
        default:
          throw new Error('Invalid operation');
      }
    }
  }

  refresh(): void {
    this.list.refresh();
  }
}
