import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-loading-placeholer',
  templateUrl: './form-loading-placeholer.component.html',
  styleUrls: ['./form-loading-placeholer.component.scss']
})
export class FormLoadingPlaceholerComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
