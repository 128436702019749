import { ChangeDetectorRef, Component, HostBinding, OnInit, SkipSelf } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommunicationService } from '../../../../core/communication/communication.service';
import {
  BatchTaskInfo,
  EmbeddedDocumentFile,
  EmbeddedDocumentQueue,
  EmbeddedDocumentTask,
  EmbeddedDocumentTemplate,
  EmbeddedDocumentUploadedFiles,
  UploadedFile,
  WebServiceResponseTyped
} from '../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { backendTypeMatch, isNullOrUndefined } from '../../../utils/typescript.utils';
import { FormManagerService } from '../../form-manager/form-manager.service';
import { FrontendFormElementInput } from '../formelementinput.class';
import { FrontendFormElementWrapper } from '../formelementwrapper.class';
import { TranslatorService } from '../../../../core/translator/services/rest-translator.service';
import { FileDownloaderService } from '../../../files/file-downloader.service';
import { takeUntil } from 'rxjs/operators';
import { BatchGlobalServicesClass } from '../../../batch/batch.global.service.class';
import { BatchService } from '../../../../core/services/ETG_SABENTISpro_Application_Core_batch.service';
import { FileService } from '../../../../core/services/ETG_SABENTISpro_Application_Core_file.service';


/**
 * Embedded document form element.
 *
 * This element doesn't requires a control value accessor as only
 * performs a document display job.
 */
@Component({
  selector: 'app-embedded-document',
  templateUrl: './embedded-document.component.html'
})
export class EmbeddedDocumentComponent extends FrontendFormElementWrapper implements OnInit {

  /**
   * The documents
   */
  documents: UploadedFile[];

  /**
   * Queue task.
   */
  task: BatchTaskInfo;

  /**
   * The documents URLs for core-file controller
   */
  private documentUrls: { [key: string]: SafeResourceUrl };

  /**
   * Deben implementarlo todos los wrappers para acceder al Control Value Accessor.
   */
  formElementInstance(): FrontendFormElementInput {
    throw new Error('Not supported.');
  }

  /**
   * EmbeddedDocumentComponent class constructor.
   *
   * @param {BatchGlobalServicesClass} batchGlobalServices
   * @param {FormManagerService} formManager
   * @param {CommunicationService} communicationService
   * @param {DomSanitizer} sanitizer
   * @param {ChangeDetectorRef} cdRef
   * @param {ChangeDetectorRef} cdRefParent
   * @param {FileService} fileService
   * @param {BatchService} batchService
   * @param translatorService
   * @param fileDownloaderService
   */
  constructor(
    protected batchGlobalService: BatchGlobalServicesClass,
    protected formManager: FormManagerService,
    protected communicationService: CommunicationService,
    protected sanitizer: DomSanitizer,
    protected cdRef: ChangeDetectorRef,
    @SkipSelf()
    protected cdRefParent: ChangeDetectorRef,
    protected fileService: FileService,
    protected batchService: BatchService,
    protected translatorService: TranslatorService,
    protected fileDownloaderService: FileDownloaderService) {
    super(formManager, cdRef, cdRefParent);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initializeEmbeddedDocument();
  }

  private initializeEmbeddedDocument(): void {
    if (!isNullOrUndefined(this.config.defaultValue)) {
      if (backendTypeMatch(EmbeddedDocumentFile.$type, this.config.defaultValue)) {
        throw new Error('EmbeddedDocumentFile type is not supported in frontend. This type must be changed in backend to a EmbeddedDocumentUploadedFiles');
      } else if (backendTypeMatch(EmbeddedDocumentQueue.$type, this.config.defaultValue)) {
        throw new Error('EmbeddedDocumentQueue type is not supported in frontend. This type must be changed in backend to a EmbeddedDocumentTask');
      } else if (backendTypeMatch(EmbeddedDocumentTemplate.$type, this.config.defaultValue)) {
        throw new Error('EmbeddedDocumentTemplate is type not supported in frontend. This type must be changed in backend to a EmbeddedDocumentTask');
      } else if (backendTypeMatch(EmbeddedDocumentTask.$type, this.config.defaultValue)) {
        const value: EmbeddedDocumentTask = this.config.defaultValue;
        this.batchService.getTask(value.Task.QueueId)
          .takeUntil(this.componentDestroyed$)
          .take(1)
          .subscribe((response: WebServiceResponseTyped<BatchTaskInfo>) => {
            this.task = response.result;
            this.cdRef.detectChanges();
          });
      } else if (backendTypeMatch(EmbeddedDocumentUploadedFiles.$type, this.config.defaultValue)) {
        const value: EmbeddedDocumentUploadedFiles = this.config.defaultValue;
        this.documents = value.Files;
        this.documentUrls = {};
        value.Files.forEach(x => {
          if (isNullOrUndefined(this.documentUrls[x.id])) {
            this.documentUrls[x.id] = this.createSafeUrlFromFileRef(x.url);
          }
        });
        this.cdRef.detectChanges();
      }
    }
  }

  /**
   * Class bindings.
   */
  @HostBinding('class')
  get hostWrapperClasses(): string {
    return this.getComponentClassesRendered();
  }

  /**
   * This method is triggered when the task associated to this field is completed.
   */
  onTaskCompleted(task: BatchTaskInfo): void {
    this.task = task;
    this.documentUrls = {};
    this.documents = [];
    this.batchGlobalService.GetTaskResultUploadedFiles(task, null, 'inline')
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((result: UploadedFile[]) => {
        if (result) {
          result.forEach(x => {
            if (isNullOrUndefined(this.documentUrls[x.id])) {
              this.documentUrls[x.id] = this.createSafeUrlFromFileRef(x.url);
            }
          });
          this.documents = result;
          this.cdRef.detectChanges();
        }
      })
  }

  getSortedDocuments(): UploadedFile[] {
    if (!this.documents) {
      return [];
    }
    return Object.assign([], this.documents.sort((x, y) => x.type > y.type ? 1 : -1));
  }

  getSafeResourceUrl(fileData: UploadedFile): SafeResourceUrl {
    if (!isNullOrUndefined(this.documentUrls[fileData.id])) {
      return this.documentUrls[fileData.id];
    }
    this.documentUrls[fileData.id] = this.createSafeUrlFromFileRef(fileData.url);
    return this.documentUrls[fileData.id];
  }

  createSafeUrlFromFileRef(fileRef: string): SafeResourceUrl {
    const url: string = this.communicationService.generateFileUrl(fileRef);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  /**
   * Check if the task is running
   */
  isTaskRunning(): boolean {
    return this.config && this.task && isNullOrUndefined(this.documents);
  }

  /**
   * Returns lastRun start timefor current task
   */
  lastRun(): string {
    return this.batchGlobalService.GetLastRun(this.task);
  }

  /**
   * Download the file using the FileRef
   * @param document
   */
  downloadResult(document: UploadedFile): void {
    const url: string = this.communicationService.generateFileUrl(document.url);
    this.fileDownloaderService.DownloadFileFromFileRef(url);
  }
}


