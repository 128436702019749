import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';

import {
  GeocodeResult,
  GeocodingConfiguration,
  ReverseGeocodeResult,
  WebServiceResponse
} from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import { AppConfigurationService } from '../../app.configuration.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { GeocodingService } from '../../core/services/ETG_SABENTISpro_Application_Core_geocoding.service';
// import { MapsAPILoader } from '@agm/core';

/**
 * Define a Global google object.
 */
declare var google: any;

@Injectable(
  {
    providedIn: 'root'
  }
)
export class FrontendGeocodingService {

  /**
   * Geocoding service configuration.
   */
  configuration: GeocodingConfiguration;

  /**
   * Google Maps geocoder.
   */
  geocoder: any;

  apiStatus: { isChecked: boolean, isLoaded: boolean };

  /***
   * GeocodingService constructor. When we instance the service the
   * googleMapsAPI is injected and loaded to be able to use the Google
   * Maps API services.
   *
   * @param {MapsAPILoader} mapsAPILoader
   * @param {GeocodingService} backendGeocodingService
   */
  constructor(
    private backendGeocodingService: GeocodingService,
    private configurationService: AppConfigurationService,
    private httpClient: HttpClient,
    @Optional() @SkipSelf() parentModule?: FrontendGeocodingService
  ) {
    // Protección para garantizar que esto está inyecto como SINGLETON
    if (parentModule) {
      throw new Error(
        'FrontendGeocodingService is already loaded. Import it in the AppModule only');
    }

    this.apiStatus = {isChecked: false, isLoaded: false};
    configurationService
      .isAppBootstrapped$()
      .filter(value => value)
      .take(1)
      .subscribe(v => {
        this.configuration = configurationService
          .getGeocodingConfiguration();
        this.apiLoaded.take(1).subscribe(x => {
          if (x) {
            console.debug('G Maps API LOADED')
          }
        })
      });
  }

  get apiLoaded(): Observable<boolean> {
    if (this.apiStatus.isChecked) {
      return of(this.apiStatus.isLoaded);
    }
    if (!isNullOrUndefined(this.configuration)) {
      return this.httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=' + this.configuration.ApiKey, 'callback')
        .pipe(
          map(() => {
            this.apiStatus.isChecked = true;
            this.apiStatus.isLoaded = true;
            return true;
          }),
          catchError((err) => {
            this.apiStatus.isChecked = true;
            return of(false)
          }),
        );
      return;
    }
    return of(false);
  }

  /**
   * Get the default map latitude.
   */
  get defaultLatitude(): number {
    return this.configuration.Latitude;
  }

  /**
   * Get the default map logitude.
   */
  get defaultLongitude(): number {
    return this.configuration.Longitude;
  }

  /**
   * Validates if the google configuration object is available.
   *
   * Checks if the API key and Language string are available.
   */
  checkIfConfigurationIsAvailable(): boolean {
    return !isNullOrUndefined(this.configuration)
      && !isNullOrUndefined(this.configuration.ApiKey)
      && !isNullOrUndefined(this.configuration.Language);
  }

  /***
   * This method transforms an address in string format to GeocodeResult object.
   *
   * @param {string} address
   * @returns {Observable<any>}
   */
  Geocode(address: string): Observable<GeocodeResult[]> {
    return this.backendGeocodingService
      .getGeocode(address, {showSpinner: false})
      .map((result: WebServiceResponse) => {
        if (!result.error) {
          return result.result as GeocodeResult[];
        }

        return null;
      });
  }

  /***
   * This method transforms an address in string format to GeocodeResult object.
   * An observable is used since this transformation is made on a google maps
   * service request and is subject to a Promise.
   *
   * @param {string} address
   * @returns {Observable<any>}
   */
  GeocodeOnFronted(
    address: string,
    useFrontedService: boolean = false): Observable<GeocodeResult[]> {

    return Observable.create((observer: Observer<GeocodeResult[]>) => {

      if (isNullOrUndefined(this.geocoder)) {
        observer.next(null);
      } else {
        this.geocoder.geocode(
          {'address': address},
          (results: any[], status: string) => {
            this.checkResultStatus(status);

            if (status === 'OK' && results.length > 0) {
              const res: GeocodeResult[] = results.map((x: any) => {
                const georesult: GeocodeResult = new GeocodeResult();
                georesult.FormattedAddress = results[0].address_components;
                georesult.Latitude = x.geometry.location.lat();
                georesult.Latitude = x.geometry.location.lng();
                georesult.RawResult = JSON.stringify(x);
                return georesult;
              });
              observer.next(res);
            }
          });
      }
    });
  }

  /***
   * This method transforms a LatLngLiteral to an address object.
   * An Observable is used since this transformation is made on a google maps
   * service request and is subject to a Promise.
   * @param {LatLngLiteral} latlng
   * @returns {Observable<any>}
   */
  ReverseGeocode(latlng: any): Observable<ReverseGeocodeResult[]> {
    return this.backendGeocodingService
      .getReveregeocode(latlng.lat, latlng.lng, {showSpinner: false})
      .map((result: WebServiceResponse) => {
        if (!result.error) {
          return result.result as ReverseGeocodeResult[];
        }

        return null;
      });
  }

  /***
   * This method transforms a LatLngLiteral to an address object.
   * An Observable is used since this transformation is made on a google maps
   * service request and is subject to a Promise.
   * @param {LatLngLiteral} latlng
   * @returns {Observable<any>}
   */
  ReverseGeocodeInFrontend(latlng: any)
    : Observable<ReverseGeocodeResult[]> {
    return Observable.create((observer: Observer<ReverseGeocodeResult[]>) => {

      if (isNullOrUndefined(this.geocoder)) {
        observer.next(null);
      } else {
        this.geocoder.geocode({'location': latlng}, (results, status) => {
          this.checkResultStatus(status);
          if (status === 'OK' && results.length > 0) {
            const res: ReverseGeocodeResult[] = results.map((x: any) => {
              const georesult: ReverseGeocodeResult = new ReverseGeocodeResult();
              georesult.FormattedAddress = x.address_components;
              georesult.RawResult = JSON.stringify(x);
              return georesult;
            });
            observer.next(res);
          }
        });
      }

    });
  }

  private checkResultStatus(status: string): void {
    switch (status) {
      case 'OVER_QUERY_LIMIT':
        throw new Error('Cannot run test since you have exceeded your Google API query limit.');
      case 'INVALID_REQUEST':
        throw new Error('Invalid request.');
      case 'REQUEST_DENIED':
        throw new Error('Request denied.');
    }
  }
}
