<!--datos-->
<ng-container *ngIf="!isNullOrUndefined(data) && data?.length; else noContent">
  <div *ngFor="let rowResult of data;" [ngClass]="resolveRowClasses(rowResult)" (click)="checkOperationsOnRow(rowResult)">
    <div *ngIf="checkImageClass(rowResult)"></div>
    <div class="card">
      <div *ngFor="let pos of cardPositions" [id]="'cardboard-position-' + pos" [ngClass]="pos">
        <ng-container *ngFor="let field of fields">
          <ng-container *ngIf="CheckFieldInPosition(pos, field)">
            <app-view-grid-cell-bulk-operations
              [fieldInclude]="field"
              [rowData]="rowResult"
              *ngIf="checkFieldType(rowResult, field) == ViewResultCellType.BulkOperation">
            </app-view-grid-cell-bulk-operations>
            <div [ngClass]="resolveFieldClasses(field, rowResult)">
              <div class="card-align">
                <div app-view-grid-header *ngIf="checkShowHeaderTitles()"
                                      [headerField]="field">
                </div>
                <app-view-grid-cell *ngIf="checkFieldType(rowResult, field) == ViewResultCellType.Simple"
                                    [viewModeUserColumn]="field" [rowData]="rowResult">
                </app-view-grid-cell>
              </div>
              <app-singleitemoperations2 [row]="rowResult" [viewModeUserColumn]="field"
                                         *ngIf="checkFieldType(rowResult, field) == ViewResultCellType.SingleItemOperation">
              </app-singleitemoperations2>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #noContent>
  <div *ngIf="!isNullOrUndefined(data)">
    <div [attr.colspan]="fields.length">
      <div class="text-center">{{ 'No hay registros' }}</div>
    </div>
  </div>
  <div *ngIf="isNullOrUndefined(data)">
    <div [attr.colspan]="2">
      <div class="text-center">Cargando...</div>
    </div>
  </div>
</ng-template>
