<div class="chart-container"
     (appOnWindowResize)="onNgxChartResizedWithDirective()"
     (window:resize)="onNgxChartResized($event)"
     resizeWindowDebounceTime="500">
  <ngx-charts-area-chart
      [results]="data"
      [gradient]="displayOptions?.Gradient"
      [xAxis]="!displayOptions?.HideXAxis"
      [yAxis]="!displayOptions?.HideYAxis"
      [legend]="getLegendDisplayOptions()?.ShowLegend"
      [legendTitle]="getLegendDisplayOptions()?.LegendTitle"
      [legendPosition]="getLegendDisplayOptions()?.LegendPosition"
      [showXAxisLabel]="!displayOptions?.HideXAxisLabel"
      [showYAxisLabel]="!displayOptions?.HideYAxisLabel"
      [xAxisLabel]="displayOptions?.XAxisLabel"
      [yAxisLabel]="displayOptions?.YAxisLabel"
      [tooltipDisabled]="displayOptions?.TooltipDisabled"
      [scheme]="colorScheme"
      [maxXAxisTickLength]="displayOptions?.MaxXAxisTickLength"
      [trimYAxisTicks]="displayOptions?.TrimYAxisTicks"
      [timeline]="displayOptions?.TimeLine"
      [view]="view"
      #chart>
  </ngx-charts-area-chart>
</div>
