import { Component } from '@angular/core';
import { ViewFilterTriggerComponent } from '../../../view-headers-shared/filters/components/view-filter-trigger.component';

@Component({
  selector: 'app-view-filter-material',
  templateUrl: './view-filter-material.component.html',
  styleUrls: ['./view-filter-material.component.scss']
})
export class ViewFilterMaterialComponent extends ViewFilterTriggerComponent {
}
