import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '../../core/navigation/navigation.service';
import { BackNavigateOptions } from '../../core/navigation/object-navigation';
import { DestroyableObjectTrait } from '../utils/destroyableobject.trait';
import { isNullOrUndefined } from '../utils/typescript.utils';
import { StyleConstants } from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import { BackButton } from './interface/back-button.interface';
import { AppContextService } from '../context/context.service';
import { IBackButtonOptions } from './interface/back-button-options.intefrace';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html'
})

/**
 * Componente que construye una botonera con el botón volver
 */
export class BackButtonComponent extends DestroyableObjectTrait implements OnInit, OnChanges {

  @Input() relativePath = '..';

  @Input() pathToGo;

  @Input() skipParametricNode;

  @Input() backButtonOptions: IBackButtonOptions;

  formParams: any = {};

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private navigationService: NavigationService) {

    super();

    this.formParams = {
      buttonText: 'Volver',
      buttonClass: StyleConstants.BTN_SECONDARY
    };
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: any): void {
    if (this.pathToGo && this.relativePath !== '..') {
      throw new Error('relativePath and pathToGo are not compatible in <app-back-button> tag');
    }
  }

  /**
   * Al recibir el evento se llama al servicio de navegación para volver a la página anterior.
   * Con el input relativePath podemos customizar la página de retorno
   * @param e
   */
  backToList(e: any): void {
    if (!isNullOrUndefined(this.pathToGo)) {
      this.router.navigateByUrl(this.pathToGo);
      return;
    }
    if (!isNullOrUndefined(this.activatedRoute.snapshot.queryParams) && Object.keys(this.activatedRoute.snapshot.queryParams).findIndex(x => x === 'backToUri') !== -1) {
      const defaultArguments: { [key: string]: any } = {};

      Object.keys(this.activatedRoute.snapshot.params).forEach(element => {
        defaultArguments[`%${element}`] = this.activatedRoute.snapshot.params[element];
      });

      this.navigationService.navigateUrlByController(this.activatedRoute.snapshot.queryParams['backToUri'], defaultArguments);
      return;
    }
    if (!isNullOrUndefined(this.backButtonOptions)) {
      if (!isNullOrUndefined(this.backButtonOptions.backButton)) {
        const defaultArguments: { [key: string]: any } = {};
        if (!isNullOrUndefined(this.backButtonOptions.backButton.arguments)) {
          Object.keys(this.backButtonOptions.backButton.arguments).forEach(element => {
            defaultArguments[`%${element}`] = this.activatedRoute.snapshot.params[element];
          });
        }
        this.navigationService.navigateUrlByController(this.backButtonOptions.backButton.controller, defaultArguments);
        return;
      }
      this.skipParametricNode = this.backButtonOptions.skipWithBackButton;
    }
    if (this.skipParametricNode) {
      this.navigationService.back({skipParametricNode: true});
      return;
    }
    this.navigationService.back({
      relativePath: [this.relativePath],
      activatedRoute: this.activatedRoute
    } as BackNavigateOptions);
  }
}
