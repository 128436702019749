<div [title]="'Añade o quita columnas al listado' | t">
  <button mat-button (click)="showColumnConfiguration()">
    <i class="fas fa-cogs"></i>
    {{'Añadir / quitar columnas' | t }}
  </button>
</div>
<ng-template #columnsModal>
  <app-view-configuration-selector (close)="modalRef.doClose()">
  </app-view-configuration-selector>
</ng-template>
