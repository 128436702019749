<div *ngIf="isCheckboxes()">
  <app-formelementinputlabel [config]="config" [group]="group">
  </app-formelementinputlabel>
</div>
<div [formGroup]="group">
  <div *ngIf="isCheckbox()">
    <app-formelementinputlabel [config]="config" [group]="group">
    </app-formelementinputlabel>
  </div>
  <app-input-check-simple *ngIf="isCheckbox()"
                          [config]="config"
                          [group]="group"
                          [formControlName]="config.name"
                          #formInput>
  </app-input-check-simple>
  <app-formelementinputdescription [config]="config" [group]="group">
  </app-formelementinputdescription>
  <div class="apply_col_style">
    <app-input-check-multiple *ngIf="isCheckboxes()"
                              [config]="config"
                              [group]="group"
                              [formControlName]="config.name"
                              #formInput2>
    </app-input-check-multiple>
  </div>
  <app-formelementinput-validationmessages [config]="config" [group]="group">
  </app-formelementinput-validationmessages>
</div>
