import { Injectable, isDevMode, NgZone, Optional, SkipSelf, TemplateRef, Type } from '@angular/core';
import { BehaviorSubject, defer, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CommandService } from '../../core/commands/command.service';
import { IResultCollector } from '../../core/commands/resultcollector.interface';
import { BatchTaskInfo, CoreModalConfirmMessageCommand, DtoFrontendModal } from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import { IFrontendFormElement } from '../form/interfaces/field.interface';
import { backendTypeMatch, isNullOrUndefined, isNullOrWhitespace } from '../utils/typescript.utils';
import { BatchDialogComponent } from './components/batch-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog.component';
import { ErrorDialogComponent } from './components/error-dialog.component';
import { FormDialogComponent } from './components/form-dialog.component';
import { DecoupledModalBridgeInterface, ModalReference } from './models/decoupled-modal-bridge.interface';
import { DecoupledModalComponentInterface } from './models/decoupled-modal-component.interface';
import {
  BaseModalParamsInterface,
  BatchDialogInterface,
  ComponentDialogInterface,
  ConfirmDialogInterface,
  ConfirmDialogLabels,
  ErrorDialogInterface,
  FormDialogInterface
} from './models/modal-params.interface';
import { EventFormSucceededInterface } from '../form/event-form-succeeded.interface';
import { Guid } from 'guid-typescript';
import { WindowRef } from '../utils/browser-globals';

@Injectable({providedIn: 'root'})
export class DecoupledModalBridgeService
  implements DecoupledModalBridgeInterface {

  /**
   * Subject to emit to show a modal.
   */
  private onShowComponent = new Subject<BaseModalParamsInterface>();

  /**
   * Current active modal service.
   */
  private currentService: Type<any>;

  /**
   * Referencia a todas las modales abiertas. Se introdujo para poder
   * cerrar cualquier modal abierta durante la ejecución de los tests
   * e2e
   *
   * @private
   */
  private modals: ModalReference<any>[] = [];

  /**
   * DecoupledModalBridgeService class constructor.
   *
   * @param {DecoupledModalBridgeService} parentModule
   */
  constructor(
    commandService: CommandService,
    windowRef: WindowRef,
    ngZone: NgZone,
    @Optional() @SkipSelf() parentModule?: DecoupledModalBridgeService
  ) {
    // Protección para garantizar que esto está inyecto como SINGLETON
    if (parentModule) {
      throw new Error(
        'DecoupledModalBridgeService is already loaded. Import it in the AppModule only');
    }

    this.registerCommands(commandService);

    if (isDevMode()) {
      console.log('👽 Inicializando DecoupledModalBridgeService');
    }

    // Esto es un "hack" para los tests e2e, ver link: https://github.com/angular/protractor/issues/3911
    const window: any = windowRef.getNativeWindow();
    window.protractorCloseAllModalBridgeModals = (serverCallback): Promise<any> => {
      console.debug('window.protractorCloseAllModalBridgeModals called');
      return ngZone.run(() => {
        return this.closeAllModals();
      })
        // Este serverCallback es para sincronizar esto con el webdriver de protractor/selenium
        // ya que ngZone.run() no es síncrono
        .then(() => {
          serverCallback();
        });
    };
  }

  /**
   *
   * Cierra todas las modales que hay abiertas actualmente
   *
   */
  closeAllModals(): Promise<void> {
    // Importante quitar las modales en orden inverso al que se añaden, sino
    // se quedan las intancias colgadas???
    this.modals
      .reverse()
      .forEach((modal) => {
        modal.doClose(null);
      });
    return Promise.resolve();
  }

  /**
   * Register commands for the command service
   *
   * @param commandService
   */
  private registerCommands(commandService: CommandService): void {

    // Comando de diálogo de confirmación
    commandService.CommandObservable
      .pipe(filter(obj => backendTypeMatch(CoreModalConfirmMessageCommand.$type, obj.Argument)))
      .subscribe((next: IResultCollector<CoreModalConfirmMessageCommand, (() => Promise<boolean>) | Observable<boolean>>) => {
        const modalRef: ModalReference<unknown> = this.showConfirm<unknown>(
          {
            message1: next.Argument['Message'],
            NoLabel: next.Argument.NoLabel,
            YesLabel: next.Argument.YesLabel,
            notVisibleNo: next.Argument.HideNoButton,
            notVisibleYes: next.Argument.HideYesButton,
          },
          {
            Title: next.Argument.Title,
            ModalType: next.Argument.ModalType,
            ModalSize: next.Argument.ModalSize,
            CssClasses: next.Argument.CssClasses,
            HideClose: next.Argument.HideClose,
            HideHeader: next.Argument.HideHeader,
          }
        );

        next.AddResult(defer(() => {
          return modalRef.close
            .pipe(map((i) => {
              return !!i;
            }));
        }));
      });
  }

  /**
   * @inheritdoc
   */
  get onShowComponent$(): Observable<BaseModalParamsInterface> {
    return this.onShowComponent.asObservable();
  };

  /**
   * @inheritdoc
   */
  register(service: Type<any>): void {
    this.currentService = service;
  }

  /**
   * @inheritdoc
   */
  show<T>(data: BaseModalParamsInterface): ModalReference<T> {
    const onInstance: Subject<DecoupledModalComponentInterface>
      = new BehaviorSubject<DecoupledModalComponentInterface>(null);

    if (isNullOrWhitespace(data.id)) {
      data.id = Guid.create().toString();
    }

    // A cada modal le asignamos un GUID único
    const modalGuid: string = data.id;

    const onClose: Subject<any> = new Subject<any>();

    this.onShowComponent.next({
      ...data,
      service: this.currentService,
      onInstance: (ref: DecoupledModalComponentInterface) => {
        onInstance.next(ref);
      },
      onClose: (result: any) => {
        onInstance.complete();
        onClose.next(result);
        onClose.complete();
      }
    });

    const instance$: Observable<DecoupledModalComponentInterface> = onInstance.asObservable()
      .filter(i => !isNullOrUndefined(i))
      .take(1);

    const close$: Observable<T> = onClose.asObservable().take(1);

    const modalReference: ModalReference<T> = {
      id: data.id,
      instance$,
      close$,
      close: close$,
      doClose: (params) => instance$.subscribe(component => {
        this.modals = this.modals.filter((i) => i.id !== modalGuid);
        component.closeModal(params);
      })
    };

    this.modals.push(modalReference);
    return modalReference;
  }

  /**
   * @inheritdoc
   */
  showTemplate<T>(
    template: TemplateRef<any>,
    config: Partial<DtoFrontendModal>,
    params?: any
  ): ModalReference<T> {
    return this.show<T>({
      component: template,
      config,
      initialState: {
        ...params
      }
    } as ComponentDialogInterface);
  }

  /**
   * @inheritdoc
   */
  showComponent<T>(
    component: Type<DecoupledModalComponentInterface>,
    config: Partial<DtoFrontendModal>,
    params?: any
  ): ModalReference<T> {
    return this.show<T>({
      component,
      config,
      initialState: {
        ...params
      }
    } as ComponentDialogInterface);
  }

  /**
   * @inheritdoc
   */
  showForm(
    plugin: string,
    configuration: Partial<DtoFrontendModal>,
    parameters?: Record<string, any>,
    dynamicComponentBindings?: Record<string, Type<IFrontendFormElement>>
  ): ModalReference<EventFormSucceededInterface> {
    // We pass an additional parameter isModal to indicate this form is
    // shown on a modal window.
    return this.show<EventFormSucceededInterface>({
      component: FormDialogComponent,
      config: configuration,
      initialState: {
        dynamicComponentBindings: dynamicComponentBindings || {},
        formPlugin: plugin,
        params: {isModal: true, ...parameters},
      }
    } as FormDialogInterface);
  }

  /**
   * @inheritdoc
   */
  showError<T>(
    body: string,
    configuration: Partial<DtoFrontendModal>
  ): ModalReference<T> {
    return this.show<T>({
      component: ErrorDialogComponent,
      config: configuration,
      initialState: {
        body,
      }
    } as ErrorDialogInterface);
  }

  /**
   * @inheritdoc
   */
  showConfirm<T>(
    labels: Partial<ConfirmDialogLabels>,
    configuration: Partial<DtoFrontendModal>
  ): ModalReference<T> {
    return this.show<T>({
      initialState: {
        ...labels
      },
      component: ConfirmDialogComponent,
      config: {
        HideHeader: !configuration.Title || configuration.HideHeader,
        HideClose: configuration.HideClose || (isNullOrUndefined(configuration.HideClose) && true),
        Title: configuration.Title,
        CssClasses: configuration.CssClasses,
        ...(configuration || {})
      }
    } as ConfirmDialogInterface)
  }

  /**
   * @inheritdoc
   */
  showBatch<T>(
    task: BatchTaskInfo,
    configuration: Partial<DtoFrontendModal>,
    params?: Record<string, any>
  ): ModalReference<T> {
    return this.show<T>({
      component: BatchDialogComponent,
      config: configuration,
      initialState: {
        task,
        ...params
      }
    } as BatchDialogInterface);
  }
}
