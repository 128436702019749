<div style="border: 1px solid #cccccc; text-align: center; padding: 10px; display: flex; flex-direction: column">
  <!-- Carga de archivo -->
  <app-file-dropable
      *ngIf="canUploadFiles()"
      [uniqueId]="uniqueComponentId('fileinput-')"
      (onFilesLoaded)="onUploadFilesLoaded($event)"
      (onOverError)="onOverError($event)"
      [multiple]="false"
      [validExtensions]="this.config.validExtensions"
      [buttonLabel]="this.formElement.ButtonLabel"
      [enableDragAndDrop]="this.formElement.EnableDragAndDrop"
      [validSize]="this.config.validSize"
      [maxFiles]="this.config.maxFiles"
      [currentFiles]="files?.length"
      [disabled]="isDisabled">
  </app-file-dropable>
  <!-- Resultado -->
  <div *ngIf="files?.length == 1" style="padding-top: 7px">
    <ng-container *ngIf="file?.UploadedFile;then linkWhenUploaded else linkWhileUploading"></ng-container>
    <ng-container *ngTemplateOutlet="progressBar"></ng-container>
    <ng-container *ngTemplateOutlet="status"></ng-container>
    <ng-container *ngIf="!isDisabled">
      <ng-container *ngTemplateOutlet="actions"></ng-container>
    </ng-container>
  </div>
  <ng-container *ngTemplateOutlet="validations"></ng-container>
</div>

<!-- Templates -->

<ng-template #linkWhenUploaded>
  <img [src]="getLink(file?.UploadedFile)" [ngStyle]="imageStyle()"/>
</ng-template>
<ng-template #linkWhileUploading>
  <span *ngIf="file?.getStatus() === uploadStatus.STARTED">{{'Subiendo archivo' }} {{file?.name}}
    ... {{'Por favor espera.' | t }}</span>
  <span *ngIf="file?.getStatus() === uploadStatus.WAITING">{{'Archivo preparado' | t }} {{file?.name}}</span>
</ng-template>

<ng-template #progressBar>
  <div *ngIf="file.getStatus() != uploadStatus.COMPLETED" class="progress" style="margin-bottom: 0;">
    <div class="progress-bar progress-bar-striped active" role="progressbar"
         [ngStyle]="{ 'width': file?.progress  + '%' }"></div>
  </div>
</ng-template>
<ng-template #status>
  <div *ngIf="file.getStatus() == uploadStatus.WAITING" class="upload-status">
    <span>{{ 'Pendiente' | t }}</span>
  </div>
  <div *ngIf="file.getStatus() == uploadStatus.PAUSED" class="upload-status">
    <span>{{ 'En pausa' | t }}</span>
  </div>
  <div *ngIf="file.getStatus() == uploadStatus.CANCELED" class="upload-status">
    <span>{{ 'Cancelado' | t }}</span>
  </div>
  <div *ngIf="file.getStatus() == uploadStatus.STARTED" class="upload-status">
    <span>{{ 'Cargando' | t }}</span>
  </div>
  <div *ngIf="file.getStatus() == uploadStatus.COMPLETED" class="upload-status">
    <span>{{ 'Completado' | t }}</span>
  </div>
  <div *ngIf="file.getStatus() == uploadStatus.ERROR" class="upload-status">
    <span> {{ 'Error' | t }}</span>
  </div>
</ng-template>
<ng-template #actions>
  <div style="display: flex; flex-direction: row">
    <button
        class="o-btn__accent" (click)="file.upload()"
        *ngIf="file.getStatus() == uploadStatus.WAITING || file.getStatus() == uploadStatus.PAUSED">
      <span> {{ "Iniciar carga" | t }}</span>
    </button>
    <button
        class="o-btn__secondary" (click)="removeFile(file)"
        *ngIf="[uploadStatus.COMPLETED, uploadStatus.CANCELED, uploadStatus.ERROR, uploadStatus.WAITING].includes(file.getStatus())">
      <span> {{ "Eliminar" | t }}</span>
    </button>
    <button
        class="o-btn__danger" (click)="cancel(file)"
        *ngIf="file.getStatus() == uploadStatus.STARTED || file.getStatus() == uploadStatus.PAUSED">
      <span> {{ "Cancelar" | t }}</span>
    </button>
  </div>
</ng-template>
<ng-template #validations>
  <div class="control-validations" [ngClass]="{ 'over-drop-error': overError }">
    <small *ngIf="config.validSize">
      {{ ('El tamaño máximo por archivo es de @filesize' | t: {'@filesize': formatFileSize(config?.validSize)})  }}
    </small>
    <br>
    <small *ngIf="config.validExtensions?.length > 0">
      {{('Las extensiones válidas de archivo son: @extensions ' | t :{'@extensions': validExtensions()}) }}.
    </small>
    <br>
    <small *ngIf="config.maxFiles > 1">
      {{ 'Puedes agregar hasta @filecount archivo/s más.' | t :{'@filecount': (config.maxFiles - files.length)} }}
    </small>
    <ng-container *ngIf="errorDimensions && errorDimensions.length > 0">
      <div style="display: flex; flex-direction: column">
        <ng-container *ngFor="let item of errorDimensions">
          <small style="color: red">
            {{ item }}
          </small>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>
