<div *ngIf="listComponentService.getConfiguration() && listComponentService.getUserConfiguration() && !hidePager"
     class="lazy-pager">
  {{"Mostrar filas:" | t }}
  <input
      class="page-input"
      type="number"
      [(ngModel)]="pageSize"
      [ngModelOptions]="{standalone: true}"
      [min]="1"
      [max]="maxSize"
      step="1"
      (keydown.enter)="keydownHandler($event)"
      (keyup.escape)="clearSearch()"
      (input)="this.pageSizeChanged.next()"
      autocomplete="off"
      #inputElement>
  <span>{{rangeStart}} - {{rangeEnd}}</span>

  <span *ngIf="this.length != NoLengthLengthNumber && this.length != LoadingLengthNumber"> de {{this.length}}</span>
  <span *ngIf="this.length == LoadingLengthNumber"> de ...</span>

  <ul class="pager-navigate">
    <li *ngIf="fullMode"
        class="first-page"
        [ngClass]="{'is-active':!isFirstPage()}" (click)="!isFirstPage() && goToFirst()">{{"Primera" | t }}</li>
    <li
        class="previous-page"
        [ngClass]="{'is-active':!isFirstPage()}"
        (click)="!isFirstPage() && goToPrevious()">{{"<"}}</li>
    <li
        class="next-page"
        [ngClass]="{'is-active':!isLastPage()}"
        (click)="!isLastPage() && goToNext()">{{">"}}</li>
    <li *ngIf="fullMode"
        class="last-page"
        [ngClass]="{'is-active':!isLastPage()}"
        (click)="!isLastPage() && goToLast()">{{"Última" | t }}</li>
    <li class="execute-count">
      <span (click)="toogleFullMode(!this.fullMode)"><span *ngIf="!fullMode">+</span><span
          *ngIf="fullMode">-</span></span>
    </li>
  </ul>

  <button
      mat-icon-button
      class="load-count-button"
      [style.visibility]="isLoadCountButtonVisible ? 'visible' : 'hidden'"
      [matTooltip]="'Contar total de registros' | t"
      (mouseover)="mouseOverHandler()"
      (click)="loadCount()">
    <mat-icon>restore_page</mat-icon>
  </button>
</div>
