import { Component, ElementRef, forwardRef, QueryList, ViewChildren } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FrontendFormElementInput } from '../../formelementinput.class';
import { FormElementOption } from '../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { getInSafe, isNullOrUndefined } from '../../../../utils/typescript.utils';

/**
 * Input type radio component
 */
@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioComponent), multi: true},
    {provide: NG_VALIDATORS, useExisting: forwardRef(() => RadioComponent), multi: true}
  ]
})
export class RadioComponent extends FrontendFormElementInput {

  protected valueValue: any;
  @ViewChildren('radioElement') radioElement: QueryList<ElementRef>;


  /**
   * Usamos esto para identificar a la opción sin valor
   */
  noOptionValue = 'nooption';

  /**
   *
   * @param value
   */
  set value(value: any) {
    this.valueValue = value;
    this.propagateChange(value);
  }

  get value(): any {
    return this.valueValue;
  }

  /**
   * Cada opción tiene una clave única combiación.
   *
   * @param option
   */
  getOptionUniqueKey(option: FormElementOption): string {
    return this.uniqueComponentId(option.Key + '_');
  }

  writeValue(obj: any): void {
    this.valueValue = obj;
    this.forceDetectChanges();
  }

  /**
   * @inheritDoc
   */
  focusInput(): boolean {
    if (isNullOrUndefined(this.radioElement)) {
      return false;
    }

    const elementsRef: ElementRef[] = this.radioElement.toArray();
    const radioElementChosen: ElementRef = elementsRef.find(i => i.nativeElement.id === this.uniqueComponentId(this.valueValue + '_'));
    if (isNullOrUndefined(radioElementChosen)) {
      elementsRef[0].nativeElement.focus()
      return true;
    } else {
      radioElementChosen.nativeElement.focus();
      return true;
    }
  }

  get selected(): string {

    if (isNullOrUndefined(this.valueValue) || this.valueValue === '') {

      return this.noOptionValue;

    }

    return this.valueValue;

  }

}
