<div class="view-search-form-search-and-filter" fxFlex="100"
     *ngIf="this.configuration.QuickSearch !== null && this.configuration.QuickSearch !== undefined">

  <div class="filters" fxLayout="column" fxLayoutAlign="center baseline"
       fxFlex="100">

    <mat-form-field appearance="legacy" fxFlex="100"
                    fxFill
                    (click)="changeFocus(true)"
                    (appClickOutside)="changeFocus(false)"
                    [floatLabel]="getFloatLabelValue()">
      <mat-label>{{'Buscar' | t}}</mat-label>
      <mat-icon matSuffix class="secondary-text" fxHide>search</mat-icon>
      <div fxLayout="row">
        <input matInput type="search"
               class="t-quicksearch t-quicksearch-input"
               [placeholder]="getPlaceHolder()"
               (click)="changeFocus(true)"
               (appClickOutside)="changeFocus(false)"
               (keyup)="searchChange($event);"
               (keypress)="preventEventPropagation($event);"
               [(ngModel)]="searchString">
        <div class="c-form-search-and-filter__side" (appClickOutside)="clickOutsideHandler($event)" fxHide.xs
             title="Haz click sobre el desplegable, para activar o desactivar los criterios de búsqueda.
          El buscador sólo te permite filtrar por los criterios seleccionados.">
          <div class="dropdown" [ngClass]="{'open': showFilters}">
            <mat-icon (click)="toggleDropdown($event)" class="secondary-text" style="cursor: pointer">filter_list
            </mat-icon>
            <ul class="dropdown-menu">
              <li>
                <div class="fix-dropdown-checkbox">
                  <div *ngFor="let field of fields" class="c-radio-checkbox">
                    <input
                        type="checkbox"
                        [id]="field.FieldId"
                        class="o-checkradio"
                        [checked]="isChecked(field.FieldId)"
                        (change)="inputChanged($event)">
                    <label [for]="field.FieldId">{{ field.Name }}</label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </mat-form-field>
  </div>


</div>
