import { Component, HostBinding, OnInit } from '@angular/core';
import { FrontendFormElementWrapper } from '../formelementwrapper.class';
import { FrontendFormElementInput } from '../formelementinput.class';

@Component({
  selector: 'app-formelementsecret',
  templateUrl: './secret.component.html'
})
export class SecretComponent extends FrontendFormElementWrapper implements OnInit {

  @HostBinding('class')
  get getClasses(): string { // use getter to reflect external value
    return 'hidden';
  }

  formElementInstance(): FrontendFormElementInput {
    throw new Error('Not supported.');
  }

  ngOnInit(): void {
  }
}
