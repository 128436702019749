import { Injectable, Optional, SkipSelf } from '@angular/core';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { CommandService } from '../../core/commands/command.service';
import { IResultCollector } from '../../core/commands/resultcollector.interface';
import { DecoupledModalBridgeService } from '../decoupled-modal/decoupled-modal-bridge.service';
import { ModalReference } from '../decoupled-modal/models/decoupled-modal-bridge.interface';
import {
  asIterableObject,
  backendTypeMatch,
  getInSafe,
  isNullOrUndefined,
  UtilsTypescript
} from '../utils/typescript.utils';
import { DestroyableObjectTrait } from '../utils/destroyableobject.trait';
import { ModalBatchItemComponent } from './modal-batch-item/modal-batch-item.component';
import {
  BatchStatusDictionary,
  BatchTaskInfo,
  BatchTaskResultFileRef,
  BatchTaskResultUploadedFiles,
  BatchTaskResultWordAndPdfUploadedFile,
  CoreBatchActionEnum,
  CoreBatchActionTaskCommand,
  CoreBatchScheduleTaskCommand,
  CoreBatchSeeProgressCommand,
  CurrentExecutionInfo,
  CurrentExecutionStatus,
  DtoFrontendModalType,
  IBatchTaskResult,
  ICommand,
  UploadedFile,
  WebServiceResponse,
  WebServiceResponseTyped
} from '../../core/models/ETG_SABENTISpro_Application_Core_models';
import * as moment from 'moment';
import { BatchResultButton } from './result-button.class';
import { Observable } from 'rxjs/Rx';
import { TranslatorService } from '../../core/translator/services/rest-translator.service';
import { FileDownloaderService } from '../files/file-downloader.service';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { BatchService } from '../../core/services/ETG_SABENTISpro_Application_Core_batch.service';
import { CoreBatchReuseModeEnum } from '../../core/models/ETG_SABENTISpro_Models_models';
import { Subject } from 'rxjs/Subject';

/**
 * Servicios globales de la API de Batch (i.e. listener para los comandos)
 */
@Injectable({
  providedIn: 'root',
})
export class BatchGlobalServicesClass extends DestroyableObjectTrait {

  /**
   * FormGlobalServicesClass class constructor.
   */
  constructor(
    protected translatorService: TranslatorService,
    protected dmbs: DecoupledModalBridgeService,
    protected commandService: CommandService,
    protected batchService: BatchService,
    protected fileDownloaderService: FileDownloaderService,
    @Optional() @SkipSelf() parentModule?: BatchGlobalServicesClass
  ) {

    super();

    if (parentModule) {
      throw new Error(
        'FormGlobalServicesClass is already loaded. Import it in the AppModule only');
    }

    this.commandService.CommandObservable
      .pipe(
        filter(obj => backendTypeMatch(CoreBatchSeeProgressCommand.$type, obj.Argument)),
        map((obj) => obj as IResultCollector<CoreBatchSeeProgressCommand, (() => Promise<boolean>) | Observable<boolean>>),
      ).subscribe(
      (next) => {
        next.AddResult(this.OpenModal(next.Argument.Job, next.Argument.OnCloseCommands))
      });

    this.commandService.CommandObservable
      .pipe(
        filter(obj => backendTypeMatch(CoreBatchScheduleTaskCommand.$type, obj.Argument)),
        map((obj) => obj as IResultCollector<CoreBatchScheduleTaskCommand, () => Promise<boolean>>),
      ).subscribe((next) => {
      next.AddResult(() => new Promise(() => {
        let args: any = next?.Argument?.Arguments ?? null;
        if (typeof args !== 'string') {
          args = JSON.stringify(args);
        }
        if (next.Argument.UniqueForCurrentPerson) {
          return this.batchService.postScheduletask(next.Argument.PluginId, args, CoreBatchReuseModeEnum.ReuseByInstance, {showSpinner: true})
            .pipe(
              takeUntil(this.componentDestroyed$)
            ).subscribe(x => {
              if (!next.Argument.HideProgressOnSchedule) {
                return new Promise(() => this.OpenModal(x.result, next.Argument.OnCloseCommands));
              }
              return this.commandService.executeCommandChain(Object.values(next.Argument.OnCloseCommands));
            });
        }
        return this.batchService.postScheduletask(next.Argument.PluginId, args, CoreBatchReuseModeEnum.ReuseByInstanceAndUser, {showSpinner: true})
          .pipe(
            takeUntil(this.componentDestroyed$)
          ).subscribe(x => {
            if (!next.Argument.HideProgressOnSchedule) {
              return new Promise(() => this.OpenModal(x.result, next.Argument.OnCloseCommands));
            }
            return this.commandService.executeCommandChain(Object.values(next.Argument.OnCloseCommands));
          });
      }));
    });

    this.commandService.CommandObservable
      .pipe(
        filter(obj => backendTypeMatch(CoreBatchActionTaskCommand.$type, obj.Argument)),
        map((obj) => obj as IResultCollector<CoreBatchActionTaskCommand, () => Promise<boolean>>),
      ).subscribe((next) => {
      next.AddResult(() => new Promise(() => {
        return this.batchService.postTaskaction(next.Argument.QueueId, next.Argument.Action)
          .pipe(
            takeUntil(this.componentDestroyed$)
          ).subscribe(x => {
            return this.commandService.executeCommandChain(Object.values(next.Argument.OnCloseCommands));
          });
      }));
    });
  }

  public OpenModal(task: BatchTaskInfo, onCloseCommands: { [key: string]: ICommand }): Observable<boolean> {

    const ref: ModalReference<unknown> = this.dmbs.showComponent(
      ModalBatchItemComponent,
      {CssClasses: ['batch-item-modal'], ModalType: DtoFrontendModalType.Modal},
      {
        params: {
          Job: task,
        }
      }
    );

    const closeModal: Subject<boolean> = new Subject<boolean>();

    ref.close
        .pipe(
            takeUntil(this.componentDestroyed$),
            switchMap(() => {
              if (!onCloseCommands) {
                return Observable.fromPromise(Promise.resolve(true));
              }

              return Observable.fromPromise(this.commandService.executeCommandChain(Object.values(onCloseCommands)));
            })
        )
        .subscribe(result => {
          closeModal.next(result);
          closeModal.complete();
        });

    return closeModal.asObservable();
  }

  /**
   * Retrieve the Current Execution info for a task
   * @param task
   * @constructor
   */
  GetCurrentExecution(task: BatchTaskInfo): CurrentExecutionInfo {
    const execution: CurrentExecutionInfo = getInSafe(task, x => x.CurrentExecution, null);
    return execution;
  }

  /**
   * Get the task status
   * @param task
   * @constructor
   */
  GetTaskStatus(task: BatchTaskInfo): CurrentExecutionStatus {
    const execution: CurrentExecutionInfo = this.GetCurrentExecution(task);
    if (!execution || !execution.Status) {
      return null;
    }
    return execution.Status;
  }

  /**
   * Get the task status display text
   * @param task
   * @constructor
   */
  GetTaskStatusDisplayText(task: BatchTaskInfo): string {
    const execution: CurrentExecutionInfo = this.GetCurrentExecution(task);

    if (!execution) {
      return '-';
    }

    switch (execution.Status) {
      case CurrentExecutionStatus.PAUSED:
        return BatchStatusDictionary.PAUSED;
      case CurrentExecutionStatus.WAITING:
        return BatchStatusDictionary.WAITING;
      case CurrentExecutionStatus.RUNNING:
        return BatchStatusDictionary.RUNNING;
      case CurrentExecutionStatus.ERROR:
        return BatchStatusDictionary.ERROR;
      case CurrentExecutionStatus.COMPLETED:
        return BatchStatusDictionary.COMPLETED;
      case CurrentExecutionStatus.CANCELED:
        return BatchStatusDictionary.CANCELED;
      default:
        return '-';
    }
  }

  /**
   * Returns lastRun start time for task
   * @param task
   * @constructor
   */
  GetLastRun(task: BatchTaskInfo): string {
    const execution: CurrentExecutionInfo = this.GetCurrentExecution(task);

    if (!execution || !execution.LastRun) {
      return '-';
    }

    return moment(execution.LastRun * 1000)
      .locale(this.translatorService.getDefaultLang())
      .format('DD/MM/YYYY HH:mm:ss');
  }

  /**
   * Returns creation time for task.
   * @param task
   * @constructor
   */
  GetCreated(task: BatchTaskInfo): string {
    const execution: CurrentExecutionInfo = this.GetCurrentExecution(task);

    if (!execution || !execution.CreatedAt) {
      return '-';
    }

    return moment(execution.CreatedAt * 1000)
      .locale(this.translatorService.getDefaultLang())
      .format('DD/MM/YYYY HH:mm:ss');
  }

  /**
   * Returns creation time for task.
   * @param task
   * @constructor
   */
  GetStarted(task: BatchTaskInfo): string {
    const execution: CurrentExecutionInfo = this.GetCurrentExecution(task);

    if (!execution || !execution.StartedAt) {
      return '-';
    }

    return moment(task.CurrentExecution.StartedAt * 1000)
      .locale(this.translatorService.getDefaultLang())
      .calendar();
  }

  /**
   * Elapsed time for task.
   * @param task
   * @constructor
   */
  GetRunningTime(task: BatchTaskInfo): string {
    const execution: CurrentExecutionInfo = this.GetCurrentExecution(task);

    if (!execution || (!execution.CreatedAt)) {
      return '-';
    }
    let time: number = null;

    if (execution.StartedAt) {
      time = execution.StartedAt * 1000;
    } else {
      time = execution.CreatedAt * 1000;
    }

    if (!time) {
      return '-';
    }

    const seconds: number = moment()
      .locale(this.translatorService.getDefaultLang())
      .diff(moment(time), 'seconds');
    if (seconds < 60) {
      return seconds + ' ' + this.translatorService.get(((seconds === 1) ? 'segundo' : 'segundos'));
    }

    if (seconds > 3600) {
      const hours: number = moment()
        .locale(this.translatorService.getDefaultLang())
        .diff(moment(time), 'hours');

      const minutesWithoutHours: number = moment()
        .locale(this.translatorService.getDefaultLang())
        .diff(moment(time), 'minutes') - (hours * 60);

      return hours + ' ' + this.translatorService.get((hours < 2) ? 'hora' : 'horas') + ' ' + this.translatorService.get('y') + ' ' + minutesWithoutHours + ' ' + this.translatorService.get((minutesWithoutHours === 1) ? 'minuto' : 'minutos');
    }

    const minutes: number = moment()
      .locale(this.translatorService.getDefaultLang())
      .diff(moment(time), 'minutes');

    const secondsWithoutMinutes: number = moment()
      .locale(this.translatorService.getDefaultLang())
      .diff(moment(time), 'seconds') - (minutes * 60);

    return minutes + ' ' + this.translatorService.get(minutes < 2 ? 'minuto' : 'minutos') + ' ' + this.translatorService.get('y') + ' ' + secondsWithoutMinutes + ' ' + this.translatorService.get(secondsWithoutMinutes === 1 ? 'segundo' : 'segundos');
  }

  /**
   * Check if the task is in any running state
   * @param task
   * @constructor
   */
  TaskIsRunning(task: BatchTaskInfo): boolean {
    const execution: CurrentExecutionInfo = this.GetCurrentExecution(task);

    if (!execution) {
      return false;
    }

    switch (execution.Status) {
      case CurrentExecutionStatus.RUNNING:
      case CurrentExecutionStatus.WAITING:
        return true;
      default:
        return false;
    }
  }

  /**
   * Check if the task is in any running state
   * @param task
   * @constructor
   */
  TaskIsRunnable(task: BatchTaskInfo): boolean {
    if (this.TaskIsCompleted(task)) {
      return task.Reexecutable;
    }
    return !this.TaskIsRunning(task) && !this.TaskIsPaused(task);
  }

  /**
   * Check if the task is paused
   * @param task
   * @constructor
   */
  TaskIsPaused(task: BatchTaskInfo): boolean {
    return this.TaskIsInStatus(task, CurrentExecutionStatus.PAUSED)
  }

  /**
   * Check if the task is canceled
   * @param task
   * @constructor
   */
  TaskIsCanceled(task: BatchTaskInfo): boolean {
    return this.TaskIsInStatus(task, CurrentExecutionStatus.CANCELED)
  }

  /**
   * Check if the task is completed
   * @param task
   * @constructor
   */
  TaskIsCompleted(task: BatchTaskInfo): boolean {
    return this.TaskIsInStatus(task, CurrentExecutionStatus.COMPLETED)
  }

  /**
   * Check if the task is in error state
   * @param task
   * @constructor
   */
  TaskIsInErrorState(task: BatchTaskInfo): boolean {
    return this.TaskIsInStatus(task, CurrentExecutionStatus.ERROR)
  }

  /**
   * Check if the task is paused
   * @param task
   * @constructor
   */
  TaskIsInStatus(task: BatchTaskInfo, status: CurrentExecutionStatus): boolean {
    return getInSafe(task, x => x.CurrentExecution.Status === status, false);
  }

  /**
   * Start a task
   * @param pluginId
   * @param args
   * @constructor
   */
  StartTask(pluginId: string, serializedArgs: string): Promise<BatchTaskInfo> {
    return this.batchService.postScheduletask(pluginId, isNullOrUndefined(serializedArgs) ? null : serializedArgs)
      .pipe(
        takeUntil(this.componentDestroyed$),
        map((x: WebServiceResponseTyped<BatchTaskInfo>) => {
          if (x.result) {
            return x.result;
          } else {
            throw new Error('Unable to start the task')
          }
        })
      ).toPromise()
  }

  /**
   * Start a task
   * @param pluginId
   * @param args
   * @constructor
   */
  RestartTask(task: BatchTaskInfo): Promise<BatchTaskInfo> {
    return this.batchService.postScheduletask(task.PluginId, !isNullOrUndefined(task.Arguments) ? task.Arguments : null)
      .pipe(
        takeUntil(this.componentDestroyed$),
        map((x: WebServiceResponseTyped<BatchTaskInfo>) => {
          if (x.result) {
            return x.result;
          } else {
            throw new Error('Unable to start the task')
          }
        })
      ).toPromise()
  }

  /**
   * Resume a paused a task
   * @param pluginId
   * @param args
   * @constructor
   */
  ResumeTask(task: BatchTaskInfo): Promise<boolean> {
    if (!this.TaskIsPaused(task)) {
      return Promise.resolve<boolean>(false);
    }

    return this.DoTaskAction(task.QueueId, CoreBatchActionEnum.RESUME);
  }

  /**
   * Pause a running a task
   * @param pluginId
   * @param args
   * @constructor
   */
  PauseTask(task: BatchTaskInfo): Promise<boolean> {
    if (this.TaskIsPaused(task)) {
      return Promise.resolve<boolean>(false);
    }

    return this.DoTaskAction(task.QueueId, CoreBatchActionEnum.PAUSE);
  }

  /**
   * Cancel a running o paused a task
   * @param pluginId
   * @param args
   * @constructor
   */
  CancelTask(task: BatchTaskInfo): Promise<boolean> {
    if (this.TaskIsRunning(task) || this.TaskIsPaused(task)) {
      return this.DoTaskAction(task.QueueId, CoreBatchActionEnum.CANCEL);
    }
    return Promise.resolve<boolean>(false);
  }

  public GetTaskResultButtons(task: BatchTaskInfo, args?: any, contentDisposition?: 'inline' | 'attachment'): Observable<{ result: IBatchTaskResult, buttons: BatchResultButton[] }> {
    if (task.HasResult) {
      return this.batchService.postTaskresult(task.QueueId, contentDisposition, args)
        .pipe(
          catchError((i) => {
              if (i instanceof HttpErrorResponse) {
                // Acceso denegado....
                if (i.status === 403 || i.status === 401) {
                  const wsResponse: WebServiceResponse = getInSafe((i), (x) => x.error, null);
                  console.warn(wsResponse.error.message);
                  const buttons: BatchResultButton[] = []
                  return Observable.empty();
                }
              }
              return throwError(i);
            }
          ),
          map((result: WebServiceResponseTyped<IBatchTaskResult>) => {
              if (backendTypeMatch(BatchTaskResultFileRef.$type, result.result)) {
                const batchTaskResultFileRef: BatchTaskResultFileRef = result.result as BatchTaskResultFileRef;
                const buttons: BatchResultButton[] = []
                buttons.push({
                  Title: 'Descargar',
                  Classes: 'o-btn__accent',
                  Icon: 'fa fa-download',
                  OnClickFunction: () => {
                    this.fileDownloaderService.DownloadFileFromFileRef(batchTaskResultFileRef.FileRef)
                  }
                } as BatchResultButton);
                return {result: result.result, buttons: buttons};
              }
              if (backendTypeMatch(BatchTaskResultUploadedFiles.$type, result.result)) {
                return {
                  result: result.result,
                  buttons: asIterableObject((result.result as BatchTaskResultUploadedFiles).Files).filter(x => !isNullOrUndefined(x.File)).map(x => {
                    return {
                      Title: x.Title,
                      Classes: UtilsTypescript.getNewtonSoftRealValue(x.CssClasses).join(' '),
                      Icon: x.Icon,
                      Tooltip: x.Description,
                      OnClickFunction: () => {
                        this.fileDownloaderService.DownloadFileFromUploadedFile(x.File);
                      }
                    } as BatchResultButton
                  })
                };
              }
              if (backendTypeMatch(BatchTaskResultWordAndPdfUploadedFile.$type, result.result)) {
                return {
                  result: result.result,
                  buttons: asIterableObject((result.result as BatchTaskResultWordAndPdfUploadedFile).Files).filter(x => !isNullOrUndefined(x.File)).map(x => {
                    return {
                      Title: x.Title,
                      Classes: UtilsTypescript.getNewtonSoftRealValue(x.CssClasses).join(' '),
                      Icon: x.Icon,
                      Tooltip: x.Description,
                      OnClickFunction: () => {
                        this.fileDownloaderService.DownloadFileFromUploadedFile(x.File);
                      }
                    } as BatchResultButton
                  })
                };
              }
            }
          ));
    } else {
      return Observable.empty();
    }
  }

  public GetTaskResultUploadedFiles(task: BatchTaskInfo, args?: any, contentDisposition?: 'inline' | 'attachment'): Observable<UploadedFile[]> {
    if (task.HasResult) {
      return this.batchService.postTaskresult(task.QueueId, contentDisposition, null)
        .pipe(map((result: WebServiceResponseTyped<IBatchTaskResult>) => {
          if (backendTypeMatch(BatchTaskResultFileRef.$type, result.result)) {
            const batchTaskResultFileRef: BatchTaskResultFileRef = result.result as BatchTaskResultFileRef;
            const uploadedFiles: UploadedFile[] = []
            const uf: UploadedFile = new UploadedFile();
            uf.url = batchTaskResultFileRef.FileRef;
            uf.type = batchTaskResultFileRef.DocumentType;
            uploadedFiles.push(uf)
            return uploadedFiles;
          }
          if (backendTypeMatch(BatchTaskResultUploadedFiles.$type, result.result)) {
            return asIterableObject((result.result as BatchTaskResultUploadedFiles).Files).filter(x => !isNullOrUndefined(x.File)).map(x => x.File);
          }
          if (backendTypeMatch(BatchTaskResultWordAndPdfUploadedFile.$type, result.result)) {
            return asIterableObject((result.result as BatchTaskResultWordAndPdfUploadedFile).Files).filter(x => !isNullOrUndefined(x.File)).map(x => x.File);
          }
        }));
    } else {
      return Observable.empty();
    }
  }

  /**
   * Do a action with a task
   * @param taskId
   * @param action
   * @constructor
   * @private
   */
  private DoTaskAction(taskId: string, action: CoreBatchActionEnum): Promise<boolean> {
    return this.batchService.postTaskaction(taskId, action, {showSpinner: false})
      .pipe(
        takeUntil(this.componentDestroyed$),
        map((x: WebServiceResponseTyped<any>) => {
          if (isNullOrUndefined(x.error)) {
            return true;
          } else {
            throw new Error(`Unable to do the action ${action.toString()} the task: ${x.error}`)
          }
        })
      ).toPromise()
  }
}
