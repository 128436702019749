import { Component, OnInit } from '@angular/core';
import { DestroyableObjectTrait } from '../../shared/utils/destroyableobject.trait';

@Component({
  selector: 'app-person-management',
  templateUrl: './person-management.component.html',
  styleUrls: []
})
export class PersonManagementComponent extends DestroyableObjectTrait implements OnInit {
  public params: { editProfile: boolean };

  /**
   * PersonManagementComponent class constructor.
   */
  constructor() {
    super();
  }

  /**
   * A lifecycle hook that is called after Angular has initialized
   * all data-bound properties of a directive.
   */
  ngOnInit(): void {
    this.params = {
      editProfile: true
    }
  }
}
