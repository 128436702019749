import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

import { MenuDirection } from './enums/dropdown-menu-direction.enum';
import { NavigationService } from '../../../core/navigation/navigation.service';
import { MenuItemCompiled } from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { Subject } from 'rxjs';
import { NavigationRequest } from '../../../core/navigation/models/NavigationRequest.class';
import { MenuItemCompiledFrontend } from '../../../core/navigation/models/MenuItemCompiledFrontend.class';

/**
 * ACHSPRIME-2168 Revisado comportamiento completo componente
 */
@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.css'],
})
export class DropdownMenuComponent implements OnInit, OnDestroy {
  // public properties
  tree: MenuItemCompiledFrontend = null;
  open = false;

  // inputs
  @Input() direction: MenuDirection = MenuDirection.left;
  @Input() menuName = 'user-menu';
  @Input() userInitial = '';

  // outputs
  @Output() childEvents = new EventEmitter<string>();
  @Output() treeUpdated: EventEmitter<MenuItemCompiled> = new EventEmitter<MenuItemCompiled>();

  private _unsubscribeAll: Subject<any>;

  /**
   * Creates a new instance of DropdownMenuComponent
   * @param {ElementRef} _eref
   * @param {NavigationService} navigationService
   */
  constructor(private _eref: ElementRef,
              private navigationService: NavigationService,
              private cdRef: ChangeDetectorRef) {
    this._unsubscribeAll = new Subject();
  }

  /**
   * On initialitation, sets the tree from navigation service and subscribes to tree changes
   */
  ngOnInit(): void {
    this.navigationService
      .resolveTreeFromNavigationRequest(new NavigationRequest())
      .takeUntil(this._unsubscribeAll)
      .subscribe(
        (next: NavigationRequest) => {
          this.tree = next.responseProcessedTree.find((i) => i.menuName === this.menuName);
          this.treeUpdated.emit(this.tree);
          this.cdRef.detectChanges();
        }
      );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  /**
   * Handler when document is clicked to close the menu if the click is outside the menu
   *
   * ACHSPRIME-2168 Revisado OK
   *
   * @param event
   */
  @HostListener('document:click', ['$event'])
  clickedOutside(event: any): void {
    if (!this._eref.nativeElement.contains(event.target) && this.open === true) {
      this.open = false;
      this.cdRef.detectChanges();
    }
  }

  /**
   * propagate menu child events
   * @param e
   */
  propagateEvent(e: any): void {
    this.childEvents.emit(e);
    this.open = false;
    this.cdRef.detectChanges();
  }

  /**
   * Toggle show/hide dropdown-menu.
   */
  toggle(): void {
    this.open = !this.open;
    this.cdRef.detectChanges();
  }
}
