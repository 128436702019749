import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import { ChartData, ChartDataSets, ChartPoint } from 'chart.js';
import { BehaviorSubject } from 'rxjs';
import 'chartjs-plugin-annotation';
import { parseKeyItemToArray } from '../../../../../../../../../shared/utils/prime.utils';
import { getNewtonSoftRealValue, isNullOrUndefined } from '../../../../../../../../../shared/utils/typescript.utils';
import { BaseFuseChartTypeInterface } from '../../base-fuse-chart-type.class';
import { ChangedetectorReference } from '../../../../../../../../../core/changedetector/changedetectoreference';
import {
  CustomDispersionChart,
  CustomDispersionChartDisplayOptions,
  ISerie,
  KeyValuePair,
  SerieValue
} from 'app/core/models/ETG_SABENTISpro_Application_Core_models';

@Component({
  selector: 'app-dispersion-chart',
  templateUrl: './custom-dispersion-chart.component.html',
  styleUrls: ['./custom-dispersion-chart.component.scss'],
  providers: [ChangedetectorReference]
})
export class CustomDispersionChartComponent
  extends BaseFuseChartTypeInterface<CustomDispersionChart, CustomDispersionChartDisplayOptions>
  implements OnInit {

  /**
   * Canvas element.
   */
  @ViewChild('canvasItem', {static: true}) canvas: ElementRef;

  /**
   * Canvas context.
   */
  ctx: CanvasRenderingContext2D;

  /**
   * Chart object.
   */
  chart: Chart;

  /**
   * This triggers an event when the component has been succesfully initializated.
   */
  private initializedTrigger: BehaviorSubject<boolean>
    = new BehaviorSubject<boolean>(false);

  /**
   * `CustomDispersionChartComponent` class constructor.
   */
  constructor(protected cdReference: ChangedetectorReference) {
    super(cdReference);
  }

  /**
   * A lifecycle hook that is called after Angular has initialized
   * all data-bound properties of a directive.
   */
  ngOnInit(): void {
    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.initializedTrigger.next(true);
  }

  /**
   * Chart configuration initializer method.
   */
  initializeChart(): void {
    const labelSerie: ISerie = this.getDataSeries().Series[this.currentChart.LabelSeriesId];
    const labels: SerieValue[] = parseKeyItemToArray<SerieValue>(labelSerie.Values);

    const dataSeries: ISerie[] = parseKeyItemToArray<ISerie>(this.getDataSeries().Series)
      .filter((v: ISerie) => v['key'] !== this.currentChart.LabelSeriesId);
    const data: ChartData = this.getChartData(labels, dataSeries);

    this.initializedTrigger
      .filter(v => v)
      .takeUntil(this.componentDestroyed$)
      .take(1)
      .subscribe(() => {
        this.chart = new Chart(this.ctx, <Chart.ChartConfiguration>{
          type: 'scatter',
          data: data,
          options: this.getChartOptions(data.datasets)
        });
        this.cdReference.changeDetector.detectChanges();
      });
  }

  /**
   * This method return a `ChartData` object from the input series.
   * @param {SerieValue[]} labels
   * @param {ISerie[]} series
   */
  private getChartData(labels: SerieValue[], series: ISerie[]): ChartData {
    return {datasets: this.getDataset(labels, series)};
  }

  /**
   * Chart configuration builder.
   * @param {ChartDataSets[]} data
   */
  private getChartOptions(data: ChartDataSets[]): object {
    return {
      maintainAspectRatio: true,
      layout: {
        padding: {
          left: 10,
          right: 10,
          top: 10,
          bottom: 10
        }
      },
      scales: {
        yAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: this.displayOptions.YAxisLabel || ''
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100
          }
        }],
        xAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: this.displayOptions.XAxisLabel || ''
          },
          ticks: {
            suggestedMin: 0,
            suggestedMax: 100
          }
        }]
      },
      legend: {
        position: 'top',
      },
      tooltips: {
        custom: function (tooltip: any): void {
          if (!tooltip) {
            return;
          }
        },
        callbacks: {
          // use label callback to return the desired label
          label: function (tooltipItem: any, globalData: any): string {
            return `${globalData.datasets[tooltipItem.datasetIndex].label} (`
              + `${this.currentChart.XAxisTitle}=${+parseFloat(tooltipItem.xLabel).toFixed(2)}, `
              + `${this.currentChart.YAxisTitle}=${+parseFloat(tooltipItem.yLabel).toFixed(2)})`;
          }.bind(this)
        }
      },
      annotation: this.getAnnotations()
    };
  }

  /**
   *
   */
  private getAnnotations(): any {
    return {
      annotations: [
        {
          id: 'red-box',
          type: 'box',
          xScaleID: 'x-axis-1',
          yScaleID: 'y-axis-1',
          xMin: 0,
          xMax: 50,
          yMin: 0,
          yMax: 50,
          backgroundColor: 'rgba(255, 0, 0, 0.05)'
        },
        {
          id: 'yellow-box',
          type: 'box',
          xScaleID: 'x-axis-1',
          yScaleID: 'y-axis-1',
          xMin: 0,
          xMax: 50,
          yMin: 50,
          yMax: 100,
          backgroundColor: 'rgba(255, 255, 0, 0.05)'
        },
        {
          id: 'yellow2-box',
          type: 'box',
          xScaleID: 'x-axis-1',
          yScaleID: 'y-axis-1',
          xMin: 50,
          xMax: 100,
          yMin: 0,
          yMax: 50,
          backgroundColor: 'rgba(255, 255, 0, 0.05)'
        },
        {
          id: 'green-box',
          type: 'box',
          xScaleID: 'x-axis-1',
          yScaleID: 'y-axis-1',
          xMin: 50,
          xMax: 100,
          yMin: 50,
          yMax: 100,
          backgroundColor: 'rgba(0, 255, 0, 0.05)'
        }]
    }
  }

  /**
   * Returns an array of `ChartDataSets` with the data for the chart.
   *
   * @param {SerieValue[]} labels
   * @param {ISerie[]} series
   * @returns {ChartDataSets[]}
   */
  private getDataset(labels: SerieValue[], series: ISerie[]): ChartDataSets[] {
    const valuesSerie: ISerie = series
      .find((s: KeyedSerie) => s.key === this.currentChart.ValueSeriesId);

    if (isNullOrUndefined(valuesSerie)) {
      return [];
    }

    return (parseKeyItemToArray<SerieValue>(valuesSerie.Values) as SerieValue[])
      .map((set: SerieValue, index: number) => {
        const label: SerieValue = labels[index];
        const data: ChartPoint[] = (getNewtonSoftRealValue(set.Value) as KeyValuePair<number, number>[])
          .map(item => ({x: item.Key, y: item.Value} as ChartPoint))

        return this.buildDataset(label.Value, data);
      });
  }

  /**
   * Builds a `ChartDataSets` object.
   *
   * @param {string} title
   * @param {number[]} data
   * @param {string} color
   * @param {string} hoverBorderColor
   * @returns {ChartDataSets}
   */
  private buildDataset(title: string, data: ChartPoint[], color?: string, hoverBorderColor?: string): ChartDataSets {
    const colortAlt: string = this.colorRoulette();

    return {
      backgroundColor: color || colortAlt,
      borderColor: color || colortAlt,
      hoverBorderColor: hoverBorderColor || color || colortAlt,
      pointRadius: 5,
      data: data,
      label: title,
    };
  }

  /**
   * Color picker algorythm.
   */
  private colorRoulette(): string {
    const r: number = Math.floor(Math.random() * 255);
    const g: number = Math.floor(Math.random() * 255);
    const b: number = Math.floor(Math.random() * 255);
    return `rgba(${r}, ${g}, ${b}, 0.5)`;
  };
}

interface KeyedSerie extends ISerie {
  key: string
}
