import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  CalendarEvent,
  CalendarMonthViewBeforeRenderEvent,
  CalendarMonthViewEventTimesChangedEvent
} from 'angular-calendar';
import { MonthViewDay } from 'calendar-utils';
import { isSameDay, isSameMonth } from 'date-fns';
import { CalendarModeMonth, DayOfWeek } from '../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { ViewModeCalendarComponentAbstract } from '../view-mode-calendar-component-abstract.class';

@Component({
  selector: 'app-view-mode-calendar-month',
  templateUrl: './view-mode-calendar-month.component.html',
  styleUrls: ['./view-mode-calendar-month.component.scss']
})
export class ViewModeCalendarMonthComponent extends ViewModeCalendarComponentAbstract implements OnInit, OnChanges {

  /**
   * The Current Configuration
   */
  monthConfiguration: CalendarModeMonth;

  /**
   * An array of day indexes (0 = sunday, 1 = monday etc) that indicate which days are weekends
   */
  weekendDays: number[];

  /**
   * The start number of the week
   */
  weekStartOn: number;

  activeDayIsOpen: boolean;
  @Output() beforeViewRender: EventEmitter<CalendarMonthViewBeforeRenderEvent>;
  @Output() dayClicked: EventEmitter<{ day: MonthViewDay<any>; }>;


  constructor(public cdr: ChangeDetectorRef) {
    super(cdr);
    this.beforeViewRender = new EventEmitter<CalendarMonthViewBeforeRenderEvent>();
    this.dayClicked = new EventEmitter<{ day: MonthViewDay<any> }>();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Emiters
  // -----------------------------------------------------------------------------------------------------
  onEventClicked(data: { event: CalendarEvent<any> }): void {
    this.eventClicked.emit(data);
  }

  onEventTimesChanged(data: CalendarMonthViewEventTimesChangedEvent<any, any>): void {
    this.eventTimesChanged.emit(data);
  }

  onBeforeMonthViewRender(data: CalendarMonthViewBeforeRenderEvent): void {
    /**
     * Get the selected day
     */
    const _selectedDay: any = data.body.find((_day) => {
      return _day.date.getTime() === this.viewDate.getTime();
    });

    if (_selectedDay) {
      /**
       * Set selected day style
       * @type {string}
       */
      _selectedDay.cssClass = 'cal-selected';
    }
    this.beforeViewRender.emit(data);
  }

  onDayClicked(data: { day: MonthViewDay<any> }): void {
    const date: Date = new Date(data.day.date);
    const events: CalendarEvent[] = data.day.events;

    if (isSameMonth(date, this.viewDate)) {
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
    this.refresh.next();
    this.dayClicked.emit(data);
  }

  protected parseConfiguration(): void {
    this.monthConfiguration = this.configuration as CalendarModeMonth;
    if (this.monthConfiguration) {
      this.weekendDays = this.monthConfiguration.WeekendDays.map((x: DayOfWeek) => x.valueOf());
      this.weekStartOn = this.monthConfiguration.WeekStartsOn.valueOf();
    }
  }
}
