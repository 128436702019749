import { Component, OnInit, ViewChild, ChangeDetectorRef, SkipSelf } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { FrontendFormElementWrapper } from '../form/form-components/formelementwrapper.class';
import { FormManagerService } from '../form/form-manager/form-manager.service';
import { FrontendFormElementInput } from '../form/form-components/formelementinput.class';
import { DynamicComponent } from '../custom-component-factory/type-manager.decorator';

@DynamicComponent('methodology-achs')
@Component({
  selector: 'app-methodology-achs',
  templateUrl: 'methodology-ACHS.component.html',
  styleUrls: ['./methodology.component.scss']
})
export class MethodologyACHSComponent extends FrontendFormElementWrapper implements OnInit {

  /**
   * Constructor
   * @param formManagerService
   * @param cdRef
   * @param cdRefParent
   */
  constructor(formManagerService: FormManagerService,
    cdRef: ChangeDetectorRef,
    @SkipSelf() cdRefParent: ChangeDetectorRef) {
    super(formManagerService, cdRef, cdRefParent);
  }

  /**
   * onInit
   */
  ngOnInit(): void {
   }

   /**
    * formElementInstance
    */
   formElementInstance(): FrontendFormElementInput {
    throw new Error('Not supported for a fieldset component.');
  }
}
