import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { isNullOrUndefined } from 'app/shared/utils/typescript.utils';

import {
  IViewResultCell,
  ViewResult,
  ViewResultCellSimple,
  ViewResultRow,
  ViewsPagerUserConfigurationSimple as PagerConfiguration,
  ViewsPluginRequest,
  ViewUserConfiguration
} from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { ViewsuserconfigchangedEventdata } from '../../../shared/list_v2/viewsuserconfigchanged.eventdata';
import { getInSafe, UtilsTypescript } from '../../../shared/utils/typescript.utils';
import { ListComponent2Service } from '../../list_v2/list.service';
import { ViewsService } from '../../../core/services/ETG_SABENTISpro_Application_Core_views.service';

/**
 * Component SelectorComponent
 */
@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  providers: [ListComponent2Service, ViewsService]
})
export class SelectorComponent {

  /**
   * Additional arguments
   */
  @Input() additionalArguments: { [key: string]: string };

  /**
   * Id of plugin view.
   */
  @Input() plugin: string;

  /**
   * Selector
   */
  @Input() selector = 'selector';

  /**
   * Selector Secondary
   */
  @Input() selectorSecundary = 'selectorSecundary';

  /**
   * Placeholder for input.
   */
  @Input() placeholder = 'Search for...';

  /**
   * Selection label for selection center
   */
  @Input() selectionLabel;

  /**
   * Can unselect the option.
   */
  @Input() canUnselect = true;

  /**
   * Item slected for the user.
   */
  @Input() selectedItem: any;

  /**
   * Event that is sends to select item.
   */
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Array of the results.
   */
  possibleResults: { [id: string]: IViewResultCell }[] = [];

  /**
   * No results boolean.
   */
  noResults = false;

  /**
   * Current search string.
   */
  private currentSearch = '';

  /**
   * Last search.
   */
  private lastSearch = '';

  /**
   * isSearching
   */
  private isSearching = false;

  /**
   * SelectorComponent class constructor.
   *
   * @param {ListComponent2Service} listService
   * @param {AppContextService} contextService
   * @param {ElementRef} eRef
   */
  constructor(
    protected listService: ListComponent2Service,
    protected eRef: ElementRef,
    protected cdRef: ChangeDetectorRef
  ) {
  }

  /**
   * Getter for the `disabled` property of the input.
   */
  get disabled(): boolean {
    return !this.canUnselect;
  }

  /**
   * This method is called on `keydown.enter` or `scroll-to-end` events. The methods
   * gets a list items for the list and `name-like` items.
   *
   * If last search and current search are equal, and the options
   * list is greater than 0, the first element on the list is selected.
   * @param {KeyboardEvent} event
   * @param {boolean} scrollEvent
   */
  search(event: KeyboardEvent, scrollEvent: boolean = false): void {
    // SKIP IF WE'RE SEARCHING
    // If we're already searching for something then do nothing.
    if (this.isSearching) {
      return;
    }

    // If this method was called from a `KeyboardEvent` then get the new input.
    if (!isNullOrUndefined(event)) {
      this.currentSearch = (event.target as HTMLInputElement).value;
    }

    /**
     * SELECT FIRST ITEM IF AVAILABLE
     * If the *current search is the same that the last search*, and
     * *there are possible results* and *is not an scroll-to-end event*,
     * then select the first item.
     */
    if (
      !scrollEvent &&
      this.currentSearch === this.lastSearch &&
      this.possibleResults.length > 0
    ) {
      this.selectItem(0);
      return;
    }

    /**
     * SKIP SEARCH IF WE'RE ON LAST PAGE
     * Only on `scroll-to-end` events, if we're on the same sarch and
     * the last result date indicated that we're on the last page the do nothing.
     */
    if (
      scrollEvent &&
      getInSafe(this.listService.data, d => d.LastPage, false)
    ) {
      return;
    }

    if (!scrollEvent) {
      // CLEAN IF EVENT IS NOT AN SCROLL EVENT
      // If is not an scroll to end event then cleand the possible results and
      // the user config to start a clean data retrieval.
      this.possibleResults = [];
      this.listService.setUserConfiguration(null);
    } else {
      // INCREMENT PAGINATION IF WE'RE IN AN SCROLL EVENT
      // Else increment the pagination.
      const userconfig: ViewUserConfiguration = this.listService.getUserConfiguration();
      const pagination: PagerConfiguration = userconfig.Pagination as PagerConfiguration;
      pagination.CurrentPage++;
    }

    // Update the last search value and hide the No Results label.
    this.lastSearch = this.currentSearch;
    this.noResults = false;

    this.getSelectorItems();
  }

  /**
   * Populates the `possibleResults` array with the items emerged from the search.
   */
  private getSelectorItems(): void {
    this.isSearching = true;

    const args: any[] = UtilsTypescript.jsonClone(this.additionalArguments || {});

    if (!UtilsTypescript.isNullOrWhitespace(this.currentSearch)) {
      args['currentSearch'] = this.currentSearch;
    }

    const currentPluginRequest: ViewsPluginRequest = Object.assign(
      new ViewsPluginRequest(),
      {Id: this.plugin, Arguments: args}
    );

    const configuration: ViewsuserconfigchangedEventdata = !isNullOrUndefined(
      this.listService.getUserConfiguration()
    )
      ? new ViewsuserconfigchangedEventdata(
        this.listService.getUserConfiguration()
      )
      : null;

    this.listService
      .loadList(currentPluginRequest, true, configuration)
      .take(1)
      .subscribe(((): void => {
        const viewResults: ViewResult = this.listService.data;

        const results: ViewResultRow[] = getInSafe(
          viewResults,
          r => r.Results,
          []
        );
        results.map(element => this.possibleResults.push(element.Columns));

        if (viewResults.Results.length === 0) {
          this.possibleResults = [];
          this.noResults = true;
        }

        this.isSearching = false;

        this.cdRef.detectChanges();
      }).bind(this));
  }

  /**
   * This method is used to select an specific
   * item. The itemSelected Observable will be
   * triggered with the item value
   * @param i: selected item index
   */
  selectItem(i: number): void {
    const item: any = this.possibleResults[i];
    this.selectedItem = {
      id: getInSafe(item, itm => itm.id.RawValue, null),
      location: getInSafe(item, itm => itm.location.RawValue, null),
      path: getInSafe(item, itm => itm.path.RawValue, null),
      companyconfig: getInSafe(item, itm => itm.companyconfig.RawValue, null),
      [this.selector]: getInSafe(
        item,
        itm => itm[this.selector].RawValue,
        null
      ),
      [this.selectorSecundary]: getInSafe(
        item,
        itm => itm[this.selectorSecundary].RawValue,
        null
      ),
    };
    this.cdRef.detectChanges();
    this.itemSelected.emit(this.selectedItem);
  }

  /**
   * This method is to unselect the selected
   * option. The itemSelected Observable will be
   * triggered with null value
   */
  unselect(): void {
    this.possibleResults = [];
    this.cdRef.detectChanges();
    // Emitimos nulo, pero dejamos la selección por si falla algo
    // durante el cambio de contexto
    this.itemSelected.emit(null);
  }

  /**
   * This handler is called when the document click event
   * is fired
   *
   * ACHSPRIME-2168 Revisado funciona OK
   *
   * @param event
   */
  @HostListener('document:click', ['$event'])
  clickedOutside(event: Event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.possibleResults = [];
      this.noResults = false;
      this.cdRef.detectChanges();
    }
  }

  /**
   * Handler to the scrollToEnd event
   */
  scrolledToEndHandler(): void {
    this.search(null, true);
  }

  /**
   * It returns selector title
   * @returns {string}
   */
  getSelectorTitle(): string {
    const selector: string = getInSafe(
      this.selectedItem,
      s => s[this.selectorSecundary]
    );
    return selector !== '' ? selector : this.selectionLabel;
  }

  /**
   * Item trackby for *ngFor of selector.
   * @param {number} _
   * @param {object} item
   */
  itemTrackBy(_: number, item: { id: ViewResultCellSimple }): string {
    return item.id.RawValue;
  }
}
